import ModalComponent from '../../../../../../libs/components/modal-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import { formToJSON } from '../../../../../../libs/form-to-json';
import { apiProvider } from '../../../../../../libs/api-provider';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';

class AddCard extends ModalComponent {
    constructor(name, root) {
        super(name, root);

        this.title = this._dEl('title');
        this.form = this._dEl('form');
        this.user = JSON.parse(this.form.dataset.user);
        this.dataNascita = this._dEl('dataNascita');
        this.addCard = this._dEl('addCard');
        this.error = this._dEl('error');
        this.cardNumber = this._dEl('cardNumber');

        this.alreadyOpened = false;

        //this._checkFormValid();
        this._addEventListeners();
    }

    /* override */
    async _onOpen() {
        await super._onOpen();
        this._resetFormInputs();
        //this._checkFormValid();
        if (this.alreadyOpened) {
            this._initError();
            return;
        }
        this.alreadyOpened = true;
    }

    _addEventListeners() {
        /*this.root.addEventListener('ecInputChanged', () => {
            this._checkFormValid();
        });*/
        this.form.addEventListener('submit', (event) => {
            event.preventDefault();
        });
        this.addCard.addEventListener('click', async (event) => {
            event.preventDefault();
            if (!this.form.checkValidity()) {
                const first = this.form.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }
            await this._addCard();
        });

        /* form errors */
        this.form.addEventListener('ecFormError', (event) => {
            event.preventDefault();
            if (!event.data.errorField) return;
            trackEvent(
                TRACKABLE_EVENT.formError, 
                FUNNEL_NAME.insiemeCard, 
                FUNNEL_STEP.insertInsiemeCardData, 
                null, null, null, 
                { 
                    errorField: event.data.errorField,
                    errorText: event.data.errorText,
                }
            );
            event.stopPropagation();
        });
    }

    _resetFormInputs() {
        if (!this.form) return;
        [
            'mt8-textfield',
            'mt23-datepicker',
        ].forEach((el) => {
            this.form.querySelectorAll(`.${el}`).forEach((field) => {
                register.getClass(field).reset();
            });
        });
    }

    /*async _checkFormValid() {
        if (!this.form.checkValidity()) {
            this.addCard.classList.add('invalid');
            this.addCard.setAttribute('title', dictionary.getFEMessage('fillInFields'));
        } else {
            this.addCard.classList.remove('invalid');
            this.addCard.removeAttribute('title');
        }
    }*/

    _initError() {
        if (this.alreadyOpened) {
            if (!this.error.classList.contains(this._elMod('error', 'hidden')))
                this.error.classList.add(this._elMod('error', 'hidden'));
        }
    }

    async _addCard() {
        let result = false;
        try {
            openLoader('main');

            const requestCardData = {
                ...formToJSON(this.form),
            };
            const userData = {
                ...this.user,
            };

            const data = {
                ...requestCardData,
                ...userData,
            }
            result = await apiProvider.verifyCard(data);

            if(result) {
                storeManager.emit('addCardData', requestCardData);
                storeManager.emit('userData', userData);
                storeManager.emit('setMm5CardAdded', { title: this.title.innerText });
                flowManager.appendDataToFlow(result);
                flowManager.next('add-card-step2');
            }
        } catch (error) {
            this.ISError = error;
            console.warn('Cannot call add card ', error);
            result = false;
        } finally {
            closeLoader('main');
        }

        if (!result) {
            this.error.innerHTML = this?.ISError?.codice
                ? await (dictionary.getFEMessage(this.ISError.codice) || this.ISError.errore)
                : this.root.dataset.genericError;
            this.error.classList.remove(this._elMod('error', 'hidden'));
        }
    }

    async _onCancel() {
        flowManager.complete();
    }
}

register.registerClass('.mm3-add-card', AddCard);
