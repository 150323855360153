import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';

class RefreshPrizeCoupon extends Component {
    constructor(name, root) {
        super(name, root);
        this.root = root;

        this._addEventListeners();
    }

    _addEventListeners() {

    }
}

register.registerClass('.mt45-refresh-prize-coupon', RefreshPrizeCoupon);
