import { apiProvider } from '../../../../../../libs/api-provider';
import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';

class SubstituteMarketingEmail extends PopupComponent {
    constructor(name, root) {
        super(name, root);
        this._doLogic();
    }

    async _doLogic() {
        this.ctaAction = this._dEl('ctaAction');
        this._addEventListeners();
    }

    _addEventListeners() {
        this.ctaAction?.addEventListener('click', () => {
            this._substituteMarketingEmail();
        });
    }

    async _substituteMarketingEmail() {
        openLoader('main');
        try {
            const data = {};
            const userData = storeManager.get('userData');
            if (userData.email) data.emailOCDB = userData.email;
            const firmaDigitale = await apiProvider.getFirmaDigitale();
            if (firmaDigitale) data.firmaDigitale = firmaDigitale;
            await apiProvider.editUserData(data);
            storeManager.emit('changedEmailOcdb', {});
            flowManager.backOrComplete();
        } catch (error) {
            console.warn(error);
        }
        closeLoader('main');
    }

    /* override */
    async _onCancel() {
        flowManager.backOrComplete();
    }
}

register.registerClass('.mp15-substitute-marketing-email', SubstituteMarketingEmail);
