import Component from '../../../../../../libs/components/component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { FUNNEL_NAME, FUNNEL_STEP, trackEvent, TRACKABLE_EVENT } from '../../../../../../libs/tracking-manager-old';
import { showPopup } from '../../../templates/mt4-popup/script';
import { ecApiProvider } from '../../../../../../libs/ecommerce-api-provider';

class OrderDetail extends Component {
    constructor(name, root) {
        super(name, root);

        this.manageOrderLabel = this._dEl('manageOrderLabel');
        this.modifyDate = this._dEl('modifyDate');
        this.cancelOrderCta = this._dEl('cancelOrderCta');
        this.modifyDayTime = this._dEl('modifyDayTime');
        this.address = this._dEl('address');
        this.addressText = this.address?.querySelector(`p${this._el('labelText', true)}`);
        this.addRemoveProducts = this._dEl('addRemoveProducts');
        this.cancelOrder = this._dEl('cancelOrder');
        this.productsHeading = this._dEl('productsAccordionHeading');
        this.productsContent = this._dEl('productsAccordionContent');
        this.addRemoveProductsCta = this._dEl('addRemoveProductsCta');
        this.preauthCta = this._dEl('preauthCta');
        this.preauthPopupContent = this._dEl('preauthPopup')?.content?.querySelector('.mt39-preauthorization-popup');
        this.downloadReceiptCta = this._dEl('receiptDownload');
        if (this.root.dataset.pdv) this.pdv = JSON.parse(this.root.dataset.pdv);
        if (this.root.dataset.order) this.order = JSON.parse(this.root.dataset.order);

        this._generateReceiptUrl();

        this._addEventListeners();
        this._addStoreListeners();
        trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.orders, FUNNEL_STEP.orderDetail);
    }

    _addEventListeners() {
        if (this._dEl('manageOrder')) {
            this.manageOrderLabel.addEventListener('click', (event) => {
                event.preventDefault();
                if (this._amendExpired()) return;
                this._toggleManageOrderDropdown();
            });
            this.manageOrderLabel.addEventListener('keydown', (event) => {
                if (event.key !== 'Escape') return;
                this.closeManageOrderDropdown();
            });
            this.modifyDayTime?.addEventListener('click', (event) => {
                event.preventDefault();
                if (this._amendExpired()) return;
                trackEvent(TRACKABLE_EVENT.clickManageOrder, FUNNEL_NAME.orders, this.modifyDayTime.innerText);
                this.manageOrderLabel.classList.remove(this._elMod('manageOrderLabel', 'show'));
                this._modifyOrder('change-timeslot');
            });
            this.addRemoveProducts?.addEventListener('click', (event) => {
                event.preventDefault();
                if (this._amendExpired()) return;
                trackEvent(TRACKABLE_EVENT.clickManageOrder, FUNNEL_NAME.orders, this.addRemoveProducts.innerText);
                this.manageOrderLabel.classList.remove(this._elMod('manageOrderLabel', 'show'));
                this._modifyOrder('change-products');
            });
            this.cancelOrder?.addEventListener('click', (event) => {
                event.preventDefault();
                if (this._amendExpired()) return;
                trackEvent(TRACKABLE_EVENT.clickManageOrder, FUNNEL_NAME.orders, this.cancelOrder.innerText);
                this.manageOrderLabel.classList.remove(this._elMod('manageOrderLabel', 'show'));
                this._cancelOrder();
            });
             /* close dropdown on click outside itself or its button */
            document.addEventListener('click', (event) => {
                const target = event.target;
                if (target.closest(this._el('manageOrder', true)))
                    return;
                this.closeManageOrderDropdown();
            });
        }
        this.modifyDate?.addEventListener('click', (event) => {
            event.preventDefault();
            if (this._amendExpired()) return;
            trackEvent(TRACKABLE_EVENT.clickManageOrder, FUNNEL_NAME.orders, this.modifyDayTime.textContent);
            this._modifyOrder('change-timeslot');
        });
        this.cancelOrderCta?.addEventListener('click', (event) => {
            event.preventDefault();
            if (this._amendExpired()) return;
            trackEvent(TRACKABLE_EVENT.clickManageOrder, FUNNEL_NAME.orders, this.cancelOrder.textContent);
            this._cancelOrder();
        });
        this.productsHeading.addEventListener('click', (event) => {
            event.preventDefault();
            if (!this._isProductsAccOpen()) {
                // about to be opened
                this.productsContent.style.visibility = 'visible';
            }
            this._isProductsAccOpen() 
                ? this._closeProductsAcc()
                : this._openProductsAcc();
            if (!this._isProductsAccOpen()) {
                // just closed
                this.productsContent.style.visibility = 'hidden';
            }
        });
        window.addEventListener('resize', () => {
            this._updateProductsAccContentHeight();
        });
        this.addRemoveProductsCta?.addEventListener('click', (event) => {
            event.preventDefault();
            if (this._amendExpired()) return;
            trackEvent(TRACKABLE_EVENT.clickManageOrder, FUNNEL_NAME.orders, this.addRemoveProducts.textContent);
            this._modifyOrder('change-products');
        });
        
        this.preauthCta?.addEventListener('click', (event) => {
            event.preventDefault();
            if (!this.preauthPopupContent) return;
            const clone = this.preauthPopupContent.cloneNode(true);
            showPopup('main', clone);
        });

        this.downloadReceiptCta?.addEventListener('click', () => {
            trackEvent(TRACKABLE_EVENT.downloadReceipt, FUNNEL_NAME.downloadReceipt, FUNNEL_STEP.downloadReceipt);
        });
    }

    _addStoreListeners() {
        storeManager.on('reloadOrderDetail', async (path, data) => {
            if (data.reload) {
                window.location.reload();
            }
        });
    }

    _amendExpired() {
        /* check amend expired real time */
        if (this.order.amendTimestamp && new Date().getTime() >= this.order.amendTimestamp) {
            flowManager.startFlow({
                flowName: 'unmodifiable-order',
                flowSteps: [{ name: 'unmodifiable-order' }],
            });
            return true;
        }
        return false;
    }

    _emitSelectedOrder() {
        if (this.pdv && this.order && this.order.typeOfService) {
            const selectedOrder = {
                becommerce: this.pdv.becommerce?.toLowerCase(),
                pointOfServiceId: this.pdv.pointOfServiceId,
                typeOfService: this.order.typeOfService,
                orderCode: this.order.code,
                amendTimestamp: this.order.amendTimestamp,
                timeslotId: this.order.timeslotId,
                deliveryAddress: this.addressText.textContent.trim(),
                deliveryDate: new Date(this.root.dataset.orderDeliveryDate).getTime()
            };
            if (this.pdv.deliveryArea) selectedOrder.deliveryArea = this.pdv.deliveryArea;
            storeManager.emit('selectedOrder', selectedOrder);
        } else {
            console.warn(`Missing pdv info for order "${this.order.code}"`);
        }
    }

    _modifyOrder(modifyOrderOption) {
        this._emitSelectedOrder();
        const flowData = {
            flowName: 'modify-order',
            options: {
                modifyOrder: modifyOrderOption,
            }
        };
        switch (modifyOrderOption) {
            case 'change-timeslot':
                flowData.flowSteps = [{ name: 'modify-order' }];
                break;
            case 'change-products':
                flowData.flowSteps = [{ name: 'modify-order' }];
                break;
            default:
                console.warn(`Unhandled modify order option "${modifyOrderOption}" provided in ${this.getName()}`);
                break;
        }
        flowManager.startFlow(flowData);
    }

    _cancelOrder() {
        this._emitSelectedOrder();
        flowManager.startFlow({
            flowName: 'cancel-order',
            flowSteps: [{ name: 'cancel-order' }],
        });
    }

    _isManageOrderDropdownOpen() {
        return this.manageOrderLabel.classList.contains(this._elMod('manageOrderLabel', 'show'));
    }
    _toggleManageOrderDropdown() {
        if (!this.manageOrderLabel) return;
        this._isManageOrderDropdownOpen() ? this.closeManageOrderDropdown() : this.openManageOrderDropdown();
    }
    openManageOrderDropdown() {
        if (!this.manageOrderLabel || this._isManageOrderDropdownOpen()) return;
        this.manageOrderLabel.setAttribute('aria-expanded', 'true');
        this.manageOrderLabel.classList.add(this._elMod('manageOrderLabel', 'show'));
    }
    closeManageOrderDropdown() {
        if (!this.manageOrderLabel || !this._isManageOrderDropdownOpen()) return;
        this.manageOrderLabel.setAttribute('aria-expanded', 'false');
        this.manageOrderLabel.classList.remove(this._elMod('manageOrderLabel', 'show'));
    }

    _isProductsAccOpen() {
        return this.productsHeading.classList.contains(this._elMod('productsAccordionHeading', 'open'));
    }
    _openProductsAcc() {
        this.productsHeading.classList.add(this._elMod('productsAccordionHeading', 'open'));
        this._updateProductsAccContentHeight();
        this._updateProductsAccAriaAttributes();
    }
    _closeProductsAcc() {
        this.productsHeading.classList.remove(this._elMod('productsAccordionHeading', 'open'));
        this._updateProductsAccContentHeight();
        this._updateProductsAccAriaAttributes();
    }
    _updateProductsAccContentHeight() {
        const isOpen = this._isProductsAccOpen();
        if (isOpen) {
            this.productsContent.style.maxHeight = this._dEl('productsAccordionWrapper').offsetHeight + 'px';
            return;
        }
        if (this.productsContent) this.productsContent.style.maxHeight = 0;
    }
    _updateProductsAccAriaAttributes() {
        const isOpen = this._isProductsAccOpen();
        this.productsHeading.setAttribute('aria-expanded', `${isOpen ? 'true' : 'false'}`);
        this.productsContent.setAttribute('tabindex', `${isOpen ? '0' : '-1'}`);
        this.productsContent.setAttribute('aria-hidden', `${isOpen ? 'false' : 'true'}`);
    }

    async _generateReceiptUrl() {
        if(!this.downloadReceiptCta) return;
        const receiptUrl = await ecApiProvider.getOrderReceipt({
            orderId: this.downloadReceiptCta.dataset.order,
            bEcommerce: this.downloadReceiptCta.dataset.becommerce
        });
        this.downloadReceiptCta.href = receiptUrl;
    }
}

register.registerClass('.mf6-order-detail', OrderDetail);
