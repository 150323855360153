import WizardComponent from '../../../../../../libs/components/wizard-component';
import { register } from '../../../../../../libs/register';

class MigrationThankYou extends WizardComponent {
    constructor(name, root) {
        super(name, root);
    }
}

register.registerClass('.mw19-migration-thank-you', MigrationThankYou);
