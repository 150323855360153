import PopupComponent from '../../../../../../libs/components/popup-component';
import { register } from '../../../../../../libs/register';
import { flowManager } from '../../../../../../libs/flow-manager';


class MarketingConsent extends PopupComponent {
    constructor(name, root) {
        super(name, root);
    }

    async _onCancel() {
        flowManager.backOrComplete();
    }
}

register.registerClass('.mp13-marketing-consent', MarketingConsent);
