import Component from '../../../../../libs/components/component';
import { gMapsHelper } from '../../../../../libs/gmaps-helper';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { register } from '../../../../../libs/register';
import { Store } from '../mt3-store/script';
import mapInfoWindowCall from '../mt39-info-window/mt39-info-window.html';

export default class MapPin extends Component {
    constructor(name, root) {
        super(name, root);

        this.SELECTED = this._mod('selected');
        this.AGGREGATOR = this._mod('aggregator');

        this.id = this.root.id;
        this.pinId = this.root.dataset.pinId;
        this.pin = this._dEl('pin');
        this.map = null;
        this.position = null;
        this.mapPinObj = null;
        this.poi = null;
        this.infoWindow = null;
        this.fav = false;

        this._addMapListeners();
    }

    setMapParams(map, position, mapPinObj, poi = null) {
        this.map = map;
        this.position = position;
        this.mapPinObj = mapPinObj;
        this.poi = poi;
        if (!this.poi) return;
        this.infoWindowData = {
            ...Store.getStoreData('info-window-' + this.pinId, null, poi),
        };
        this.initInfoWindow();
    }

    async initInfoWindow() {
        if (!this.poi) return;
        const maps = await gMapsHelper.getGMaps();
        this.infoWindow = new maps.InfoWindow({
            minWidth: 384,
            maxWidth: 384,
            pixelOffset: new maps.Size(16, -4, 'px', 'px'),
            position: this.getPosition()
        });
        await this.updateInfoWindowElement();
    }

    _addMapListeners() {
        /* handle click on map markers pin */
        this.pin.addEventListener('click', async () => {
            if (!this.mapPinObj?.getVisible()) return;

            if (this.isAggregator()) {
                /* emit clicked aggregator */
                const clickedAggregator = new CustomEvent('clickedAggregator', { bubbles: true });
                clickedAggregator.data = { position: this.position };
                this.root.dispatchEvent(clickedAggregator);
            } else {
                /* emit clicked pin */
                const clickedStore = new CustomEvent('clickedStore', { bubbles: true });
                clickedStore.data = { pinId: this.pinId };
                this.root.dispatchEvent(clickedStore);
            }
        });
    }

    async updateInfoWindowElement() {
        if (!this.map || !this.mapPinObj || !this.infoWindow) return;
        const infoWindowData = {
            ...this.infoWindowData,
        };
        this.infoWindowElement = runTemplate(mapInfoWindowCall, infoWindowData);
    }

    isSelected() {
        return this.root.classList.contains(this.SELECTED);
    }

    isAggregator() {
        return this.root.classList.contains(this.AGGREGATOR);
    }

    setSelected(select = true) {
        if (select) {
            this.root.classList.add(this.SELECTED);
            this.infoWindow?.setContent(this.infoWindowElement);
            this.infoWindow?.open(this.map);
        } else {
            this.root.classList.remove(this.SELECTED);
            this.infoWindow?.close();
        }
    }

    getPosition() {
        return this.position;
    }
}

register.registerClass('.mt34-map-pin', MapPin);
