import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';

class ActionPopup extends Component {
    constructor(name, root) {
        super(name, root);

        this.action = this.root.dataset.action;
        this.cta = this._dEl('ctaAction');

        this._addEventListeners();
    }

    _addEventListeners() {
        this.cta.addEventListener('click', async (event) => {
            event.preventDefault();
            /* add supported action names here */
            switch (this.action) {
                default:
                    console.warn(`[${this.getName()}] unknown action '${this.action}'`);
                    break;
            }
            const popup = this.root.closest('.mt4-popup');
            if (popup) {
                register.getClass(popup).close();
            }
        });
    }
}

register.registerClass('.mt25-action-popup', ActionPopup);