import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';

class ProductAccordionCard extends Component {
    constructor(name, root) {
        super(name, root);
        this._addEventListeners();
    }

    _addEventListeners() {
    
    }
}

register.registerClass('.mt24-product-accordion-card', ProductAccordionCard);
