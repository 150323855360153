import { apiProvider } from '../../../../../../libs/api-provider';
import WizardComponent from '../../../../../../libs/components/wizard-component';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { checkOriginEcommerce, delay, getCallback } from '../../../../../../libs/utils';
import { closeLoader, isLoaderOpened, openLoader } from '../../../templates/mt11-loader/script';
import { ecCartProvider } from '../../../../../../libs/ecommerce-cart-provider';


class Login extends WizardComponent {
    constructor(name, root) {
        super(name, root);

        this.page = register.getClass(document.querySelector('.ms1-page'));
        this.email = this._dEl('email');
        this.password = this._dEl('password');
        this.mantain = this._dEl('mantain');
        this.recaptchaAction = this.root.dataset.recaptchaAction;
        this.submit = this._dEl('submit');
        this.form = this._dEl('form');
        this.formTxt = this._dEl('formTxt');
        this.socialButtons = Array.from(this._dEl('socialButton', true));
        this.emailObj = register.getClass(this.email);
        this.passwObj = register.getClass(this.password);
        this.mantainObj = register.getClass(this.mantain);
        this.error = this._dEl('error');
        this.endTxt = this._dEl('endTxt');
        this.hasCart = window.accessInfo.cart;
        this.result;

        this._initTextsWithEditableLinks();

        this._checkError();
        this.originEcommerce = checkOriginEcommerce();

        this._addEventListeners();
        this._addStoreListeners();
        //this._checkShowSubmit();
    }

    /* override */
    open() {
        super.open();
        this.userInfo = { coop: '', store: '' };
        if (this.originEcommerce) {
            this.typeOfService = window.accessInfo.typeOfService;
            this.pointOfServiceId = window.accessInfo.pointOfServiceId;
            this.cooperativeId = window.accessInfo.cooperativeId;
            if (this.pointOfServiceId && this.cooperativeId) {
                this.userInfo = {
                    coop: this.cooperativeId,
                    store: this.pointOfServiceId,
                };
            }
            trackEvent(
                TRACKABLE_EVENT.pageview,
                FUNNEL_NAME.login,
                FUNNEL_STEP.loginModeChoice,
                this.typeOfService,
                null,
                this.userInfo
            );
        } else {
            trackEvent(
                TRACKABLE_EVENT.pageview,
                FUNNEL_NAME.login,
                FUNNEL_STEP.loginModeChoice,
                null,
                null,
                this.userInfo
            );
        }
    }

    _addEventListeners() {
        /*this.root.addEventListener('ecInputChanged', () => {
            this._checkShowSubmit();
        });*/
        this.form.addEventListener('submit', (event) => {
            event.preventDefault();
        });
        this.submit.addEventListener('click', (event) => {
            event.preventDefault();

            if (!this.form.checkValidity()) {
                const first = this.form.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }

            if (!this.error.classList.contains(this._elMod('error', 'hidden'))) {
                this.error.classList.add(this._elMod('error', 'hidden'));
                this.error.innerHTML = '';
            }
            this.emailObj.setState('');
            this.email.querySelector('.mt8-textfield__error').removeAttribute('style');
            this.passwObj.setState('');
            this.socialLogin = false;

            //start login process
            this._doLoginProcess();
        });
        this.socialButtons.forEach((item) => {
            item.addEventListener('click', (event) => {
                event.preventDefault();
                this.socialType = item.dataset.type;
                this.socialLogin = true;

                //start login process
                this._doLoginProcess();
            });
        });

        /* form errors */
        this.form.addEventListener('ecFormError', (event) => {
            event.preventDefault();
            if (!event.data.errorField) return;
            trackEvent(
                TRACKABLE_EVENT.formError,
                FUNNEL_NAME.login,
                FUNNEL_STEP.loginWithEmail,
                null,
                null,
                this.userInfo,
                {
                    errorField: event.data.errorField,
                    errorText: event.data.errorText,
                }
            );
            event.stopPropagation();
        });
    }

    _addStoreListeners() {
        storeManager.on('mw1DoLogin', async (path, data) => {
            if (data.doLogin) {
                this._doLogin();
            }
        });

        storeManager.on('mw1CompleteLogin', async (path, data) => {
            if (data.doRedirectAfterLogin) {
                openLoader('main');
                this._doRedirectAfterLogin();
            }
        });
    }

    async _doLoginProcess() {
        openLoader('main');
        /* open popup if user has cart */
        if (this.hasCart) {
            try {
                const cart = await ecCartProvider.getCartNoCache();
                if (cart && cart.totalUnitCount > 0) {
                    closeLoader('main');
                    flowManager.next('cart-recalculation');
                    return;
                }
            } catch (error) {
                console.log('Error during getting current cart, continue login without prompt cart recalculation');
            }
        }

        await this._doLogin();
    }

    async _doLogin() {
        //re-open loader if not open
        if (!isLoaderOpened('main')) openLoader('main');

        try {
            if (!this.socialLogin) {
                const token = await this._getRecaptchaToken();
                this._trackLoginFunnel();
                await this._sendLogin(token);
            } else {
                window.localStorage.setItem('loginSocialCb', getCallback());
                this._trackSocialLoginFunnel(this.socialType);
                await this._sendSocialLogin(this.socialType);
            }
        } catch (error) {
            closeLoader('main');
        }
    }

    _getRecaptchaToken() {
        return new Promise((resolve) => {
            /* recaptcha */
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(window.recaptchaSiteKey, { action: this.recaptchaAction }).then((token) => {
                    resolve(token);
                });
            });
        });
    }

    _checkShowSubmit() {
        if (this.emailObj.isValid() && this.passwObj.isValid()) {
            this.submit.classList.remove('invalid');
        } else {
            this.submit.classList.add('invalid');
        }
    }

    _getErrorFromUrl() {
        const urlParams = new URLSearchParams(window.location.search);
        const error = urlParams.get('error');
        if (error) return error;
        return null;
    }

    async _checkError() {
        const err = this._getErrorFromUrl();
        if (!err) return;
        const message = err != '' ? err : this.root.dataset.genericError;
        let decoded = null;
        try {
            decoded = decodeURI(message);
        } catch (error) {
            console.warn('Error message wrongly encoded in url');
        }
        this.error.innerHTML = decoded != null ? dictionary.get(decoded) : this.root.dataset.genericError;
        this.error.classList.remove(this._elMod('error', 'hidden'));
    }

    async _sendSocialLogin(socialType) {
        const data = {
            socialType,
            cb: getCallback(),
        };

        try {
            this.result = await apiProvider.socialLogin(data);
            window.location = this.result.redirectUrl; //TODO: Handle different user state
        } catch (error) {
            this.emailObj.setState('error');
            this.passwObj.setState('error');
            const message = error && error.frontendMessage ? error.frontendMessage : this.root.dataset.genericError;
            this.error.innerHTML = message;
            this.error.classList.remove(this._elMod('error', 'hidden'));
            throw 'Cannot complete social login';
        }
    }

    async _sendLogin(token) {
        if (!this.emailObj.isValid() || !this.passwObj.isValid()) {
            this._showError('Something was not filled in!');
            return;
        }

        const data = {
            email: this.emailObj.getValue(),
            password: this.passwObj.getValue(),
            mantain: this.mantainObj.getValue() == 'true',
            cb: getCallback(),
            gRecaptchaResponse: token,
        };

        try {
            this.result = await apiProvider.login(data);
            if (this.result.cartSelectionResponse) {
                storeManager.emit('cartsOnConflict', this.result.cartSelectionResponse);
                console.log(this.result.cartSelectionResponse);
                flowManager.startFlow({
                    flowName: 'choose-cart',
                    flowSteps: [{ name: 'choose-cart' }],
                });
                closeLoader('main');
            } else {
                this._doRedirectAfterLogin();
            }
        } catch (error) {
            this.emailObj.setState('error');
            this.email.querySelector('.mt8-textfield__error').style.display = 'none';
            this.passwObj.setState('error');
            const message = error && error.frontendMessage ? error.frontendMessage : this.root.dataset.genericError;
            if (error.code && error.code == 3) {
                this.error.innerHTML = '';
                /* case user not activated, show resend email */
                this.error.append(message + ' (');
                let resendLink = document.createElement('span');
                resendLink.innerText = dictionary.get('resend activation email');
                resendLink.style.cursor = 'pointer';
                resendLink.style.textDecoration = 'underline';
                resendLink.style.fontWeight = '600';
                resendLink.addEventListener('click', (event) => {
                    event.preventDefault();
                    this._resendActivationEmail();
                });
                this.error.append(resendLink);
                this.error.append(')');
            } else {
                this.error.innerHtml = '';
                this.error.append(message);
            }
            this.error.classList.remove(this._elMod('error', 'hidden'));
            await delay(1000);
            this.emailObj.setState('');
            this.passwObj.setState('');
            throw 'Cannot complete login';
        }
    }

    _doRedirectAfterLogin() {
        if (!this.result.cbValid) {
            window.location.href = '/';
        }
        if (!this.result.pdvSet) {
            /* prefstore not set yet */
            if (isLoaderOpened('main')) closeLoader('main'); // close loader before go to next step
            storeManager.emit('redirectUrl', { redirectUrl: this.result.redirectUrl });
            flowManager.next('completedata');
        } else {
            /* case valid callback with pdv set */
            window.location = this.result.redirectUrl;
        }
    }

    async _resendActivationEmail() {
        try {
            openLoader('main');
            await apiProvider.resendActivationEmail({ email: this.emailObj.getValue(), cb: getCallback() });
            await delay(500);
        } catch (error) {
            console.warn(error);
        } finally {
            closeLoader('main');
        }
    }

    _initTextsWithEditableLinks() {
        const recoverPwd = JSON.parse(this.formTxt.dataset.recoverPwd);
        const recoverEmail = JSON.parse(this.formTxt.dataset.recoverEmail);
        const registration = JSON.parse(this.endTxt?.dataset?.registration || '{}');

        this.formTxt.innerHTML = this.formTxt.innerHTML
            .replace(
                '{{recover-pwd}}',
                `<a href="${recoverPwd.href}?cb=${encodeURIComponent(getCallback())}">${recoverPwd.label}</a>`
            )
            .replace(
                '{{recover-email}}',
                `<a href="${recoverEmail.href}?cb=${encodeURIComponent(getCallback())}">${recoverEmail.label}</a>`
            );
        this.formTxt.removeAttribute('data-recover-pwd');
        this.formTxt.removeAttribute('data-recover-email');

        if (this.endTxt) {
            this.endTxt.innerHTML = this.endTxt.innerHTML.replace(
                '{{registration}}',
                `<a href="${registration.href}?cb=${encodeURIComponent(getCallback())}">${registration.label}</a>`
            );
            this.endTxt.removeAttribute('data-registration');
        }
    }

    _trackSocialLoginFunnel(socialType) {
        let funnelStep;
        switch (socialType) {
            case 'facebook':
                funnelStep = FUNNEL_STEP.loginWithFacebook;
                break;
            case 'google':
                funnelStep = FUNNEL_STEP.loginWithGoogle;
                break;
            case 'apple':
                funnelStep = FUNNEL_STEP.loginWithApple;
                break;
            default:
                break;
        }
        if (!funnelStep) return;
        if (this.originEcommerce && this.typeOfService && this.pointOfServiceId && this.userInfo) {
            trackEvent(
                TRACKABLE_EVENT.loginSocial,
                FUNNEL_NAME.login,
                funnelStep,
                this.typeOfService,
                null,
                this.userInfo
            );
        } else {
            trackEvent(TRACKABLE_EVENT.loginSocial, FUNNEL_NAME.login, funnelStep, this.typeOfService, null, {
                coop: '',
                store: '',
            });
        }
    }

    _trackLoginFunnel() {
        if (this.originEcommerce && this.typeOfService && this.pointOfServiceId && this.userInfo) {
            trackEvent(
                TRACKABLE_EVENT.loginCta,
                FUNNEL_NAME.login,
                FUNNEL_STEP.loginCta,
                this.typeOfService,
                null,
                this.userInfo
            );
        } else {
            trackEvent(TRACKABLE_EVENT.loginCta, FUNNEL_NAME.login, FUNNEL_STEP.loginCta, null, null, this.userInfo);
        }
    }
}

register.registerClass('.mw1-login', Login);
