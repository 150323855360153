import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';

export class DateBox extends Component {
    constructor(name, root) {
        super(name, root);
        this.page = document.querySelector('.ms1-page');
        this.day = this._dEl('day');
        this._addEventListeners();
    }

    _addEventListeners() {
        ['click','keydown'].forEach((e) => {
            this.root.addEventListener(e, (event) => {
                if (event instanceof KeyboardEvent && event.key !== 'Enter' && event.key !== ' ')
                    return;
                if (this.root.classList.contains(this._mod('selected'))) 
                    return;
                this.clicked();
            });
        });
    }

    clicked() {
        this._unselectAll();
        this.select();
        this._changedDay();
    }

    _unselectAll() {
        this.page.querySelectorAll(`${this.getSelector()}`).forEach((el) => {
            const elObj = register.getClass(el);
            elObj.unselect();
        });
    }

    unselect() {
        this.root.classList.remove(this._mod('selected'));
        this.root.setAttribute('aria-pressed', 'false');
    }

    select() {
        if (!this.root.classList.contains(this._mod('selected')))
            this.root.classList.add(this._mod('selected'));
        this.root.setAttribute('aria-pressed', 'true');
    }

    _changedDay() {
        const data = {
            index: this.root.dataset.index,
            date: this.day.dataset.timestamp,
        };
        const event = new CustomEvent('mt32DateChanged', { bubbles: true });
        event.data = data;
        this.root.dispatchEvent(event);
    }

    static getDateBoxData(uniqueId, extraClasses, { index, date, available, partiallyAvailable, availableText, partiallyAvailableText, exhaustedText }) {
        return {
            uniqueId: uniqueId,
            extraClasses: extraClasses,
            index: index,
            date: {
                day: new Date(date).toLocaleDateString('it-IT', { day: 'numeric', month: 'short' }),
                weekday: new Date(date).toLocaleDateString('it-IT', { weekday: 'long' }),
                timestamp: date,
            },
            available: available,
            partiallyAvailable: partiallyAvailable,
            availableText: availableText,
            partiallyAvailableText: partiallyAvailableText,
            exhaustedText: exhaustedText,

        };
    }
}

register.registerClass('.mt32-date-box', DateBox);
