import WizardComponent from '../../../../../../libs/components/wizard-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { formToJSON, jsonToForm } from '../../../../../../libs/form-to-json';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import { apiProvider } from '../../../../../../libs/api-provider';
import { FUNNEL_STEP, TRACKABLE_EVENT, FUNNEL_NAME, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { dictionary } from '../../../../../../libs/dictionary-provider';

class InsertCard extends WizardComponent {
    constructor(name, root) {
        super(name, root);

        this.accordion = this._dEl('accordion');
        this.accordionHeading = this._dEl('accordionHeading');
        this.accordionContent = this._dEl('accordionContent');
        this.form = this._dEl('form');
        this.cardNumber = this._dEl('cardNumber');
        this.verify = this._dEl('verify');
        this.continueTxt = this._dEl('continueTxt');
        this.continueBtn = this._dEl('continueBtn');
        this.error = this._dEl('error');
        this.loginUrl = this.root.dataset.loginUrl;

        //this._checkForm();
        this._addEventListeners();
        this._addStoreListeners();
    }

    /* override */
    open() {
        super.open();
        const ecRegistrationData = storeManager.get('ecRegistrationData');
        const pointOfServiceId = ecRegistrationData?.pointOfServiceId;
        const cooperativeId = ecRegistrationData?.cooperativeId;
        const selectedPdv = storeManager.get('selectedPdv');
        this.userInfo = null;
        if (pointOfServiceId && cooperativeId) {
            /* case pdv selected on ecommerce (registration flow from ecommerce) */
            this.userInfo = {
                coop: cooperativeId,
                store: pointOfServiceId,
            };
        } else if (selectedPdv) {
            /* case pdv selected together with type of service (registration flow from ecommerce) */
            this.userInfo = {
                coop: selectedPdv.codiceCooperativa,
                store: selectedPdv.anacanId,
            };
        }
        this._trackRegistrationFunnel(TRACKABLE_EVENT.pageview, FUNNEL_STEP.cardInsertionChoice, this.userInfo);
    }

    _addEventListeners() {
        this.accordionHeading.addEventListener('click', () => {
            if (!this.isOpen()) {
                // about to be opened
                this.accordionContent.style.visibility = 'visible';
            }
            this.accordion.classList.toggle(this._elMod('accordion', 'show'));
            if (this.isOpen()) {
                this._trackRegistrationFunnel(
                    TRACKABLE_EVENT.insertExistingCard,
                    FUNNEL_STEP.insertInsiemeCardData,
                    this.userInfo
                );
                this._trackRegistrationFunnel(TRACKABLE_EVENT.pageview, FUNNEL_STEP.insiemeCardDataForm, this.userInfo);
            }
            this.updateContentHeight();
            this._updateAriaAttributes();
            if (!this.isOpen()) {
                // just closed
                this.accordionContent.style.visibility = 'hidden';
            }
        });
        /*this.form.addEventListener('ecInputChanged', () => {
            this._checkForm();
            if (!this.error.classList.contains(this._elMod('error', 'hidden'))) {
                this.error.classList.add(this._elMod('error', 'hidden'));
            }
        });*/
        this.form.addEventListener('submit', async (event) => {
            event.preventDefault();
            if (!this.form.checkValidity() || !this._validDates(this.form)) {
                const first = this.form.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }
            this._trackRegistrationFunnel(
                TRACKABLE_EVENT.verifyInsiemeCard,
                FUNNEL_STEP.verifyInsiemeCardCta,
                this.userInfo
            );
            this._verifyCard();
        });
        this.continueBtn.addEventListener('click', () => {
            this._trackRegistrationFunnel(
                TRACKABLE_EVENT.nextNoExistingCard,
                FUNNEL_STEP.nextNoExistingCard,
                this.userInfo
            );
            flowManager.next('register-light');
        });

        /* form errors */
        this.form.addEventListener('ecFormError', (event) => {
            event.preventDefault();
            if (!event.data.errorField) return;
            this._trackRegistrationFunnel(TRACKABLE_EVENT.formError, FUNNEL_STEP.insiemeCardDataForm, this.userInfo, {
                errorField: event.data.errorField,
                errorText: event.data.errorText,
            });
            event.stopPropagation();
        });
    }

    _addStoreListeners() {
        storeManager.on('userData', (path, data) => {
            if (data.socialRegistration) jsonToForm(this.form, data);
        });
    }

    _validDates(form) {
        if (!form) return false;
        const validities = Array.from(form.querySelectorAll('.mt23-datepicker')).map((el) => {
            const elObj = register.getClass(el);
            return {
                name: elObj.getName(),
                value: elObj.getValue(),
                valid: elObj.isValid(),
            };
        });
        //console.log(validities);
        const result = validities.filter((el) => !el.valid).length <= 0;
        //console.log(result);
        return result;
    }

    async _verifyCard() {
        let result = false;
        let anacanId = null;
        try {
            openLoader('main');
            const userData = {
                ...formToJSON(this.form),
                bimodalCard: true,
            };

            const cardData = await apiProvider.verifyCard(userData);
            if (cardData) {
                result = true;

                //change current user data
                if (storeManager.get('userData')?.socialRegistration) {
                    const currentUserData = storeManager.get('userData');
                    const override = {};
                    if (currentUserData.email) override.email = currentUserData.email;
                    //merge current social data + user data from form + carda data from service + override with social email
                    const finalData = { ...currentUserData, ...userData, ...cardData, ...override };
                    storeManager.emit('noCardUserData', currentUserData);
                    storeManager.emit('userData', finalData);
                } else {
                    storeManager.emit('noCardUserData', {});
                    storeManager.emit('userData', { ...userData, ...cardData });
                }
            }

            //set pdv
            if (cardData?.anacanId) {
                const pdv = await apiProvider.getPointOfServiceByAnacanId(cardData);
                if (pdv) {
                    anacanId = pdv.anacanId;
                    storeManager.emit('selectedPdv', pdv);
                }
            }
        } catch (error) {
            this.ISError = error;
            console.warn('Cannot call verify card check', error);
        } finally {
            closeLoader('main');
        }

        if (result) {
            /* skip register-bimodal on social registration */
            if (!anacanId || !storeManager.get('userData')?.socialRegistration) {
                flowManager.next('register-bimodal');
            } else {
                flowManager.next('privacy');
            }
        } else {
            let errorText = '';
            if (this.ISError?.codice) {
                errorText = `${await (dictionary.getFEMessage(this.ISError.codice) || this.ISError.errore)}`;
                if (this.ISError.codice == 'IS_ERR:CARTA_GIA_REGISTRATA') {
                    const recoverText = `<a href="${this.loginUrl}">${dictionary.get(
                        'Click here'
                    )}</a> ${dictionary.get('to login or recover the data')}.`;
                    errorText = `${errorText} ${recoverText}`;
                }
            } else {
                errorText = this.root.dataset.genericError;
            }
            this.error.innerHTML = errorText;
            this.error.classList.remove(this._elMod('error', 'hidden'));
            this._trackRegistrationFunnel(TRACKABLE_EVENT.formError, FUNNEL_STEP.insiemeCardDataForm, this.userInfo, {
                errorField: dictionary.getFEMessage('formSubmitError'),
                errorText: errorText,
            });
        }
    }

    _trackRegistrationFunnel(event, funnelStep, userInfoObj = null, formDataObj = null) {
        const typeOfService = storeManager.get('ecRegistrationData')?.typeOfService;
        const originEcommerce = storeManager.get('ecRegistrationData')?.originEcommerce;
        const userInfo =
            userInfoObj && userInfoObj.coop && userInfoObj.store
                ? {
                      coop: userInfoObj.coop,
                      store: userInfoObj.store,
                  }
                : null;
        const formData =
            formDataObj && (formDataObj.errorField || formDataObj.errorText)
                ? {
                      errorField: formDataObj.errorField,
                      errorText: formDataObj.errorText,
                  }
                : null;
        if (storeManager.get('currentFlow').includes('conad-register')) {
            if (originEcommerce && typeOfService) {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, typeOfService, null, userInfo, formData);
            } else {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, null, null, userInfo, formData);
            }
        }
    }

    isOpen() {
        return this.accordion.classList.contains(this._elMod('accordion', 'show'));
    }

    updateContentHeight() {
        this.accordionContent.style.maxHeight = `${!this.isOpen() ? 1 : this.accordionContent.scrollHeight + 100}px`;
    }

    _updateAriaAttributes() {
        const isOpen = this.isOpen();
        this.accordionHeading.setAttribute('aria-expanded', `${isOpen ? 'true' : 'false'}`);
        this.accordionContent.setAttribute('aria-hidden', `${isOpen ? 'false' : 'true'}`);
    }
}

register.registerClass('.mw8-insert-card', InsertCard);
