import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { register } from '../../../../../../libs/register';

class GenericError extends PopupComponent {
    constructor(name, root) {
        super(name, root);
    }

    _onBeforeOpen() {
        super._onBeforeOpen();
        const data = flowManager.getDataFromFlow();
        if (data?.errorMessage) {
            this._dEl('text').innerHTML = data.errorMessage;
        } else {
            this._dEl('text').innerHTML = '';
        }
    }

    _onClose() {
        super._onClose();
        this._dEl('text').innerHTML = '';
    }

    /* override */
    async _onCancel() {
        flowManager.complete();
    }
}

register.registerClass('.mp20-generic-error', GenericError);
