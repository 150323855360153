import { apiProvider } from '../../../../../../libs/api-provider';
import WizardComponent from '../../../../../../libs/components/wizard-component';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { formToJSON } from '../../../../../../libs/form-to-json';
import { register } from '../../../../../../libs/register';
import { isPositive } from '../../../../../../libs/utils';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';

class MigrationStep2 extends WizardComponent {
    constructor(name, root) {
        super(name, root);

        this.error = this._dEl('error');
        this.form = this._dEl('form');
        this.emptyForm = this.form?.querySelectorAll('[data-is-validable]')?.length <= 0;
        this.next = this._dEl('next');
        //if (this.emptyForm) this._enableSubmit();
        this.deleteAccount = this._dEl('deleteAccount');
        this.privacyText = this._dEl('privacyText');
        this.privacyLink = this.privacyText.dataset.privacyLink;
        this.privacyLinkLabel = this.privacyText.dataset.privacyLinkLabel || 'click here';
        this.privacyVersion = this.privacyText.dataset.privacyVersion;
        this.privacyLink ? this._setInformativa() : console.warn('Could not set informativa');

        this.currentPrivacy = null;
        this.privacyBoxes = this._dEl('box', true);
        this.password = this._dEl('password');
        if (this.password) this.passwObj = register.getClass(this.password);
        this.passwordRepeat = this._dEl('confirmPassword');
        if (this.passwordRepeat) this.passwRepeatObj = register.getClass(this.passwordRepeat);
        this.passwordRules = this._dEl('passwordRules');
        this.passwordState = this._dEl('passwordState');
        this.passwordMismatch = this._dEl('passwordMismatch');
        if (this.passwordState) {
            this.passwordStateOk = this.passwordState.querySelector('.ok');
            this.passwordStateError = this.passwordState.querySelector('.error');
        }

        this._addEventListeners();
    }

    _addEventListeners() {
        this.form.addEventListener('submit', (event) => {
            event.preventDefault();
            event.stopPropagation();
        });
        /*this.form.addEventListener('ecInputChanged', (event) => {
            event.preventDefault();
            !event.data.valid
                ? this._disableSubmit()
                : this._getFormValidity()
                    ? this._enableSubmit()
                    : this._disableSubmit();
            event.stopPropagation();
        });*/
        this.next.addEventListener('click', (event) => {
            event.preventDefault();
            if (!this._getFormValidity()) {
                const first = this.form.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }
            /*if (this._submitDisabled()) {
                return;
            }*/
            this.emptyForm 
                ? this._activateProfile()
                : this._migrationStep2();
        });
        this.deleteAccount.addEventListener('click', (event) => {
            event.preventDefault();
            this._deleteProfile();
        });
        this.privacyBoxes?.forEach((box) => {
            box?.addEventListener('ecInputChanged', (event) => {
                this._checkRadio(box, isPositive(event.data.value));
            });
        });
        this.password?.addEventListener('ecInputChanged', () => {
            this._checkShowPasswordRules();
        });
        this.password?.querySelector('input').addEventListener('focus', () => {
            this._checkShowPasswordRules();
        });
        this.passwordRepeat?.addEventListener('ecInputChanged', () => {
            this._checkPasswordRepeat();
        });
    }

    _getFormValidity() {
        if (!this.form.checkValidity()) {
            return false;
        }
        return true;
    }

    _checkRadio(box, valid) {
        if (!box) return;
        const boxNotSelected = box.querySelector(this._el('boxNotSelected', true));
        if (!valid) {
            boxNotSelected.classList.add(this._elMod('boxNotSelected', 'show'));
        } else {
            boxNotSelected.classList.remove(this._elMod('boxNotSelected', 'show'));
        }
    }

    _checkShowPasswordRules() {
        if (!this.passwordRules || !this.passwordState) return;
        this.passwordRules?.classList.remove(this._elMod('passwordRules', 'hidden'));
        if (this.passwObj?.isValid()) {
            this.passwordStateOk?.classList.add('active');
            this.passwordStateError?.classList.remove('active');
        } else {
            this.passwordStateOk?.classList.remove('active');
            this.passwordStateError?.classList.add('active');
        }
    }

    _checkPasswordRepeat() {
        if (!this.password || !this.passwordRules || !this.passwordState) return;
        const passwordRepeatError = this.passwordRepeat?.querySelector('.mt8-textfield__error');
        if (this.passwObj?.getValue() !== '' && 
            this.passwObj?.isValid() && 
            this.passwRepeatObj?.getValue() !== '' &&
            this.passwRepeatObj?.getValue() !== this.passwObj.getValue()) {
            this.passwordMismatch?.classList.remove(this._elMod('passwordMismatch', 'hidden'));
            this.passwRepeatObj?.setState('error');
            if (passwordRepeatError) passwordRepeatError.style.display = 'none';
            //this._disableSubmit();
        } else {
            passwordRepeatError?.removeAttribute('style');
            if (!this.passwordMismatch?.classList.contains(this._elMod('passwordMismatch', 'hidden')))
                this.passwordMismatch?.classList.add(this._elMod('passwordMismatch', 'hidden'));
            if (this.passwRepeatObj?.getValue() !== '' && 
                this.passwRepeatObj?.getValue() === this.passwObj?.getValue()) {
                this.passwRepeatObj?.setState('valid');
                //this._enableSubmit();
            }
        }
    }

    /*_enableSubmit() {
        this.next?.classList?.add(this._elMod('next', 'active'));
        this.next?.removeAttribute('title');
    }
    async _disableSubmit() {
        this.next?.classList?.remove(this._elMod('next', 'active'));
        this.next?.setAttribute('title', dictionary.getFEMessage('fillInFields'));
    }
    _submitDisabled() {
        return !this.next.classList.contains(this._elMod('next', 'active'));
    }*/

    _setInformativa() {
        const privacyLink = `<a href="${this.privacyLink}" title="informativa ${this.privacyVersion}" target="_blank">${this.privacyLinkLabel}</a>`;
        this.privacyText.innerHTML = this.privacyText.innerHTML.replace('$linkInformativa', privacyLink);
    }

    async _activateProfile() {
        console.log('Activate profile!');
        let result = false;
        try {
            openLoader('main');
            const data = {};
            const firmaDigitale = await apiProvider.getFirmaDigitale();
            data.firmaDigitale = firmaDigitale;
            result = await apiProvider.activateUser(data);
        } catch (error) {
            this.ISError = error;
            console.warn('Cannot activate user', error);
        } finally {
            closeLoader('main');
        }

        if (!result) {
            this.error.innerHTML = this?.ISError?.codice
                ? await (dictionary.getFEMessage(this.ISError.codice) || this.ISError.errore)
                : this.root.dataset.genericError;
            this.error.classList.remove(this._elMod('error', 'hidden'));
        } else {
            flowManager.next('migration-thank-you');
        }
    }

    _deleteProfile() {
        flowManager.next('migration-delete-profile');
    }

    async _migrationStep2() {
        let result = false;
        try {
            openLoader('main');
            const data = {
                ...formToJSON(this.form),
            };
            /* if radios not selected, default to 'N' */
            if (this.privacyBoxes) {
                if (!data.privacy1) data.privacy1 = 'N';
                if (!data.privacy2) data.privacy2 = 'N';
                if (!data.privacy3) data.privacy3 = 'N';
            }
            const firmaDigitale = await apiProvider.getFirmaDigitale();
            data.firmaDigitale = firmaDigitale;
            result = await apiProvider.migrationStep2(data);
        } catch (error) {
            this.ISError = error;
            console.warn(error);
        } finally {
            closeLoader('main');
        }

        if (!result) {
            this.error.innerHTML = this?.ISError?.codice
                ? await (dictionary.getFEMessage(this.ISError.codice) || this.ISError.errore)
                : this.root.dataset.genericError;
            this.error.classList.remove(this._elMod('error', 'hidden'));
            window.scrollTo(0,0);
        } else {
            flowManager.next('migration-thank-you');
        }
    }
}

register.registerClass('.mw18-migration-step2', MigrationStep2);
