import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { register } from '../../../../../../libs/register';
import { getTrackingManager } from '../../../../../../libs/tracking-manager';

class RefreshThankYou extends PopupComponent {
    constructor(name, root) {
        super(name, root);
        this.trackingManager = getTrackingManager();
    }

    _onBeforeOpen() {
        super._onBeforeOpen();
        const data = flowManager.getDataFromFlow();
        if (data?.changedMailLogin) {
            this._dEl('additionalText').innerHTML = this._dEl('additionalText').innerHTML.replace('$email', data.changedMailLogin);
            this._dEl('additionalText').classList.remove(this._elMod('additionalText', 'hidden'));
        } else {
            if (!this._dEl('additionalText').classList.contains(this._elMod('additionalText', 'hidden'))) {
                this._dEl('additionalText').classList.add(this._elMod('additionalText', 'hidden'));
            }
        }
/*        if (data?.choice) {
            let text = this._dEl('text');
            if (data.choice === 'shoppingCoupon') {
                text.innerHTML = text.innerHTML.replace('$choice', text.dataset.shoppingCouponText);
            }
            if (data.choice === 'travelCoupon') {
                text.innerHTML = text.innerHTML.replace('$choice', text.dataset.travelCouponText);
            }
        }*/
    }

    async _onOpen() {
        super._onOpen();

        await this.trackingManager.track(this.root, {
            event: 'pageview',
            funnel: {
                nomeFunnel:'Conad Refresh',
                stepFunnel:'TYP aggiornamento dati'
            }
        });
    }

    /* override */
    _onCancel() {
        flowManager.backOrComplete();
    }
}

register.registerClass('.mp26-refresh-thank-you', RefreshThankYou);
