import Component from '../../../../../../../libs/components/component';
import { register } from '../../../../../../../libs/register';
import { delay } from '../../../../../../../libs/utils';
import styledMapFeatures from './styled-map';

import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { BREAKPOINTS } from '../../../../../../../libs/constants';
import { flowManager } from '../../../../../../../libs/flow-manager';
import { gMapsHelper } from '../../../../../../../libs/gmaps-helper';
import { runTemplate } from '../../../../../../../libs/htl-runtime/HTMLRuntime';
import { getPoiType } from '../../../../../../../libs/pdv-utils';
import mapPinTemplate from '../../../../templates/mt34-map-pin/mt34-map-pin.html';
import { ClusterPinRenderer } from '../../../../templates/mt34-map-pin/scripts/cluster-pin-renderer';
import { htmlMapPin } from '../../../../templates/mt34-map-pin/scripts/html-map-pin';

export default class SelectPdvMap extends Component {
    constructor(name, root) {
        super(name, root);

        this.BREAKPOINT_L = BREAKPOINTS.l;
        this.PRE_SHOW = this._mod('preShow');
        this.SHOW = this._mod('show');
        this.mapEl = this._dEl('map');
        this.map = null;
        this.markerClusterer = null;
        this.markers = [];
        this.markersMap = {};
        this.selectedMarker = null;
        this.closer = this._dEl('closer');
        this.zoomIn = this._dEl('zoomIn');
        this.zoomOut = this._dEl('zoomOut');

        this._addEventListeners();
    }

    async init(listObj) {
        this.listObj = listObj;
        this.root.classList.add(this.PRE_SHOW);
        if (!this.map) {
            const maps = await gMapsHelper.getGMaps();
            this.map = new maps.Map(this.mapEl, {
                center: { lat: 43, lng: 12 },
                zoom: 10,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                zoomControl: false,
            });

            const styledMapType = new maps.StyledMapType(styledMapFeatures);
            this.map.mapTypes.set('styled_map', styledMapType);
            this.map.setMapTypeId('styled_map');
        }

        await delay(500);
        this.root.classList.add(this.SHOW);
    }

    async addResults(stores) {
        if (!stores || stores.length <= 0) return;
        const maps = await gMapsHelper.getGMaps();

        if (this.markers.length > 0) {
            this._emptyMarkers();
        }

        this.currentBounds = new maps.LatLngBounds();
        for (const poi of stores) {
            /* get latlng */
            const position = new maps.LatLng(poi.latitudine, poi.longitudine);
            /* get marker element dynamically */
            const pinData = {
                uniqueId: `pin-${poi.anacanId}`,
                pinId: poi.anacanId,
                type: getPoiType(poi),
            };
            const mapPin = runTemplate(mapPinTemplate, pinData);
            /* create map marker */
            const marker = htmlMapPin({
                position: position,
                elem: mapPin,
                map: this.map,
            });

            /* set map params on pin object */
            const markerObj = register.getClass(mapPin);
            markerObj.setMapParams(this.map, position, marker, poi);

            /* add marker to the map */
            this.markersMap[poi.anacanId] = markerObj;
            this.markers.push(marker);
            this.currentBounds.extend(position);
        }

        this.map.fitBounds(this.currentBounds);
        this.markerClusterer?.reset();
        this.markerClusterer = new MarkerClusterer({
            map: this.map,
            markers: this.markers,
            renderer: new ClusterPinRenderer(this.map),
        });
    }

    selectPoi(anacanId, panTo = true) {
        const pinObj = this.markersMap[anacanId];
        if (!pinObj || !pinObj.getPosition()) {
            console.warn(`Cannot find marker for store ${anacanId}`);
            return;
        }

        this.resetPin(pinObj);

        if (panTo) {
            this.map.panTo(pinObj.getPosition());
            this.map.setZoom(17);
        }

        this.selectedMarker = pinObj;
    }

    async destroy() {
        this.root.classList.remove(this.PRE_SHOW);
        this.root.classList.remove(this.SHOW);
    }

    resetPin(selectedPinObj = null) {
        //reset pins
        for (const prop in this.markersMap) {
            if (Object.hasOwn(this.markersMap, prop)) {
                if (selectedPinObj == this.markersMap[prop]) this.markersMap[prop].setSelected(true);
                else this.markersMap[prop].setSelected(false);
            }
        }
    }

    /* called by select-pdv */
    panSelected() {
        if (this.selectedMarker) {
            const pos = this.selectedMarker.getPosition();
            this.map.panTo(pos);
            this.map.setZoom(15);
        } else if (this.currentBounds) {
            this.map.fitBounds(this.currentBounds);
        } else {
            this.map.panTo({ lat: 43, lng: 12 });
            this.map.setZoom(6);
        }
    }

    _emptyMarkers() {
        for (const marker of this.markers) {
            marker.setMap(null);
        }
        this.markers = [];
        this.markersMap = {};
        this.selectedMarker = null;
        this.markerClusterer?.clearMarkers();
    }

    _addEventListeners() {
        this.closer.addEventListener('click', (event) => {
            event.preventDefault();
            flowManager.backOrComplete();
        });
        this.zoomIn.addEventListener('click', (event) => {
            event.preventDefault();
            if (this.map) this.map.setZoom(this.map.getZoom() + 1);
        });
        this.zoomOut.addEventListener('click', (event) => {
            event.preventDefault();
            if (this.map) this.map.setZoom(this.map.getZoom() - 1);
        });
        this.mapEl.addEventListener('clickedStore', (event) => {
            const data = event.data;
            if (!data.pinId) return;

            this.selectPoi(data.pinId, false);
            this.listObj.setSelectedPof(data.pinId);
            if (window.innerWidth >= this.BREAKPOINT_L) {
                /* desktop: list is visible, select store directly */
                this.listObj.selectPof(data.pinId, true, false);
            }
        });
        this.mapEl.addEventListener('clickedAggregator', (event) => {
            const data = event.data;
            if (!data.position) return;

            this.resetPin();

            this.map.panTo(data.position);
            this.map.setZoom(this.map?.getZoom() < 15 ? this.map?.getZoom() + 2 : this.map?.getZoom() + 1);
        });
    }
}

register.registerClass('.mm6-select-pdv-map', SelectPdvMap);
