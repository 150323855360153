import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';
import { flowManager } from '../../../../../libs/flow-manager';
import { storeManager } from '../../../../../libs/store-manager';
import { apiProvider } from '../../../../../libs/api-provider';
import { closeLoader, openLoader } from '../mt11-loader/script';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../libs/tracking-manager-old';

class Address extends Component {
    constructor(name, root) {
        super(name, root);
        this.addresses = document.querySelector('.mf4-addresses');
        this.id = this.root.id;
        this.types = ['delivery', 'billing'];
        this.type = this.root.dataset.addressType;
        this.favorite = this.root.hasAttribute('data-favorite');
        this.labelSetFavorite = this.root.dataset.labelSetFavorite;
        this.labelFavorite = this.root.dataset.labelFavorite;
        this.favoriteIcon = this._dEl('favoriteIcon');
        this.ctaModify = this._dEl('ctaModify');
        this.address = JSON.parse(this.root.dataset.address);
        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        this.favoriteIcon.addEventListener('click', async (event) => {
            event.preventDefault();
            if (this.type === 'delivery') trackEvent(TRACKABLE_EVENT.favoriteAddress, FUNNEL_NAME.addressHandling, FUNNEL_STEP.favoriteDeliveryAddress);
            if (this.type === 'billing') trackEvent(TRACKABLE_EVENT.favoriteAddress, FUNNEL_NAME.addressHandling, FUNNEL_STEP.favoriteBillingAddress);
            await this._setFavoriteAddress();
        });
        this.ctaModify.addEventListener('click', (event) => {
            event.preventDefault();
            this._refreshEcTime();
            if (this.ecTime && new Date().getTime() - this.ecTime > this.ecTimeThreshold) {
                this._confirmLogin('modifyAddress');
                return;
            }
            if (this.type === 'billing') {
                trackEvent(TRACKABLE_EVENT.modifyAddress, FUNNEL_NAME.addressHandling, FUNNEL_STEP.modifyBillingAddress);
                const data = {
                    newAddress: false,
                    address: JSON.parse(this.root.dataset.address)
                };
                storeManager.emit('currentBillingAddress', data);
                flowManager.startFlow({
                    flowName: 'billing-add',
                    flowSteps: [{ name: 'billing-address' }, { name: 'delete-address' }],
                });
            }
            if (this.type === 'delivery') {
                trackEvent(TRACKABLE_EVENT.modifyAddress, FUNNEL_NAME.addressHandling, FUNNEL_STEP.modifyDeliveryAddress);
                const data = {
                    newAddress: false,
                    address: JSON.parse(this.root.dataset.address)
                };
                storeManager.emit('currentDeliveryAddress', data);
                flowManager.startFlow({
                    flowName: 'delivery-add',
                    flowSteps: [{ name: 'delivery-address' }, { name: 'delete-address' }],
                });
            }
        });
    }

    _addStoreListeners() {
        storeManager.on('mm10LoginConfirmed', async (path, data) => {
            if (!data.confirmed) {
                console.warn('Login confirmation failed');
                return;
            }
            if (!data.calleeId || data.calleeId !== this.id) {
                return;
            }
            const submitEl = data.submitEl;
            if (!submitEl) {
                console.warn('Missing submitEl on confirmed login');
                return;
            }
            switch (submitEl) {
                case 'modifyAddress': {
                    if (this.type === 'billing') {
                        trackEvent(TRACKABLE_EVENT.modifyAddress, FUNNEL_NAME.addressHandling, FUNNEL_STEP.modifyBillingAddress);
                        const data = {
                            newAddress: false,
                            address: JSON.parse(this.root.dataset.address)
                        };
                        storeManager.emit('currentBillingAddress', data);
                        flowManager.startFlow({
                            flowName: 'billing-add',
                            flowSteps: [{ name: 'billing-address' }, { name: 'delete-address' }],
                        });
                    }
                    if (this.type === 'delivery') {
                        trackEvent(TRACKABLE_EVENT.modifyAddress, FUNNEL_NAME.addressHandling, FUNNEL_STEP.modifyDeliveryAddress);
                        const data = {
                            newAddress: false,
                            address: JSON.parse(this.root.dataset.address)
                        };
                        storeManager.emit('currentDeliveryAddress', data);
                        flowManager.startFlow({
                            flowName: 'delivery-add',
                            flowSteps: [{ name: 'delivery-address' }, { name: 'delete-address' }],
                        });
                    }
                    break;
                }
                default:
                    break;
            }
        });
    }

    unsetFavorites() {
        if (!this.addresses || !this.addresses.objReference) {
            console.warn('[mt15-address] mf4-addresses not found in current page');
            return;
        }
        /* get all mt15-address of the same type */
        let addresses = this.addresses.querySelectorAll(`.mt15-address[data-address-type="${this.type}"]`);
        addresses.forEach((addr) => {
            /* skip myself */
            if (addr.id === this.id) return;
            /* get class of my peer and make it unset its favorite */
            let addrClass = register.getClass(addr);
            addrClass.setFavorite(false);
        });
    }

    setFavorite(favorite) {
        this.favorite = favorite;
        favorite ? 
            this.root.setAttribute('data-favorite', true) 
            : this.root.removeAttribute('data-favorite');
        const icon_class = `ec-icon-like-${favorite ? 'on' : 'off'}`;
        this.favoriteIcon.classList.remove('ec-icon-like-on');
        this.favoriteIcon.classList.remove('ec-icon-like-off');
        this.favoriteIcon.classList.add(icon_class);
        const label = favorite ? this.labelFavorite : this.labelSetFavorite;
        this.favoriteIcon.setAttribute('title', label);
        this.favoriteIcon.setAttribute('aria-label', label);
    }

    getFavorite() {
        return this.favorite;
    }

    async _setFavoriteAddress() {
        /* skip logic if already favorite */
        if (this.favorite) return;
        /* create request */
        const data = this.address;
        openLoader('main');
        /* call apiProvider */
        try {
            if (this.type === 'delivery') {
                data.defaultAddress = true;
                await apiProvider.editDeliveryAddress(data);
            } else if (this.type === 'billing') {
                data.defaultBillingAddress = true;
                await apiProvider.editBillingAddress(data);
            }
            /* unset all favorites of the same type */
            this.unsetFavorites();
            /* set clicked address as favorite */
            this.setFavorite(true);
        } catch (error) {
            console.warn(error);
        } finally {
            closeLoader('main');
        }
    }

    _refreshEcTime() {
        this.ecTime = storeManager.get('perslogData')?.ecTime || null;
        this.ecTimeThreshold = storeManager.get('perslogData').ecTimeThreshold;
    }

    _confirmLogin(submitEl) {
        flowManager.startFlow({
            flowName: 'confirm-login',
            flowSteps: [{ name: 'confirm-login' }],
            options: { 
                submitEl: submitEl,
                calleeId: this.id,
            },
        });
    }
}

register.registerClass('.mt15-address', Address);
