import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';

class Instructions extends Component {
    constructor(name, root) {
        super(name, root);
        this.heading = this._dEl('heading');
        this.content = this._dEl('content');
        this.title = this._dEl('title');
        this._addEventListeners();
    }

    _addEventListeners() {
        this.title.addEventListener('click', (event) => {
            event.preventDefault();
            this.heading.classList.toggle(this._elMod('heading', 'open'));
            this.updateContentHeight();
            this._updateAriaAttributes();
        });
        window.addEventListener('resize', () => {
            if (!this.isOpen()) return;
            this.updateContentHeight();
        });
    }

    isOpen() {
        return this.heading.classList.contains(this._elMod('heading', 'open'));
    }

    updateContentHeight() {
        this.content.style.maxHeight = `${!this.isOpen() ? 1 : this.content.scrollHeight + 100}px`;
    }

    _updateAriaAttributes() {
        const isOpen = this.isOpen();
        this.heading.setAttribute('aria-expanded', `${isOpen ? 'true' : 'false'}`);
        this.content.setAttribute('aria-hidden', `${isOpen ? 'false' : 'true'}`);
    }
}

register.registerClass('.mt36-instructions', Instructions);
