import { apiProvider } from '../../../../../../libs/api-provider';
import PopupComponent from '../../../../../../libs/components/popup-component';
import { ecApiProvider } from '../../../../../../libs/ecommerce-api-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';

class RepeatOrder extends PopupComponent {
    constructor(name, root) {
        super(name, root);

        this.ctaAction = this._dEl('ctaAction');
        this.ecDetailCart = this.root.dataset.ecDetailCart;
        this.currentCartId = window.accessInfo.cart;

        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        this.ctaAction.addEventListener('click', async (event) => {
            event.preventDefault();
            if (!this.ecDetailCart) {
                console.warn('Ecommerce detail cart page url not found');
                return;
            }

            try {
                openLoader('main');
                await this._deleteCart();
                await this._repeatOrder();
                flowManager.complete();
                window.location = `${this.ecDetailCart}`;
            } catch (error) {
                console.warn(error.errorMessage);
                if ((error.errorMessage.indexOf("No PointOfServce and Delivery Area available") > -1) ||
                    (error.errorMessage.indexOf("Address not covered by deliveryArea") > -1) )
                {
                    flowManager.appendDataToFlow({ errorType: "HD" })
                    flowManager.next("reorder-error");
                } else if (error.errorMessage.indexOf("PointOfService not available") > -1) {
                    flowManager.appendDataToFlow({ errorType: "OC" })
                    flowManager.next("reorder-error");
                }
            } finally {
                closeLoader('main');
            }
        });
    }

    _addStoreListeners() {
        storeManager.on('selectedOrder', (path, data) => {
            this.selectedOrder = data;
        });
    }

    async _deleteCart() {
        if (!this.currentCartId || this.currentCartId == '') {
            console.log('cart id not provided, skipping delete cart');
            return;
        }
        try {
            await ecApiProvider.deleteCart();
        } catch (error) {
            console.warn(error);
        }
    }

    async _repeatOrder() {
        const data = {
            pointOfServiceId: this.selectedOrder.pointOfServiceId,
            reorderId: this.selectedOrder.orderCode,
            becommerce: this.selectedOrder.becommerce,
            typeOfService: this.selectedOrder.typeOfService,
            deliveryArea: this.selectedOrder.deliveryArea,
        };
        await apiProvider.addCart(data);
    }

    async _onCancel() {
        flowManager.backOrComplete();
    }
}

register.registerClass('.mp7-repeat-order', RepeatOrder);
