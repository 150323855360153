import PopupComponent from '../../../../../../libs/components/popup-component';
import { register } from '../../../../../../libs/register';
import { flowManager } from '../../../../../../libs/flow-manager';

class BolliniTemporaryBlock extends PopupComponent {
    constructor(name, root) {
        super(name, root);
        this.ctaAction = this._dEl('ctaAction');

        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener(
            'click',
            (event) => {
                event.preventDefault();
                flowManager.complete();
            },
            this.ctaAction
        );
    }

    /* override */
    async _onCancel() {
        flowManager.complete();
    }
}

register.registerClass('.mp27-bollini-temporary-block', BolliniTemporaryBlock);
