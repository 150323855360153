import Component from '../../../../../libs/components/component';
import { dictionary } from '../../../../../libs/dictionary-provider';
import { register } from '../../../../../libs/register';

class TextfieldLocked extends Component {
    constructor(name, root) {
        super(name, root);
        this.label = this._dEl('label');
        this.value = this._dEl('value');
    }

    async setLabel(label) {
        this.label.textContent = dictionary.get(label);
    }

    async setValue(value) {
        this.value.textContent = dictionary.get(value);
    }
}

register.registerClass('.mt31-textfield-locked', TextfieldLocked);
