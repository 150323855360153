import Component from '../../../../../../libs/components/component';
import { register } from '../../../../../../libs/register';
import { flowManager } from '../../../../../../libs/flow-manager';
import { apiProvider } from '../../../../../../libs/api-provider';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';

class MyStores extends Component {

    constructor(name, root) {
        super(name, root);

        this.addStores = this._dEl('addStores');
        this.storesContainer = this._dEl('containerOtherStores');

        this._addEventListeners();
        this._addStoreListeners();
    }

    async _addEventListeners(){
        this._addListener(
            'click',
            () => this._startModifyStore(),
            this.addStores);
    }

    _addStoreListeners(){
        this._addStoreListener('selectedStore', async (key, store) => {
            const anacanId = store.anacanId;
            await openLoader('main');
            try{
                /*const response = await apiProvider
                    .associateComplementaryData('','savedStores', { anacanId });*/
                const response = await apiProvider.setSavedStore(anacanId);
                if(response)
                    window.location.reload();
            }catch(error){
                console.error();
            }finally{
                await closeLoader('main');
            }
        });
    }
    _startModifyStore() {
        this._emit('setModality', { modality: 'selectPdv' });
        flowManager.startFlow({
            flowName: 'select-pdv',
            flowSteps: [{ name: 'select-pdv' }]
        });
    }
}

register.registerClass('.mf17-my-stores', MyStores);
