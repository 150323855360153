import PerfectScrollbar from 'perfect-scrollbar';
import PopupComponent from '../../../../../libs/components/popup-component';
import { register } from '../../../../../libs/register';
import { Popup } from '../mt4-popup/script';
import popupTpl from './jscall.html';
import { runWithData } from '../../../../../libs/htl-runtime/HTMLRuntime';

export class BasicPopup extends PopupComponent {
    constructor(name, root) {
        super(name, root);
        this.messageText = this._dEl('text');
        this.ctaAction = this._dEl('ctaAction');
        const scrollbarOptions = {
            swipeEasing: true,
            suppressScrollX: true,
        };
        this.ps = new PerfectScrollbar(this.messageText, scrollbarOptions);
        this.cancelled = true;
        this._addEventListeners();
    }

    _addEventListeners() {
        this.ctaAction.addEventListener('click', (event) => {
            event.preventDefault();
            this.cancelled = false;
            this.popup.close();
        });
    }

    setOnAction(onAction) {
        this.onAction = onAction;
    }

    setOnCancel(onCancel) {
        this.onCancel = onCancel;
    }

    static async showMainPopup(data, onAction, onCancel) {
        const el = runWithData(popupTpl, data);
        const popup = Popup.findPopup('main');
        await popup.openContent(el);

        const basicObj = register.getClass(el);
        basicObj.setOnAction(onAction);
        basicObj.setOnCancel(onCancel);
    }

    async _onClose() {
        if (this.cancelled && this.onCancel) this.onCancel();
        else if(this.onAction) this.onAction();

        this.onAction = null;
        this.onCancel = null;
    }
}

register.registerClass('.mt26-basic-popup', BasicPopup);
