import PopupComponent from '../../../../../../libs/components/popup-component';
import { register } from '../../../../../../libs/register';

class GccThankyou extends PopupComponent {
    constructor(name, root) {
        super(name, root);
    }
}

register.registerClass('.mp11-gcc-thankyou', GccThankyou);
