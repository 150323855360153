import PopupComponent from '../../../../../../libs/components/popup-component';
import { register } from '../../../../../../libs/register';
import { flowManager } from '../../../../../../libs/flow-manager';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import { apiProvider } from '../../../../../../libs/api-provider';


class EmailChangeProceed extends PopupComponent {
    constructor(name, root) {
        super(name, root);

        this.action = this._dEl("ctaAction");
        this._init();
    }

    _init() {
        this.action.addEventListener("click", (e) => {
            e.preventDefault();
            this._changeMail();
        });
    }

    async _changeMail() {
        try {
            openLoader('main');
            const data = flowManager.getDataFromFlow();
            const formData = {
                ...data.formData,
                updateGccMail: data.updateGccMail == undefined ? null : data.updateGccMail
            }
            await apiProvider.changeEmailRequest(formData);
            flowManager.appendDataToFlow({ ok: true })
            flowManager.next("check-email");
        } catch (error) {
            console.warn(error);
            flowManager.appendDataToFlow({ errorMessage: error?.errore })
            flowManager.next("generic-error");
        } finally {
            closeLoader('main');
        }
    }

    /* override */
    async _onCancel() {
        flowManager.complete();
    }
}

register.registerClass('.mp19-email-change-proceed', EmailChangeProceed);
