import WizardComponent from '../../../../../../libs/components/wizard-component';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { formToJSON, jsonToForm } from '../../../../../../libs/form-to-json';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { emptyElement } from '../../../../../../libs/utils';
import { Store } from '../../../templates/mt3-store/script';
import { apiProvider } from '../../../../../../libs/api-provider';

class RegisterLight extends WizardComponent {
    constructor(name, root) {
        super(name, root);

        this.id = this.root.getAttribute('id');
        this.error = this._dEl('error');
        this.errorContent = this._dEl('error-content');
        if (this.error.dataset.recoverPwd) this.recoverPwd = JSON.parse(this.error.dataset.recoverPwd);
        this.backButton = this._dEl('backButton');
        this.userDataWrapper = this._dEl('userDataWrapper');
        this.userDataForm = this._dEl('userDataForm');
        this.userDataRecap = this._dEl('userDataRecapWrapper');
        this.userRecapEmail = this._dEl('recapEmail');
        this.userRecapPassword = this._dEl('recapPassword');
        this.userRecapPasswordCnt = this._dEl('recapPasswordCnt');
        this.userDataModify = this._dEl('editPersonalData');

        this.email = this._dEl('email');
        this.emailObj = register.getClass(this.email);
        this.emailRepeat = this._dEl('emailConfirm');
        this.emailRepeatObj = register.getClass(this.emailRepeat);
        this.emailMismatch = this._dEl('emailMismatch');

        this.password = this._dEl('password');
        this.passwObj = register.getClass(this.password);
        this.passwordRepeat = this._dEl('confirmPassword');
        this.passwRepeatObj = register.getClass(this.passwordRepeat);
        this.passwordRules = this._dEl('passwordRules');
        this.passwordState = this._dEl('passwordState');
        this.passwordStateInfo = this.passwordState.querySelector('.info');
        this.passwordStateOk = this.passwordState.querySelector('.ok');
        this.passwordStateError = this.passwordState.querySelector('.error');
        this.passwordMismatch = this._dEl('passwordMismatch');

        this.next = this._dEl('nextButton');
        this.userDataForm = this._dEl('userDataForm');

        this.refPdvButton = this._dEl('referencePdvButton');
        this.pdvSelect = this._dEl('pdvSelect');
        this.referencePdvTitle = this._dEl('referencePdvTitle');
        this.referencePdvText = this._dEl('referencePdvText');
        this.changePdvButton = this._dEl('changePdvButton');
        this.cardRequestWrp = this._dEl('cardRequestWrapper');
        this.cardRequestTxt = this._dEl('cardRequestTxt');

        this.storeWrapper = this._dEl('storeWrapper');
        this.storeWrapperTitle = this._dEl('storeWrapper-title');
        this.storeWrapperAddress = this._dEl('storeWrapper-address');
        this.storeWrapperDistance = this._dEl('storeWrapper-distance');
        this.storeNotEnabledWrapper = this._dEl('storeNotEnabledWrapper');

        this.boxRequestButton = this._dElMod('boxButton', 'request');
        this.requestCardLabel = this._dEl('requestCardLabel');
        this.boxContinueButton = this._dElMod('boxButton', 'continue');
        this.continueButton = this._dEl('continueButton');
        this.changePdvButton = this._dEl('changePdvButton');
        this.userEmptyObj = {};

        this.userDataInputs = Array.from(this.userDataForm.querySelectorAll('[data-is-validable]'));

        this._initTextsWithEditableLinks();
        this._addEventListeners();
        this._addStoreListeners();
    }

    /* override */
    open() {
        super.open();
        this._loadUserInfo();
        this._trackRegistrationFunnel(TRACKABLE_EVENT.pageview, FUNNEL_STEP.userAndPswChoice, this.userInfo);

        //check if anacanId is provided in query string
        const urlSearchParams = new URLSearchParams(window.location.search);
        if (!this.originEcommerce && urlSearchParams.has('pdv')) {
            (async () => {
                try {
                    const selectedPdv = await apiProvider.getPointOfServiceByAnacanId({
                        anacanId: urlSearchParams.get('pdv'),
                    });
                    storeManager.emit('selectedPdv', selectedPdv);
                } catch (error) {
                    console.error(error);
                }
            })();
        }
        this._setValidationContexts();
    }

    _addEventListeners() {
        this.email.addEventListener('ecInputChanged', () => {
            if (!this.emailObj?.isValid()) {
                this._disableSubmit();
            }
            if (this.emailObj?.getValue() !== '' && this.emailRepeatObj?.getValue() !== '') {
                this._checkEmailRepeat();
            }
        });
        this.emailRepeat.addEventListener('ecInputChanged', () => {
            this._checkEmailRepeat();
        });
        this.password.addEventListener('click', () => {
            this.passwordRules.classList.remove(this._elMod('passwordRules', 'hidden'));
        });
        this.password.addEventListener('ecInputChanged', () => {
            this._checkShowPasswordRules();
            if (!this.passwObj?.isValid()) {
                this._disableSubmit();
            }
            if (this.passwObj?.getValue() !== '' && this.passwRepeatObj?.getValue() !== '') {
                this._checkPasswordRepeat();
            }
        });
        this.passwordRepeat.addEventListener('ecInputChanged', () => {
            this._checkPasswordRepeat();
        });
        this.refPdvButton.addEventListener('click', () => {
            flowManager.startFlow({
                flowName: 'select-pdv-extended',
                flowSteps: [{ name: 'select-pdv-extended' }],
            });
        });
        this.next.addEventListener('click', () => {
            if (this._checkUserDataInputsValid()) {
                this._checkUserIfRegistered();
            }
        });
        this.userDataModify.addEventListener('click', () => {
            this.userDataRecap.classList?.add(this._elMod('userDataRecapWrapper', 'hidden'));
            this.userDataForm.classList?.remove(this._elMod('userDataForm', 'hidden'));
            this.userDataWrapper.classList?.remove(this._elMod('userDataWrapper', 'showRecap'));
            this.referencePdvText.classList?.add(this._elMod('referencePdvText', 'hidden'));
            this.changePdvButton.classList?.add(this._elMod('changePdvButton', 'hidden'));
            this.refPdvButton.classList?.add(this._elMod('referencePdvButton', 'hidden'));
            this.next.classList?.remove(this._elMod('nextButton', 'hidden'));
            setTimeout(() => {
                this.storeWrapper.classList.remove(this._elMod('storeWrapper', 'show'));
                this.changePdvButton.classList.remove(this._elMod('changePdvButton', 'show'));
                if (this.cardRequestWrp.classList.contains(this._elMod('cardRequestWrapper', 'show'))) {
                    this.cardRequestWrp.classList.remove(this._elMod('cardRequestWrapper', 'show'));
                } else if (this.continueButton.classList.contains(this._elMod('continueButton', 'show'))) {
                    this.continueButton.classList.remove(this._elMod('continueButton', 'show'));
                }
                this.pdvSelect.classList?.add(this._elMod('pdvSelect', 'disabled'));
                this.referencePdvTitle.classList?.add(this._elMod('referencePdvTitle', 'disabled'));
                this.pdvSelect.classList?.remove(this._elMod('pdvSelect', 'withBackground'));
            }, 500);
        });
        this.boxContinueButton.addEventListener('click', () => {
            if (this._checkUserDataInputsValid()) {
                this._saveData();
                /* invalidate request card data */
                storeManager.emit('requestCardData', { invalid: true }, true);
                flowManager.next('privacy');
            } else {
                const first = this.userDataForm.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
            }
        });
        this.continueButton.addEventListener('click', () => {
            if (this._checkUserDataInputsValid()) {
                this._saveData();
                flowManager.next('privacy');
            } else {
                const first = this.userDataForm.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
            }
        });
        this.boxRequestButton.addEventListener('click', () => {
            if (this._checkUserDataInputsValid()) {
                this._saveData();
                this._trackRegistrationFunnel(
                    TRACKABLE_EVENT.requestCardCta,
                    FUNNEL_STEP.requestInsiemeCardCta,
                    this.userInfo
                );
                flowManager.startFlow({
                    flowName: 'request-card',
                    flowSteps: [
                        { name: 'request-card-step1', stepIndex: 1, disableBack: true },
                        { name: 'request-card-step2', stepIndex: 2, disableBack: true },
                        { name: 'card-added', disableBack: true },
                    ],
                    options: {
                        requestCardModality: 'registration',
                    },
                });
            } else {
                const first = this.userDataForm.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
            }
        });
        this.changePdvButton.addEventListener('click', () => {
            flowManager.startFlow({
                flowName: 'select-pdv-extended',
                flowSteps: [{ name: 'select-pdv-extended' }],
            });
        });

        /* form errors */
        this.userDataForm.addEventListener('ecFormError', (event) => {
            event.preventDefault();
            if (!event.data.errorField) return;
            trackEvent(
                TRACKABLE_EVENT.formError,
                FUNNEL_NAME.registration,
                FUNNEL_STEP.userAndPswChoice,
                null,
                null,
                this.userInfo,
                {
                    errorField: event.data.errorField,
                    errorText: event.data.errorText,
                }
            );
            event.stopPropagation();
        });
        this.backButton.addEventListener('click', () => {
            this.userDataModify.click();
            this._disableSubmit();
            storeManager.emit('userData', this.userEmptyObj);
            this.passwObj.reset();
            this.passwRepeatObj.reset();
            this.passwordRules.classList.add(this._elMod('passwordRules', 'hidden'));
            this.passwordStateInfo.classList.add('active');
            this.passwordStateOk.classList.remove('active');
            this.passwordStateError.classList.remove('active');
        });
    }

    async _checkUserIfRegistered() {
        if (!this.next.classList.contains(this._elMod('nextButton', 'disabled'))) {
            var checkUser = null;
            try {
                checkUser = await apiProvider.checkUserIfRegistered({
                    email: this.emailObj.getValue(),
                });
            } catch (error) {
                checkUser = error.codice;
                console.warn('Error during check user');
            }

            if (checkUser == 'OK') {
                this.error.classList.add(this._elMod('error', 'hidden'));
                this.userRecapEmail.innerHTML = this.emailObj.input.value;
                var passwordHidden = '';
                for (var ct = 0; ct < this.passwObj.input.value.length; ct++) {
                    passwordHidden = passwordHidden + '*';
                }
                this.userRecapPassword.innerHTML = passwordHidden;
                this.pdvSelect.classList?.remove(this._elMod('pdvSelect', 'disabled'));
                this.referencePdvTitle.classList?.remove(this._elMod('referencePdvTitle', 'disabled'));
                this.referencePdvText.classList?.remove(this._elMod('referencePdvText', 'hidden'));
                this.changePdvButton.classList?.remove(this._elMod('changePdvButton', 'hidden'));
                this.refPdvButton.classList?.remove(this._elMod('referencePdvButton', 'hidden'));
                this.pdvSelect.classList?.add(this._elMod('pdvSelect', 'withBackground'));
                this.userDataWrapper.classList?.add(this._elMod('userDataWrapper', 'showRecap'));
                this.userDataForm.classList?.add(this._elMod('userDataForm', 'hidden'));
                this.next.classList?.add(this._elMod('nextButton', 'hidden'));
                setTimeout(() => {
                    this.userDataRecap.classList?.remove(this._elMod('userDataRecapWrapper', 'hidden'));
                }, 500);
            } else {
                if (checkUser == 'BACKEND:RESPONSE_ERROR') {
                    this.errorContent.innerHTML = this.root.dataset.genericError;
                } else {
                    const errorInfo = `<a href='${this.recoverPwd.href}'>${this.recoverPwd.label}</a> ${dictionary.get(
                        'or register with different email address'
                    )}`;
                    this.errorContent.innerHTML = `${await (dictionary.getFEMessage(
                        'IS_ERR:INDIRIZZO_EMAIL_GIA_REGISTRATO'
                    ) || 'Indirizzo email già registrato su Conad.it')}. ${errorInfo}`;
                }
                this.error.classList.remove(this._elMod('error', 'hidden'));
                window.scrollTo(0, 0);
            }
        }
    }

    _loadUserInfo() {
        const ecRegistrationData = storeManager.get('ecRegistrationData');
        const pointOfServiceId = ecRegistrationData?.pointOfServiceId;
        const cooperativeId = ecRegistrationData?.cooperativeId;
        const selectedPdv = storeManager.get('selectedPdv');

        //init default user info
        this.userInfo = {
            coop: '',
            store: '',
        };
        if (pointOfServiceId && cooperativeId) {
            /* case pdv selected on ecommerce (registration flow from ecommerce) */
            this.userInfo = {
                coop: cooperativeId,
                store: pointOfServiceId,
            };
            /* set footer privacy link */
            storeManager.emit('ms3PrivacyLink', { coop: cooperativeId, ecommerce: true });
        } else if (selectedPdv) {
            /* case pdv selected during registration flow */
            this.userInfo = {
                coop: selectedPdv.codiceCooperativa,
                store: selectedPdv.anacanId,
            };
            /* set footer privacy link */
            storeManager.emit('ms3PrivacyLink', {
                coop: selectedPdv.codiceCooperativa,
                ecommerce: ecRegistrationData?.originEcommerce || false,
            });
        }
    }

    _addStoreListeners() {
        storeManager.on('registration', async (path, data) => {
            jsonToForm(this.userDataForm, data);
        });
        storeManager.on('selectedPdv', async (path, data) => {
            this._addPdv(data);
            this._checkPdv(data);
            this._loadUserInfo();
        });
        storeManager.on('userData', async (path, data) => {
            if (data.socialRegistration) {
                jsonToForm(this.userDataForm, data);

                /* lock email field */
                if (!this.email.classList.contains(this._elMod('email', 'locked')))
                    this.email.classList.add(this._elMod('email', 'locked'));
                this.email.querySelector('input').setAttribute('tabindex', '-1');

                // hide repeate mail
                if (!this.emailRepeat.classList.contains(this._elMod('emailConfirm', 'hidden')))
                    this.emailRepeat.classList.add(this._elMod('emailConfirm', 'hidden'));
                this.emailRepeat.querySelector('input').required = false;

                /* unset required password and hide it */
                if (!this.password.classList.contains(this._elMod('password', 'hidden')))
                    this.password.classList.add(this._elMod('password', 'hidden'));
                this.password.querySelector('input').required = false;

                if (!this.passwordRepeat.classList.contains(this._elMod('confirmPassword', 'hidden')))
                    this.passwordRepeat.classList.add(this._elMod('confirmPassword', 'hidden'));
                this.passwordRepeat.querySelector('input').required = false;

                // hide password recapt
                this.userRecapPasswordCnt.style.display = "none";

                this._enableSubmit();
            } else {
                jsonToForm(this.userDataForm, data);
            }
        });
        storeManager.on('noCardUserData', async (path, data) => {
            this.userEmptyObj = data;
        });
        storeManager.on('setMw3RegisterLight', async (path, data) => {
            this.requestCardLabel.innerText = data.modifyCard
                ? this.requestCardLabel.dataset.modify
                : this.requestCardLabel.dataset.request;
            if (data.showContinue) {
                if (!this.continueButton.classList.contains(this._elMod('continueButton', 'show')))
                    this.continueButton.classList.add(this._elMod('continueButton', 'show'));
            } else {
                this.continueButton.classList.remove(this._elMod('continueButton', 'show'));
            }
        });
    }

    _checkUserDataInputsValid() {
        if (!this.userDataForm.checkValidity()) {
            //this.userDataForm.reportValidity();
            return false;
        }
        //this._saveData();
        return true;
    }

    async _checkPdv(data) {
        try {
            if (this.userDataWrapper.classList.contains(this._elMod('userDataWrapper', 'showRecap'))) {
                if (data.cartaDematerializzata) {
                    this.cardRequestWrp.classList.add(this._elMod('cardRequestWrapper', 'show'));
                    this.continueButton.classList.remove(this._elMod('continueButton', 'show'));
                } else {
                    this.cardRequestWrp.classList.remove(this._elMod('cardRequestWrapper', 'show'));
                    this.continueButton.classList.add(this._elMod('continueButton', 'show'));
                }
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    async _addPdv(st) {
        emptyElement(this.storeWrapperTitle);
        emptyElement(this.storeWrapperAddress);
        emptyElement(this.storeWrapperDistance);
        const data = Store.getStoreData(this.id + '-' + Math.floor(Math.random() * 100000), `${this.name}__store`, st);
        this.storeWrapperTitle.innerHTML = data.title;
        this.storeWrapperAddress.innerHTML = data.address;
        this.storeWrapperDistance.innerHTML = data.distance;

        if (
            !data.cartaDematerializzata &&
            this.storeNotEnabledWrapper.classList.contains(this._elMod('storeNotEnabledWrapper', 'hidden'))
        ) {
            this.storeNotEnabledWrapper.classList.remove(this._elMod('storeNotEnabledWrapper', 'hidden'));
        } else if (
            data.cartaDematerializzata &&
            !this.storeNotEnabledWrapper.classList.contains(this._elMod('storeNotEnabledWrapper', 'hidden'))
        ) {
            this.storeNotEnabledWrapper.classList.add(this._elMod('storeNotEnabledWrapper', 'hidden'));
        }
        if (this.userDataWrapper.classList.contains(this._elMod('userDataWrapper', 'showRecap'))) {
            this.refPdvButton.classList.add(this._elMod('referencePdvButton', 'hidden'));
            this.storeWrapper.classList.add(this._elMod('storeWrapper', 'show'));
            this.changePdvButton.classList.add(this._elMod('changePdvButton', 'show'));
        }
    }

    _saveData() {
        storeManager.emit('userData', formToJSON(this.userDataForm), true);
    }

    _checkShowPasswordRules() {
        if (this.passwObj.getValue()) {
            this.passwordRules.classList.remove(this._elMod('passwordRules', 'hidden'));
        } else {
            this.passwordRules.classList.add(this._elMod('passwordRules', 'hidden'));
        }
        if (this.passwObj.isValid()) {
            this.passwordStateInfo.classList.remove('active');
            this.passwordStateOk.classList.add('active');
            this.passwordStateError.classList.remove('active');
        } else {
            this.passwordStateInfo.classList.remove('active');
            this.passwordStateOk.classList.remove('active');
            this.passwordStateError.classList.add('active');
        }
    }

    _initTextsWithEditableLinks() {
        const cardRequest = JSON.parse(this.cardRequestTxt.dataset.cardRequest);
        this.cardRequestTxt.innerHTML = this.cardRequestTxt.innerHTML.replace(
            '{{card-request}}',
            `<a href="${cardRequest.href}">${cardRequest.label}</a>`
        );
        this.cardRequestTxt.removeAttribute('data-card-request');
    }

    _setValidationContexts() {
        this.passwRepeatObj.setValidationContext({
            pswObj: this.passwObj,
        });
        this.emailRepeatObj.setValidationContext({
            emailObj: this.emailObj,
        });
    }

    _checkPasswordRepeat() {
        if (!this.password || !this.passwordRules || !this.passwordState) return;
        const passwordRepeatError = this.passwordRepeat?.querySelector('.mt8-textfield__error');
        if (
            this.passwObj?.getValue() !== '' &&
            this.passwRepeatObj?.getValue() !== '' &&
            this.passwRepeatObj?.getValue() !== this.passwObj.getValue()
        ) {
            this.passwordMismatch?.classList.remove(this._elMod('passwordMismatch', 'hidden'));
            this.passwRepeatObj?.setState('error');
            if (passwordRepeatError) passwordRepeatError.style.display = 'none';
            this._disableSubmit();
        } else {
            passwordRepeatError?.removeAttribute('style');
            if (!this.passwordMismatch?.classList.contains(this._elMod('passwordMismatch', 'hidden')))
                this.passwordMismatch?.classList.add(this._elMod('passwordMismatch', 'hidden'));
            if (
                this.passwRepeatObj?.getValue() !== '' &&
                this.passwRepeatObj?.getValue() === this.passwObj?.getValue()
            ) {
                this.passwRepeatObj?.setState('valid');

                if(this._lastCheckEmailAndPasswordFieldsPopulation()) {
                    this._enableSubmit();
                }
            }
        }
    }

    _checkEmailRepeat() {
        if (!this.email) return;

        const emailRepeatError = this.emailRepeat?.querySelector('.mt8-textfield__error');

        if (
            this.emailObj?.getValue() !== '' &&
            this.emailRepeatObj?.getValue() !== '' &&
            this.emailRepeatObj?.getValue() !== this.emailObj.getValue()
        ) {
            this.emailMismatch?.classList.remove(this._elMod('emailMismatch', 'hidden'));
            this.emailRepeatObj?.setState('error');
            if (emailRepeatError) emailRepeatError.style.display = 'none';
            this._disableSubmit();
        } else {
            if (!this.emailMismatch?.classList.contains(this._elMod('emailMismatch', 'hidden')))
                this.emailMismatch?.classList.add(this._elMod('emailMismatch', 'hidden'));
            if (
                this.emailRepeatObj?.getValue() !== '' &&
                this.emailRepeatObj?.getValue() === this.emailObj?.getValue()
            ) {
                this.emailRepeatObj?.setState('valid');

                if(this._lastCheckEmailAndPasswordFieldsPopulation()) {
                    this._enableSubmit();
                }
            }
        }
    }

    _lastCheckEmailAndPasswordFieldsPopulation() {
        if (
            this.emailRepeatObj?.getValue() !== '' &&
            this.emailRepeatObj?.getValue() === this.emailObj?.getValue() &&
            this.passwRepeatObj?.getValue() !== '' &&
            this.passwRepeatObj?.getValue() === this.passwObj?.getValue()
        ) {
            return true;
        }

        return false;
    }

    _enableSubmit() {
        this.next?.classList?.remove(this._elMod('nextButton', 'disabled'));
    }

    _disableSubmit() {
        this.next?.classList?.add(this._elMod('nextButton', 'disabled'));
    }

    _trackRegistrationFunnel(event, funnelStep, userInfo = null, formDataObj = null) {
        const typeOfService = storeManager.get('ecRegistrationData')?.typeOfService;
        const originEcommerce = storeManager.get('ecRegistrationData')?.originEcommerce;
        const formData = formDataObj && formDataObj.errorFields ? { errorFields: formDataObj.errorFields } : null;
        if (storeManager.get('currentFlow').includes('conad-register')) {
            if (originEcommerce && typeOfService) {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, typeOfService, null, userInfo, formData);
            } else {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, null, null, userInfo, formData);
            }
        }
    }
}

register.registerClass('.mw3-register-light', RegisterLight);
