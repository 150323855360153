import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';

class Tooltip extends Component {
    constructor(name, root) {
        super(name, root);
        this.icon = this._dEl('icon');
        this._appendPopup();
        this._addEventListeners();
    }

    _appendPopup() {
        this.popup = document.createElement('div');
        this.popup.classList.add('mt20-tooltip-popup');
        this.popup.setAttribute('id', `${this.root.id}-popup`);
        this.popup.setAttribute('role', 'tooltip');
        this.popup.innerHTML = this.root.dataset.tooltipText;
        this.popup.style.top = '0px';
        document.body.appendChild(this.popup);
    }

    _addEventListeners() {
        this.icon.addEventListener('mouseover', (event) => {
            event.preventDefault();
            this._openTooltip();
        });
        this.icon.addEventListener('mouseout', (event) => {
            event.preventDefault();
            this._closeTooltip();
        });
        this.icon.addEventListener('focusin', (event) => {
            event.preventDefault();
            this._openTooltip();
        }, true);
        this.icon.addEventListener('focusout', (event) => {
            event.preventDefault();
            this._closeTooltip();
        }, true);
        this.icon.addEventListener('keydown', (event) => {
            if (event.key !== 'Escape') return;
            this._closeTooltip();
        });
    }

    _openTooltip() {
        if (!this.root.classList.contains(this._mod('show'))) {
            this.root.classList.add(this._mod('show'));
            this._setTooltipPosition();
            this.popup.classList.add('mt20-tooltip-popup--show');
            this.popup.focus();
        }
    }

    _closeTooltip() {
        this.root.classList.remove(this._mod('show'));
        this.popup.classList.remove('mt20-tooltip-popup--show');
    }

    _setTooltipPosition() {
        this.popup.style.top = this._calcTop();
        this.popup.style.left = this._calcLeft();
    }

    _calcTop() {
        const rect = this.root.getBoundingClientRect();
        const popHeight = this.popup.clientHeight;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        let top = rect.top + scrollTop + rect.height + 8;
        if (top + popHeight > document.documentElement.scrollHeight) {
            const diff = top + popHeight - document.documentElement.scrollHeight;
            top = top - diff - 8;
            top = top >= 0 ? top : 0;
        }
        return top + 'px';
    }

    _calcLeft() {
        const rect = this.root.getBoundingClientRect();
        const popWidth = this.popup.clientWidth;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        let left = rect.left + scrollLeft;
        if (left + popWidth > document.documentElement.scrollWidth) {
            const diff = left + popWidth - document.documentElement.scrollWidth;
            left = left - diff - 8;
            left = left >= 0 ? left : 0;
        }
        return left + 'px';
    }
}

register.registerClass('.mt20-tooltip', Tooltip);
