import PopupComponent from '../../../../../../libs/components/popup-component';
import { register } from '../../../../../../libs/register';
import { flowManager } from '../../../../../../libs/flow-manager';


class SaveProfile extends PopupComponent {
    constructor(name, root) {
        super(name, root);

        this.action = this._dEl("ctaAction");
        this._init();
    }

    _init() {
        this.action.addEventListener("click", () => {
            flowManager.appendDataToFlow({ ok: true })
            flowManager.complete();
        });
    }

    /* override */
    async _onCancel() {
        flowManager.backOrComplete();
    }
}

register.registerClass('.mp21-save-profile', SaveProfile);
