/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  (function _template_src_main_webpack_repository_apps_conad_myconad_templates_mt26_basic_popup_mt26_basic_popup_html(){ 
    let render;
    render = function* _template_src_main_webpack_repository_apps_conad_myconad_templates_mt26_basic_popup_mt26_basic_popup_html_render(args) { 
      let uniqueid = args[1]['uniqueId'] || '';
      let extraclasses = args[1]['extraClasses'] || '';
      let title = args[1]['title'] || '';
      let text = args[1]['text'] || '';
      let closelabel = args[1]['closeLabel'] || '';
      let cancellabel = args[1]['cancelLabel'] || '';
      let $t, $n = args[0];
      $.dom.text($n,"\n    ");
      $t = $.dom.create("div",false,false);
      const var_attrValue0 = uniqueid;
      if (!$.col.empty(var_attrValue0)) {
        $.dom.attr($t, 'id', var_attrValue0, 'attribute');
      }
      const var_attrValue1 = ("mt26-basic-popup ") + (extraclasses);
      if (!$.col.empty(var_attrValue1)) {
        $.dom.attr($t, 'class', var_attrValue1, 'attribute');
      }
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n        ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mt26-basic-popup__content", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n            ");
      $t = $.dom.create("button",false,false);
      $.dom.attr($t, 'class', "mt26-basic-popup__close ec-icon-close", 'attribute');
      $.dom.attr($t, 'type', "button", 'attribute');
      const var_attrValue2 = "Close popup";
      if (!$.col.empty(var_attrValue2)) {
        $.dom.attr($t, 'aria-label', var_attrValue2, 'attribute');
      }
      $.dom.attr($t, 'data-close-popup', null, 'attribute');
      $n = $.dom.push($n,$t);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      $t = $.dom.create("h3",false,false);
      $.dom.attr($t, 'class', "mt26-basic-popup__title", 'attribute');
      $n = $.dom.push($n,$t);
      const var_3 = yield $.xss(title, "html");
      $.dom.append($n, var_3);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mt26-basic-popup__text", 'attribute');
      $n = $.dom.push($n,$t);
      const var_4 = yield $.xss(text, "html");
      $.dom.append($n, var_4);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      $t = $.dom.create("div",false,false);
      $.dom.attr($t, 'class', "mt26-basic-popup__ctas", 'attribute');
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n                ");
      $t = $.dom.create("button",false,false);
      $.dom.attr($t, 'class', "mt26-basic-popup__ctaAction", 'attribute');
      $.dom.attr($t, 'type', "button", 'attribute');
      $n = $.dom.push($n,$t);
      const var_5 = yield $.xss(closelabel, "html");
      $.dom.append($n, var_5);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n                ");
      const var_testVariable6 = cancellabel;
      if (var_testVariable6) {
        $t = $.dom.create("button",false,false);
        $.dom.attr($t, 'class', "mt26-basic-popup__ctaCancel", 'attribute');
        $.dom.attr($t, 'type', "button", 'attribute');
        $.dom.attr($t, 'data-close-popup', null, 'attribute');
        $n = $.dom.push($n,$t);
        const var_7 = yield $.xss(cancellabel, "html");
        $.dom.append($n, var_7);
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n            ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n        ");
      $n = $.dom.pop($n);
      $.dom.text($n," \n    ");
      $n = $.dom.pop($n);
      $.dom.text($n,"\n");
    };
    $.template('src/main/webpack/repository/apps/conad-myconad/templates/mt26-basic-popup/mt26-basic-popup.html', 'render', render);
  })();

  return $.run(function* () {
    let $t, $n = $.dom.start();
    $.dom.text($n,"\n");
    let store = $.template('src/main/webpack/repository/apps/conad-myconad/templates/mt26-basic-popup/mt26-basic-popup.html');
    $.dom.text($n,"\n");
    yield $.call(store["render"], [$n, {"uniqueId": data["uniqueId"], "extraClasses": data["extraClasses"], "title": data["title"], "text": data["text"], "closeLabel": data["closeLabel"], "cancelLabel": data["cancelLabel"], }]);
    $.dom.text($n,"\n");
    return $.dom.end();

  });
};
