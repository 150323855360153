import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';

class ProductGiftAccordionCard extends Component {
    constructor(name, root) {
        super(name, root);
        this._addEventListeners();
    }

    _addEventListeners() {
    
    }
}

register.registerClass('.mt39-product-gift-accordion-card', ProductGiftAccordionCard);
