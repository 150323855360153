import Component from '../../../../../../libs/components/component';
import { register } from '../../../../../../libs/register';

class AlertBox extends Component {
    constructor(name, root) {
        super(name, root);
        this._addEventListeners();
    }

    _addEventListeners() {}
}

register.registerClass('.mc4-alert-box', AlertBox);
