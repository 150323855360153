import { apiProvider } from '../../../../../../libs/api-provider';
import ModalComponent from '../../../../../../libs/components/modal-component';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { formToJSON, jsonToForm } from '../../../../../../libs/form-to-json';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';

class RequestCardStep1 extends ModalComponent {
    constructor(name, root) {
        super(name, root);

        this.form = this._dEl('form');
        this.submit = this._dEl('submit');
        
        this.provinciaNascita = this._dEl('provinciaNascita');
        this.provinciaResidenza = this._dEl('provinciaResidenza');
        this.nazioneResidenza = this._dEl('nazioneResidenza');
        this.comuneNascita = this._dEl('comuneNascita');
        this.cittaResidenza = this._dEl('cittaResidenza');
        this.indirizzoResidenza = this._dEl('indirizzoResidenza');
        this.nCivicoResidenza = this._dEl('nCivico');
        this.error = this._dEl('error');
        this.cfSection = this._dEl('cfSection');
        this.cfObj = register.getClass(this._dEl('cf'));
        this.cfCalc = this._dEl('cfCalc');
        this.cfObj.getInput().required = false;

        this.alreadyOpened = false;

        //this._checkFormValid();
        this._addEventListeners();
        this._addStoreListeners();
    }

    /* override */
    async _onOpen() {
        await super._onOpen();
        if (!this.userData) this.userData = storeManager.get('userData');
        const prepData = {
            dataNascita: this.userData?.dataNascita,
            genere: this.userData?.sesso,
            cellulare: this.userData?.telefonoCellulare ? this.userData.telefonoCellulare : '',
        };
        jsonToForm(this.form, prepData);
        //this._checkFormValid();

        if (!this.alreadyOpened) {
            const provinciaNascitaSelect = register.getClass(this.provinciaNascita);
            const provinciaResidenzaSelect = register.getClass(this.provinciaResidenza);
            const nazioneResidenzaSelect = register.getClass(this.nazioneResidenza);
            try {
                const province = await apiProvider.province();
                provinciaNascitaSelect.setItems(province);
                provinciaResidenzaSelect.setItems(province);
            } catch (error) {
                console.error('Cannot set province');
            }

            try {
                const nations = await apiProvider.stati();
                nazioneResidenzaSelect.setItems(nations);
            } catch (error) {
                console.error('Cannot set nations');
            }
        }
        this.alreadyOpened = true;

        const ecRegistrationData = storeManager.get('ecRegistrationData');
        const pointOfServiceId = ecRegistrationData?.pointOfServiceId;
        const cooperativeId = ecRegistrationData?.cooperativeId;
        const selectedPdv = storeManager.get('selectedPdv');
        this.userInfo = null;
        if (pointOfServiceId && cooperativeId) {
            /* case pdv selected on ecommerce (registration flow from ecommerce) */
            this.userInfo = {
                coop: pointOfServiceId,
                store: cooperativeId
            };
        } else if (selectedPdv) {
            /* case pdv selected during registration flow */
            this.userInfo = {
                coop: selectedPdv.codiceCooperativa,
                store: selectedPdv.anacanId
            };
        }
        if (storeManager.get('currentFlow').includes('conad-register')) {
            this._trackRegistrationFunnel(
                TRACKABLE_EVENT.pageview, 
                FUNNEL_STEP.requestInsiemeCardForm, 
                this.userInfo
            );
        }
    }

    _addEventListeners() {
        /*this.root.addEventListener('ecInputChanged', () => {
            this._checkFormValid();
        });*/
        this.form.addEventListener('submit', (event) => {
            event.preventDefault();
        });
        this.submit.addEventListener('click', async (event) => {
            event.preventDefault();
            /*if (this.submit.classList.contains('invalid')) {
                return;
            }*/
            if (!this.form.checkValidity()) {
                const first = this.form.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }

            let result = false;
            openLoader('main');
            result = await this._onlyValidation();
            closeLoader('main');

            if (!result) {
                let errorText = '';
                if (this.ISerror?.codice && 
                    (this.ISerror.codice == 'IS_ERR:DATI_INCONGRUENTI' ||
                    this.ISerror.codice == 'IS_ERR:RICHIESTA_NON_VALIDA')) {
                    errorText = `${dictionary.getFEMessage('invalidDmCardData')}`;
                } else {
                    errorText = this.root.dataset.genericError;
                }
                this.error.innerHTML = errorText;
                trackEvent(
                    TRACKABLE_EVENT.formError, 
                    FUNNEL_NAME.insiemeCard, 
                    FUNNEL_STEP.requestInsiemeCardForm, 
                    null, null, this.userInfo, 
                    { 
                        errorField: dictionary.getFEMessage('formSubmitError'),
                        errorText: dictionary.getFEMessage('genericError'),
                    }
                );
                this.error.classList.remove(this._elMod('error', 'hidden'));
            }
        });

        this.indirizzoResidenza.addEventListener('focusout', () => {
            var tempResidenzaClass = register.getClass(this.indirizzoResidenza);
            tempResidenzaClass.setValue(tempResidenzaClass.getValue().trim());
            tempResidenzaClass._checkState();
            tempResidenzaClass._changedInput();
        });

        this.nCivicoResidenza.addEventListener('focusout', () => {
            var tempNcivicoClass = register.getClass(this.nCivicoResidenza);
            tempNcivicoClass.setValue(tempNcivicoClass.getValue().trim());
            tempNcivicoClass._checkState();
            tempNcivicoClass._changedInput();
        });

        this.provinciaNascita.addEventListener('ecInputChanged', async (event) => {
            const value = event.data.value;
            await this._loadComuni(value, register.getClass(this.comuneNascita), true);
            //this._checkFormValid();
        });
        this.provinciaResidenza.addEventListener('ecInputChanged', async (event) => {
            const value = event.data.value;
            await this._loadComuni(value, register.getClass(this.cittaResidenza), false);
            //this._checkFormValid();
        });

        this.cfCalc.addEventListener('click', (event) => {
            event.preventDefault();
            const userData = { ...storeManager.get('userData'), ...formToJSON(this.form) };
            const data = userData.dataNascita.split('-');

            (async () => {
                const cf = (await import("codice-fiscale-js")).default.compute({
                    name: userData.nome,
                    surname: userData.cognome,
                    gender: userData.genere,
                    day: data[0],
                    month: data[1],
                    year: data[2],
                    birthplace: userData.comuneNascitaLabel,
                    birthplaceProvincia: userData.provinciaNascita,
                });
                this.cfObj.setValue(cf);
            })();
            
        });

        /* form errors */
        this.form.addEventListener('ecFormError', (event) => {
            event.preventDefault();
            if (!event.data.errorField) return;
            trackEvent(
                TRACKABLE_EVENT.formError, 
                FUNNEL_NAME.insiemeCard, 
                FUNNEL_STEP.requestInsiemeCardForm, 
                null, null, this.userInfo, 
                { 
                    errorField: event.data.errorField,
                    errorText: event.data.errorText,
                }
            );
            event.stopPropagation();
        });
    }

    _addStoreListeners() {
        storeManager.on('selectedPdv', async (path, data) => {
            if (data.codiceCooperativa == 'PAC') {
                this.cfSection.classList.add(this._elMod('cfSection', 'show'));
                this.cfObj.getInput().required = true;
            } else {
                this.cfSection.classList.remove(this._elMod('cfSection', 'show'));
                this.cfObj.getInput().required = false;
            }
        });
    }

    _resetFormInputs() {
        if (!this.form) return;
        [
            'mt8-textfield',
            'mt16-select',
            'mt23-datepicker',
        ].forEach((el) => {
            this.form.querySelectorAll(`.${el}`).forEach((field) => {
                register.getClass(field).reset();
            });
        });
    }

    /*async _checkFormValid() {
        if (!this.form.checkValidity()) {
            this.submit.classList.add('invalid');
            this.submit.setAttribute('title', dictionary.getFEMessage('fillInFields'));
        } else {
            this.submit.classList.remove('invalid');
            this.submit.removeAttribute('title');
        }
    }*/

    async _onlyValidation() {
        let result = false;
        try {
            const selectedPdv = storeManager.get('selectedPdv');
            const requestCardData = {
                ...formToJSON(this.form),
            };
            const userData = {
                ...storeManager.get('userData'),
                dmCard: true,
            };
            const data = { 
                ...userData,
                ...requestCardData,
                pdvAnacanId: selectedPdv.anacanId
            };
            result = await apiProvider.isDmValid(data);
            if (result) {
                /* save request card data separately from user data */
                storeManager.emit('requestCardData', requestCardData);
                storeManager.emit('userData', userData);
                storeManager.emit('setMm5CardAdded', { saved: true });
                flowManager.next('request-card-step2');
            }
        } catch (error) {
            this.ISerror = error;
            console.warn('Cannot call dm validation check', error);
        }

        return result;
    }

    async _loadComuni(value, select, isBirth) {
        try {
            select.reset();
            const comuni = await apiProvider.comuni({ 
                provinciaId: value,
                isBirth: isBirth
            });
            await select.setItems(comuni);
        } catch (error) {
            console.error('Cannot set comuni');
        }
    }

    /* override */
    async _onCancel() {
        flowManager.complete();
    }

    _trackRegistrationFunnel(event, funnelStep, userInfo = null) {
        const typeOfService = storeManager.get('ecRegistrationData')?.typeOfService;
        const originEcommerce = storeManager.get('ecRegistrationData')?.originEcommerce;
        if (storeManager.get('currentFlow').includes('conad-register')) {
            if (originEcommerce && typeOfService) {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, typeOfService, null, userInfo);
            } else {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, null, null, userInfo);
            }
        }
    }
}

register.registerClass('.mm11-request-card-step1', RequestCardStep1);
