import { apiProvider } from '../../../../../../libs/api-provider';
import PopupComponent from '../../../../../../libs/components/popup-component';
import { ecCartProvider } from '../../../../../../libs/ecommerce-cart-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { storeManager } from '../../../../../../libs/store-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { register } from '../../../../../../libs/register';

class ChooseCart extends PopupComponent {
    constructor(name, root) {
        super(name, root);

        this.cartsContainer = this._dEl('container');
        this.cartsDataWrapper = this._dEl('wrapper');
        this.cartGuestEl = this._dEl('guestCart');
        this.guestProductsListing = this._dEl('guestProductsListing');
        this.guestNumberProducts = this._dEl('guest-number-products');
        this.cartUserEl = this._dEl('userCart');
        this.userProductsListing = this._dEl('userProductsListing');
        this.userNumberProducts = this._dEl('user-number-products');
        this.productSampleNode = document.getElementsByClassName("product-empty-sample")[0];
        this.button = this._dEl('button');
        this.cartsEl = document.getElementsByClassName("mp16-choose-cart__single-cart");
        this.loader = this._dEl('loader');
        this.errorMessage = this._dEl('error');
        this.socialSelect = false;
        this.popupLoaded = false;
        this.apiSelectExecutedWithoutError = true;
        this._addEventListeners();
    }

    /* override */
    async _onOpen() {
        await super._onOpen();
        let urlParams = new URLSearchParams(window.location.search);
        let cartsOnConflict = storeManager.get('cartsOnConflict');
        if(!cartsOnConflict) {
            this.socialSelect = true;
            cartsOnConflict = await apiProvider.getCarts({
                guid: urlParams.get('guid'),
                bEcommerce: urlParams.get('bEcommerce')
            });
        }

        if(cartsOnConflict) {
            if(cartsOnConflict.cartUser.tipoServizio === "HOME_DELIVERY") {
                cartsOnConflict.cartUser.tipoServizio = "Ordina e ricevi";
            }

            if (cartsOnConflict.cartUser.tipoServizio === "ORDER_AND_COLLECT"){
                cartsOnConflict.cartUser.tipoServizio = "Ordina e ritira";
            }

            if(cartsOnConflict.cartGuest.tipoServizio === "HOME_DELIVERY") {
                cartsOnConflict.cartGuest.tipoServizio = "Ordina e ricevi";
            }

            if (cartsOnConflict.cartGuest.tipoServizio === "ORDER_AND_COLLECT"){
                cartsOnConflict.cartGuest.tipoServizio = "Ordina e ritira";
            }
        }

        if(cartsOnConflict.cartGuest.isSelected) {
            this.cartGuestEl.classList.add("mp16-choose-cart__cart-selected");
            for (var i = 1; i < this.cartsContainer.childNodes.length; i++){
                this.cartsContainer.insertBefore(this.cartsContainer.childNodes[i], this.cartsContainer.firstChild);
            }
        } else if (cartsOnConflict.cartUser.isSelected) {
            this.cartUserEl.classList.add("mp16-choose-cart__cart-selected");
        }

        /* cart user */
        this.cartUserEl.querySelector('span').textContent = this.cartUserEl.querySelector('span').textContent + " " + cartsOnConflict.cartUser.date;
        let totalPriceUserFormatted = cartsOnConflict.cartUser.totalPrice.toFixed(2);
        totalPriceUserFormatted = totalPriceUserFormatted.toString().replace('.',',');
        this.cartUserEl.querySelector('h2').textContent = totalPriceUserFormatted + " €";
        this.cartUserEl.setAttribute('data-code-cart', cartsOnConflict.cartUser.code);
        this.cartUserEl.setAttribute('data-bEcommerce-cart', cartsOnConflict.cartUser.bEcommerce);

        if(cartsOnConflict.cartUser.products) {
            cartsOnConflict.cartUser.products.forEach(product1 => {
                let productUserNode = this.productSampleNode.cloneNode(true);
                productUserNode.src = product1.img;
                productUserNode.classList.remove("product-empty-sample");
                this.userProductsListing.append(productUserNode);
            });

            if(cartsOnConflict.cartUser.totalProducts > 4) {
                this.userNumberProducts.textContent = "+" + (cartsOnConflict.cartUser.totalProducts - cartsOnConflict.cartUser.products.length);
            }
        }

        let cartUserObjTracking = {
            carrelloSelezionato: {
                tipoServizio: cartsOnConflict.cartUser.tipoServizio,
                idNegozio: cartsOnConflict.cartUser.idNegozio,
                cooperativaNegozio: cartsOnConflict.cartUser.cooperativaNegozio,
                importo: cartsOnConflict.cartUser.totalPrice,
                carrelloSuggerito: 'SI',
                carrelloRecuperato: 'SI'
            }
        };
        this.cartUserEl.setAttribute('data-cart-obj-tracking', JSON.stringify(cartUserObjTracking));
        let cartUserObj = {
            guid: cartsOnConflict.cartUser.code,
            bEcommerce: cartsOnConflict.cartUser.bEcommerce,
            isGuest: false
        };
        this.cartUserEl.setAttribute('data-cart-obj', JSON.stringify(cartUserObj));


        /* cart guest */
        this.cartGuestEl.querySelector('span').textContent = this.cartGuestEl.querySelector('span').textContent + " " + cartsOnConflict.cartGuest.date;
        let totalPriceGuestFormatted = cartsOnConflict.cartGuest.totalPrice.toFixed(2);
        totalPriceGuestFormatted = totalPriceGuestFormatted.toString().replace('.',',');
        this.cartGuestEl.querySelector('h2').textContent = totalPriceGuestFormatted + " €";
        this.cartGuestEl.setAttribute('data-code-cart', cartsOnConflict.cartGuest.code);
        this.cartGuestEl.setAttribute('data-bEcommerce-cart', cartsOnConflict.cartGuest.bEcommerce);

        if(cartsOnConflict.cartGuest.products) {
            cartsOnConflict.cartGuest.products.forEach(product => {
                let productGuestNode = this.productSampleNode.cloneNode(true);
                productGuestNode.src = product.img;
                productGuestNode.classList.remove("product-empty-sample");
                this.guestProductsListing.append(productGuestNode);
            });

            if(cartsOnConflict.cartGuest.totalProducts > 4) {
                this.guestNumberProducts.textContent = "+" + (cartsOnConflict.cartGuest.totalProducts - cartsOnConflict.cartGuest.products.length);
            }
        };

        let cartGuestObjTracking = {
            carrelloSelezionato: {
                tipoServizio: cartsOnConflict.cartGuest.tipoServizio,
                idNegozio: cartsOnConflict.cartGuest.idNegozio,
                cooperativaNegozio: cartsOnConflict.cartGuest.cooperativaNegozio,
                importo: cartsOnConflict.cartGuest.totalPrice,
                carrelloSuggerito: 'NO',
                carrelloRecuperato: 'NO'
            }
        };
       this.cartGuestEl.setAttribute('data-cart-obj-tracking', JSON.stringify(cartGuestObjTracking));
        let cartGuestObj = {
            guid: cartsOnConflict.cartGuest.code,
            bEcommerce: cartsOnConflict.cartGuest.bEcommerce,
            isGuest: true
        };
        this.cartGuestEl.setAttribute('data-cart-obj', JSON.stringify(cartGuestObj));

        /* event tracking carts view */
        let carts = {
            carrelloSuggerito: {
                tipoServizio: cartsOnConflict.cartUser.tipoServizio,
                idNegozio: cartsOnConflict.cartUser.idNegozio,
                cooperativaNegozio: cartsOnConflict.cartUser.cooperativaNegozio,
                importo: cartsOnConflict.cartUser.totalPrice,
                carrelloSuggerito: 'SI',
                carrelloRecuperato: 'SI'
            },
            carrelloNonSuggerito: {
                tipoServizio: cartsOnConflict.cartGuest.tipoServizio,
                idNegozio: cartsOnConflict.cartGuest.idNegozio,
                cooperativaNegozio: cartsOnConflict.cartGuest.cooperativaNegozio,
                importo: cartsOnConflict.cartGuest.totalPrice,
                carrelloSuggerito: 'NO',
                carrelloRecuperato: 'NO'
            }
        };
        this._removeElementsByClass("product-empty-sample");
        this.popupLoaded = true;
        this.cartsDataWrapper.style.visibility = "visible";
        this.loader.style.display = "none";
        let cbOpenPopUp = '';
        if(window.localStorage.getItem('loginSocialCb')) {
            cbOpenPopUp = window.localStorage.getItem('loginSocialCb');
        } else {
            cbOpenPopUp = urlParams.get('cb');
        }
        trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.retrieveCart, FUNNEL_STEP.viewConflictCarts, null, null, null, null, carts, cbOpenPopUp);
    }

    _removeElementsByClass(className){
        const elements = document.getElementsByClassName(className);
        while(elements.length > 0){
            elements[0].remove();
        }
    }

    _addEventListeners() {
        this.button.addEventListener('click', (event) => {
            event.preventDefault();
            if (this.popupLoaded) {
                this.button.classList.add(this._elMod('button', 'disabled'));
                this.button.setAttribute('disabled', 'true');
                this._selectCart();
            }
        });

        for (const cart of this.cartsEl) {
            cart.addEventListener('click', function handleClick() {
                if(!this.classList.contains('mp16-choose-cart__cart-selected'))  {
                    document.querySelectorAll('.mp16-choose-cart__cart-selected').forEach(function(el) {
                        el.classList.remove("mp16-choose-cart__cart-selected");
                    })
                    this.classList.add("mp16-choose-cart__cart-selected");
                }
            });
        }
    }

    async _selectCart() {
        try {
            let carrelloSelezionatoObj = JSON.parse(this._dEl('cart-selected').getAttribute('data-cart-obj'));
            await apiProvider.selectConflictCart({
                code: carrelloSelezionatoObj.guid,
                bEcommerce: carrelloSelezionatoObj.bEcommerce,
                guest: carrelloSelezionatoObj.isGuest
            });
            this.apiSelectExecutedWithoutError = true;
        } catch (error) {
            this.apiSelectExecutedWithoutError = false;
            console.warn(error);
            this.errorMessage.style.display = 'block';
            this.button.classList.remove(this._elMod('button', 'disabled'));
            this.button.setAttribute('disabled', 'false');
        }

        if(this.apiSelectExecutedWithoutError) {
            let urlParams = new URLSearchParams(window.location.search);
            this.errorMessage.style.display = 'none';
            ecCartProvider.resetCache();
            let cbSelectCart = '';
            if(window.localStorage.getItem('loginSocialCb')) {
                cbSelectCart = window.localStorage.getItem('loginSocialCb');
            } else {
                cbSelectCart = urlParams.get('cb');
            }
            let carrelloSelezionatoTracking = JSON.parse(this._dEl('cart-selected').getAttribute('data-cart-obj-tracking'));
            trackEvent(TRACKABLE_EVENT.chooseCartConflict, FUNNEL_NAME.retrieveCart, FUNNEL_STEP.confirmSelectCart, null, null, null, null, carrelloSelezionatoTracking, cbSelectCart);
            flowManager.backOrComplete();
            if(!this.socialSelect) {
                storeManager.emit('mw1CompleteLogin', { doRedirectAfterLogin: true });
            } else {
                window.location = urlParams.get('cb');
            }
        }
    }

    /* override */
    async _onCancel() {
        flowManager.backOrComplete();
    }
}

register.registerClass('.mp16-choose-cart', ChooseCart);
