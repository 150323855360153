import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';
import { flowManager } from '../../../../../libs/flow-manager';
import { apiProvider } from '../../../../../libs/api-provider';
import { closeLoader, openLoader } from '../mt11-loader/script';

export class StoreCard extends Component {
    constructor(name, root) {
        super(name, root);

        this.modifyStore = this._dEl('modifyStore');
        this.removeStore = this._dEl('remove', true);
        this.setAsFavorite = this._dEl('setAsFavorite', true);

        this.anacanId = this.root.dataset.anacanId;
        this.anacanFavorite = this.root.dataset.anacanFavorite;

        this._addListeners();
        this._addStoreListeners();
    }

    _addStoreListeners() {}

    _addListeners() {
        this.removeStore.forEach((button) => {
            this._addListener(
                'click',
                async () => {
                    openLoader('main');
                    try {
                        await apiProvider.removeSavedStore(this.anacanId);
                        window.location.reload();
                    } catch (error) {
                        console.error(error);
                        closeLoader('main');
                    }
                },
                button
            );
        });

        this.setAsFavorite.forEach((button) => {
            this._addListener(
                'click',
                async () => {
                    await openLoader('main');
                    try {
                        await apiProvider.setPreferredStore({ anacanId: this.anacanId });
                        window.location.reload();
                    } catch (error) {
                        console.log(error);
                        await closeLoader('main');
                    }
                },
                button
            );
        });

        if(this.modifyStore){
            this._addListener(
                'click',
                () => {
                    this._emit('setModality', { modality: 'modifyStore' });
                    flowManager.startFlow({
                        flowName: 'select-pdv',
                        flowSteps: [{ name: 'select-pdv' }],
                    });
                },
                this.modifyStore
            );
        }
    }
}

register.registerClass('.mt44-store-card', StoreCard);
