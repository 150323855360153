import Component from '../../../../../../libs/components/component';
import { ecCartProvider } from '../../../../../../libs/ecommerce-cart-provider';
import { runTemplate } from '../../../../../../libs/htl-runtime/HTMLRuntime';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { emptyElement } from '../../../../../../libs/utils';
import { openLoader } from '../../../templates/mt11-loader/script';
import cartButton from './partials/cart-button.html';

class Header extends Component {
    constructor(name, root) {
        super(name, root);

        this.cart = this._dEl('cart');
        this.checkCart = window.accessInfo.cart && this.root.dataset.isFunctional == 'true';
        this._addEventListeners();
        this._addStoreListeners();
    }

    async _initCart() {
        //check cart
        const cart = await ecCartProvider.getCart();
        if (cart?.totalUnitCount > 0) {
            emptyElement(this.cart);
            const tpl = runTemplate(cartButton, { cart }, this._el('cartDataWrapper', true));
            this.cart.appendChild(tpl);
            this.cart.classList.add(this._elMod('cart', 'show'));
        }
    }

    _addEventListeners() {
        this.cart?.addEventListener('click', (event) => {
            event.preventDefault();
            if (this.cart?.dataset?.ecDetailCart) {
                openLoader('main');
                window.location = this.cart?.dataset?.ecDetailCart;
            }
        });
    }

    _addStoreListeners() {
        storeManager.on('userData', () => {
            // not have cart then return
            if (!this.checkCart) return;
            this._initCart(); //init cart only if user exists
        });
    }
}

register.registerClass('.ms2-header', Header);
