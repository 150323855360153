import Component from '../../../../../../libs/components/component';
import { register } from '../../../../../../libs/register';
import { openLoader, closeLoader } from '../../../templates/mt11-loader/script';
import axios from 'axios';

class Travel extends Component {

    constructor(name, root) {
        super(name, root);

        this.RESERVATIONS = 'RESERVATIONS';
        this.FAVORITES = 'FAVORITES';

        this.types = [this.RESERVATIONS, this.FAVORITES];
        this.tabList = this._dEl('tabs');
        this.tab = {
            'RESERVATIONS': this._dEl('reservationsTab'),
            'FAVORITES': this._dEl('favoritesTab')
        };
        this.panels = {
            'RESERVATIONS': this._dEl('reservationsContent'),
            'FAVORITES': this._dEl('favoritesContent')
        };
        this.tabs = Object.values(this.tab);
        this.loaderEndpoint = {
            'RESERVATIONS': this.root.dataset.loaderReservations,
            'FAVORITES': this.root.dataset.loaderFavorites
        };
        this.nextIndexes = {
            'RESERVATIONS': 0,
            'FAVORITES': 0
        };
        this.totalLength = {
            'RESERVATIONS': parseInt(this.root.dataset.lengthReservations),
            'FAVORITES': parseInt(this.root.dataset.lengthFavorites)
        };
        this.initLength = {
            'RESERVATIONS': parseInt(this.root.dataset.reservationsInitLength),
            'FAVORITES': parseInt(this.root.dataset.favoritesInitLength)
        }
        this.containers = {
            'RESERVATIONS': this._dEl('reservations'),
            'FAVORITES': this._dEl('favorites'),
        };
        this.loadMoreOffsets = {
            'RESERVATIONS': parseInt(this.root.dataset.reservationsDownloadLength),
            'FAVORITES': parseInt(this.root.dataset.favoritesDownloadLength),
        };

        this.displayEmpty = {
            'RESERVATIONS': this._dEl('emptyRes'),
            'FAVORITES': this._dEl('emptyFav')
        };

        this.loadMores = {
            'RESERVATIONS': this._dEl('loadMoreRes'),
            'FAVORITES': this._dEl('loadMoreFav'),
        };

        /* load initial orders dynamically */
        (async () => {
            openLoader('main');

            const active = this.root.dataset.activeTab;
            this.tabFocus = this.types.indexOf(active) || 0;
            const firstOpenTab = this.tab[active || this.RESERVATIONS];
            await this._downloadOrders(this.types[this.tabFocus]);
            this._endLoading(this.types[this.tabFocus]);

            firstOpenTab.setAttribute('tabindex', '0');
            this._selectTab(firstOpenTab, this._getTabPanelFromTab(firstOpenTab), true);
            await this._downloadOrders(this.types[1-this.tabFocus]);
            this._endLoading(this.types[1-this.tabFocus]);

            closeLoader('main');
        })();

        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {

        this.tabs.forEach((tab) => {
            tab.addEventListener('click', () => {
                if (this._isTabActive(tab)) 
                    return;
                // Remove all current selected tabs
                const selectedTabs = [...this.tabList.querySelectorAll('[aria-selected="true"]')];
                selectedTabs?.forEach(t => {
                    this._selectTab(t, this._getTabPanelFromTab(t), false);
                });
                // Set this tab as selected
                this._selectTab(tab, this._getTabPanelFromTab(tab), true);
            });
        });
        this.tabList.addEventListener('keydown', (e) => {
            if (e.key !== 'ArrowRight' && e.key !== 'ArrowLeft') return;
            this.tabs[this.tabFocus].setAttribute('tabindex', '-1');
            if (e.key === 'ArrowRight') {
                this.tabFocus++;
                // if at the end, go to the start
                if (this.tabFocus >= this.tabs.length) {
                    this.tabFocus = 0;
                }
            } else if (e.key === 'ArrowLeft') {
                this.tabFocus--;
                // if at the start, move to the end
                if (this.tabFocus < 0) {
                    this.tabFocus = this.tabs.length - 1;
                }
            }
            this.tabs[this.tabFocus].setAttribute('tabindex', '0');
            this.tabs[this.tabFocus]?.focus();
        });

        this.types.forEach((type) => {
            this._addListener(
                'click',
                async () => {
                    this._startLoading(type);
                    await this._downloadOrders(type);
                    this._endLoading(type); 
                },
                this.loadMores[type]
            );
        });
    }

    _startLoading(type){
        this.loadMores[type].classList.add(this._elMod('loadMore', 'loader'));
    }

    _endLoading(type){
        this.loadMores[type].classList.remove(this._elMod('loadMore', 'loader'));
        if(this.nextIndexes[type] >= this.totalLength[type])
            this.loadMores[type].classList.add(this._elMod('loadMore', 'hidden'));
        else if(type == 'RESERVATIONS')
            this._dEl('reservations').classList.add(this._elMod('reservations', 'endLoadMore'));
    }

    _addStoreListeners(){
        this._addStoreListener('mf14RemoveATravelFavorite', () => {
            const type = this.FAVORITES;
            this.nextIndexes[type]--;
            this.totalLength[type]--;
            console.log(this.nextIndexes[type]);
            console.log(this.totalLength[type]);
            this._endLoading(this.FAVORITES);
        })
    }

    _getTabPanelFromTab(tab) {
        const panel = this.root.querySelector(`#${tab.getAttribute('aria-controls')}`);
        return panel;
    }

    _isTabActive(tab) {
        return tab.classList.contains(this._el('activeTab'));
    }

    _selectTab(tab, tabPanel, select = true) {
        if (select == true) {
            tab.setAttribute('aria-selected', 'true');
            tab.classList.add(this._el('activeTab'));
            tabPanel.removeAttribute('hidden');
            tabPanel.setAttribute('aria-hidden', 'false');
        } else {
            tab.setAttribute('aria-selected', 'false');
            tab.classList.remove(this._el('activeTab'));
            tabPanel.setAttribute('hidden', 'true');
            tabPanel.setAttribute('aria-hidden', 'true');
        }
    }

    async _downloadOrders(type) {
        if (!type || !this.types.includes(type)) return;
        const url = this.loaderEndpoint[type];
        const data = {
            type: type == this.RESERVATIONS ? 'res' : 'fav',
            from: this.nextIndexes[type],
            to: this.nextIndexes[type] + (this.nextIndexes[type]== 0 ? this.initLength[type] :  this.loadMoreOffsets[type]),
        };
        try {
            console.log(type)
            console.log(this.nextIndexes[type]);
            console.log(this.totalLength[type]);
            const response = await axios({
                method: 'get',
                url: url,
                responseType: 'text',
                params: data
            });
            this._appendOrders(response.data, type);
            this.nextIndexes[type]+= this.nextIndexes[type]== 0 ? this.initLength[type] :  this.loadMoreOffsets[type];
            console.log(this.nextIndexes[type]);
            console.log(this.totalLength[type]);
        } catch (error) {
            console.error(error);
            this._appendOrders("", type);
        }
    }

    _appendOrders(reservations, type) {
        if (!type || !this.types.includes(type)) return;
        let toAppend = [];
        const parser = new DOMParser();
        const doc = parser.parseFromString(reservations, 'text/html');
        if ( type == 'RESERVATIONS') {
            toAppend = doc.querySelectorAll(this._el('monthReservation', true));
            if(toAppend.length != 0){
                const toMerge = toAppend[0].dataset.merge;
                if(toMerge != undefined && this.nextIndexes[type] != 0){
                    const inPage = this.root.querySelectorAll(this._el('monthReservations', true));
                    const cards = toAppend[0].querySelectorAll(this._el('resCard', true))
                    inPage[inPage.length-1].append(...cards);
                    toAppend = [...toAppend].slice(1, toAppend.length);
                }
            }
        }else {
            toAppend = doc.querySelectorAll(this._el('favCard', true));
        }
        
        const modifyText = this._dEl('modRes');
        const goToConadViaggi = this._dEl('goTo');
        if(toAppend.length == 0 && this.nextIndexes[type] == 0){
            this.displayEmpty[type].classList.remove(this._elMod('empty', 'hidden'));
            this.loadMores[type].classList.add(this._elMod('loadMore', 'hidden'));
        }
        else{
            this.displayEmpty[type].classList.add(this._elMod('empty', 'hidden'));
            if(modifyText && type == 'RESERVATIONS') modifyText.classList.remove(this._elMod('modRes', 'hidden'));
            if(goToConadViaggi && type == 'FAVORITES') goToConadViaggi.classList.remove(this._elMod('goTo', 'hidden'));
        }
        this.containers[type].append(...toAppend);
        
    }

}

register.registerClass('.mf14-travel', Travel);
