import PerfectScrollbar from 'perfect-scrollbar';
import { Modal } from '../../repository/apps/conad-myconad/templates/mt1-modal/script';
import { flowManager } from '../flow-manager';
import { storeManager } from '../store-manager';
import { htmlToElement } from '../utils';
import Component from './component';

export default class ModalComponent extends Component {
    constructor(name, root) {
        super(name, root);
        this.wrapper = this._dEl('wrapper');
        this.topBar = this._dEl('topBar');
        this.bottomBar = this._dEl('bottomBar');
        this.stepsIcons = this._dEl('stepsIcons');
        this.title = this._dEl('title');
        this.modal = Modal.getCurrentModal(this.root);
        this.modalName = this.modal.getModalName();
        if (!this.wrapper) throw 'A modal component need to have a wrapper div';

        this._initModalEvents();
        this._initSteps();
    }

    _initModalEvents() {
        this.initWrapperScrollbar();

        /* scrollbar handlers */
        this.wrapper.addEventListener('ps-y-reach-start', () => {
            if (this.topBar) this.topBar.classList.remove(this._elMod('topBar', 'shadow'));
            if (this.stepsIcons && !this.topBar) this.stepsIcons.classList.add(this._elMod('stepsIcons', 'show'));
        });
        this.wrapper.addEventListener('ps-scroll-down', () => {
            if (this.topBar) this.topBar.classList.add(this._elMod('topBar', 'shadow'));
            if (this.stepsIcons && !this.topBar) this.stepsIcons.classList.remove(this._elMod('stepsIcons', 'show'));
        });
        this.wrapper.addEventListener('ps-y-reach-end', () => {
            if (this.bottomBar) this.bottomBar.classList.remove(this._elMod('bottomBar', 'shadow'));
        });
        this.wrapper.addEventListener('ps-scroll-up', () => {
            if (this.bottomBar) this.bottomBar.classList.add(this._elMod('bottomBar', 'shadow'));
        });

        /* modal events */
        this.modal.getRoot().addEventListener('ecModalOpen', () => {
            this.wrapper.scrollTop = 0;
            this._onOpen();
        });
        this.modal.getRoot().addEventListener('ecModalClose', () => {
            this._onClose();
        });
        this.modal.getRoot().addEventListener('ecModalCancel', () => {
            this._onCancel();
        });

        /* flow buttons events */
        if (this.title)
            this.title.addEventListener('click', (event) => {
                if (!event.target.matches(this._elMod('title', 'back', true))) return;
                flowManager.back();
            });
        if (this.stepsIcons)
            this.stepsIcons.addEventListener('click', (event) => {
                if (!event.target.matches('[data-flow-step]')) return;
                flowManager.back(event.target.dataset.flowStep);
            });
    }

    initWrapperScrollbar() {
        const scrollbarOptions = {
            swipeEasing: true,
            suppressScrollX: true,
        };
        this.ps = new PerfectScrollbar(this.wrapper, scrollbarOptions);
    }

    _initSteps() {
        storeManager.on(/flow\/[^/]+\/backStack/g, async (path, backStack) => {
            if (!this.title) return;
            const flowName = flowManager.getFlowNameByStorePath(path);
            const activeStep = storeManager.get(`flow/${flowName}/activeStep`);
            if (activeStep.el != this.modal.getRoot()) {
                return;
            }

            if (activeStep.disableBack || !backStack || backStack.length <= 0) {
                this.title.classList.remove(this._elMod('title', 'back'));
            } else {
                this.title.classList.add(this._elMod('title', 'back'));
            }
        });

        storeManager.on(/flow\/[^/]+\/state/g, async (path, steps) => {
            if (!this.stepsIcons) return;
            const flowName = flowManager.getFlowNameByStorePath(path);
            const activeStep = storeManager.get(`flow/${flowName}/activeStep`);
            if (activeStep.el != this.modal.getRoot()) {
                return;
            }

            if (!activeStep.index || storeManager.get('flow')?.options?.hideStepIcons) {
                return;
            }

            while (this.stepsIcons.firstChild) {
                this.stepsIcons.removeChild(this.stepsIcons.firstChild);
            }

            if (!steps || steps.length <= 0) {
                this.stepsIcons.classList.remove(this._elMod('stepsIcons', 'show'));
                return;
            }

            for (const index in steps) {
                if (steps[index]) {
                    const icStep = steps[index]
                        .filter((item) => item.status != 'disabled')
                        .reduce((total, item) => (total != null && total.status == 'active' ? total : item), null);
                    this.stepsIcons.appendChild(
                        htmlToElement(`<button class="step ${icStep.status}" data-flow-step="${icStep.name}"></button>`)
                    );
                }
            }
            this.stepsIcons.classList.add(this._elMod('stepsIcons', 'show'));
        });
    }

    async _onOpen() {
        //focus content
        this.modal?.content?.focus();
    }

    async _onClose() {}

    async _onCancel() {}
}
