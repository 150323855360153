import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';

class ReservationCard extends Component {
    
    constructor(name, root) {
        super(name, root);
    }

}

register.registerClass('.mt43-reservation-card', ReservationCard);