import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';

class Filter extends Component {
    constructor(name, root) {
        super(name, root);
        this.filterButton = this._dEl('filter');
        this.selectedFilter = this._dEl('selectedFilter');
        this.sfPlaceholder = this.selectedFilter.dataset.placeholder;
        this.filterIcon = this._dEl('filterIcon');
        this.filterDropdown = this._dEl('filterDropdown');
        this.filterItemLabels = Array.from(this.filterDropdown.getElementsByClassName(this._el('filterItemLabel')));
        this.filterRadios = this._dEl('filterRadio', true);
        this._addEventListeners();
    }

    _addEventListeners() {

        this.filterButton.addEventListener('click', () => {
            this._toggleButton();
        });

        this.filterItemLabels.forEach((fiLabel) => {
            fiLabel.addEventListener('keydown', (event) => {
                if (!(event.key == 'Enter')) return;
                fiLabel.click();
                this._toggleButton();
            });
            let radio = fiLabel.querySelector(this._el('filterRadio', true));
            radio.addEventListener('change', (event) => {
                let clickedRadio = event.target;
                let selectedFilterLabel = clickedRadio.nextElementSibling.textContent;
                this.selectedFilter.innerText = selectedFilterLabel;
                this._closeDropdown();
                /* emit custom event for changed filter (with bubbling) */
                const eventFilterChanged = new CustomEvent('mt10FilterChanged', { bubbles: true });
                eventFilterChanged.data = {
                    value: clickedRadio.value,
                    label: selectedFilterLabel,
                    filters: this.root
                }
                this.root.dispatchEvent(eventFilterChanged);
            });
        });

        /* close dropdown on click outside */
        document.addEventListener('click', (event) => {
            const target = event.target;
            if (!target || target.closest(this._el('content', true)) || !this._isDropdownOpen()) 
                return;
            this._closeDropdown();
        });
    }

    getLabel() {
        const checked = this.filterRadios.filter((radio) => radio.checked);
        const label = checked.length == 1
            ? checked[0].nextElementSibling.textContent
            : '';
        return label;
    }

    getValue() {
        const checked = this.filterRadios.filter((radio) => radio.checked);
        return checked.length <= 0 ? '' : checked[0].value;
    }

    setSelected(value) {
        this.filterRadios.filter((radio) => radio.checked).forEach((ch) => ch.checked = false);
        const target = this.filterRadios.filter((radio) => radio.value == value);
        if (target.length !== 1) {
            console.warn(`[${this.getName()}] duplicate values in filter`);
            return;
        }
        target[0].checked = true;
        const label = target[0].nextElementSibling.textContent;
        this.selectedFilter.innerText = label;
    }

    _toggleClass(elem, clazz) {
        if (!elem) {
            console.log(`[${this.getName()}] error: elem not found in _toggleClass.`);
            return;
        }
        if (elem.classList.contains(clazz)) {
            elem.classList.remove(clazz);
        } else {
            elem.classList.add(clazz);
        }
    }

    _toggleButton() {
        /* rotate chevron arrow */
        this._toggleClass(this.filterIcon, this._elMod('filterIcon', 'rotate'));
        /* open or close filter dropdown */
        this._toggleClass(this.filterDropdown, this._elMod('filterDropdown', 'open'));
    }

    _isDropdownOpen() {
        return this.filterDropdown.classList.contains(this._elMod('filterDropdown', 'open'));
    }

    _closeDropdown() {
        this.filterDropdown.classList.remove(this._elMod('filterDropdown', 'open'));
        this.filterIcon.classList.remove(this._elMod('filterIcon', 'rotate'));
        this.filterButton?.focus();
    }

    disable() {
        if (this.filterButton.classList.contains(this._elMod('filter', 'disabled'))) return;
        this.filterButton.classList.add(this._elMod('filter', 'disabled'));
    }
    enable() {
        this.filterButton.classList.remove(this._elMod('filter', 'disabled'));
    }
}

register.registerClass('.mt10-filter', Filter);
