import Component from '../../../../../../libs/components/component';
import { register } from '../../../../../../libs/register';

class Payments extends Component {
    constructor(name, root) {
        super(name, root);
        this.iframeEl = this._dEl('iframeContainer').firstElementChild;
        this.iframe = register.getClass(this.iframeEl);
        this.url = this.root.dataset.iframeUrl;
        this.iframe.setIframeSrc(this.url);
        this.iframe.setIframeTitle('Payment methods');
        this._adjustLayoutItemHeight();
    }

    _adjustLayoutItemHeight() {
        const layoutItem = this.root.closest('.ml2-navlayout__item');
        if (!layoutItem) return;
        layoutItem.style.height = '100%';
    }
}

register.registerClass('.mf1-payments', Payments);
