import Component from '../../../../../../libs/components/component';
import { register } from '../../../../../../libs/register';
import { openLoader, closeLoader } from '../../../templates/mt11-loader/script';
import axios from 'axios';
import { delay } from '../../../../../../libs/utils';

class Messages extends Component {
    constructor(name, root) {
        super(name, root);

        this.loaderEndpoint = this.root.dataset.endpoint;
        this.currentSize = 0;
        this.downloadLength = parseInt(this.root.dataset.downloadLength);
        this.countMsg = this.root.querySelector(`${this._el('countMsg', true)} > span`);
        this.loadMore = this._dEl('loadMore');
        this.noMessages = this._dEl('noMessages');
        this.content = this._dEl('content');
        this.filter = register.getClass(this._dEl('filter'));

        /* load initial messages dynamically */
        (async () => {
            openLoader('main');
            await this._downloadMessages(this.currentSize, this.downloadLength);
            closeLoader('main');
        })();

        this._addEventListeners();
    }

    _addEventListeners() {
        this.loadMore?.addEventListener('click', async () => {
            openLoader('main');
            const lastFocusedMessage = this.content.querySelector(`${this._el('message', true)}:last-child`);
            await this._downloadMessages(this.currentSize, this.downloadLength);
            setTimeout(() => {
                closeLoader('main');
                lastFocusedMessage?.querySelector('button')?.focus();
            }, 500);
        });
        this.root.addEventListener('mt10FilterChanged', async (event) => {
            console.info(`[${this.getName()}] selected filter: ${event.data.label} (${event.data.value})`);
            openLoader('main');
            this.currentSize = 0;
            await this._emptyMessages(this.content);
            await this._downloadMessages(this.root.dataset.initLength, this.downloadLength);
            await delay(300);
            closeLoader('main');
            event.stopPropagation();
        });
    }

    async _downloadMessages(init, length) {
        const url = this.loaderEndpoint;
        const data = {
            filter: this.filter.getValue(),
            init: init,
            length: length,
            currentSize: this.currentSize
        };
        try {
            const response = await axios({
                method: 'get',
                url: url,
                responseType: 'text',
                params: data,
            });
            this._appendMessages(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    _appendMessages(messages) {
        /* parse new messages */
        const parser = new DOMParser();
        const doc = parser.parseFromString(messages, 'text/html');
        const newMessages = doc.querySelectorAll('.mt7-message');
        const countMsg = doc.querySelector(this._el('loader', true)).dataset.countMsg;

        const class_hideLoadMore = this._elMod('loadMore', 'hidden');
        let classList_loadMore = this.loadMore?.classList;
        const class_hideContent = this._elMod('content', 'hidden');
        let classList_content = this.content.classList;
        const class_hideNoMessages = this._elMod('noMessages', 'hidden');
        let classList_noMessages = this.noMessages.classList;

        /* hide/show loadMore */
        if (this.currentSize != 0 && newMessages.length < this.downloadLength) {
            /* case no more messages to download next */
            classList_loadMore.add(class_hideLoadMore);
        } else {
            /* case possibly more messages to download next */
            classList_loadMore.remove(class_hideLoadMore);
        }

        /* hide/show messages */
        if (this.currentSize + newMessages.length <= 0) {
            /* case no messages */
            classList_content.add(class_hideContent);
            classList_noMessages.remove(class_hideNoMessages);
            this.countMsg.textContent = '0';
            classList_loadMore.add(class_hideLoadMore);
        } else {
            /* case messages */
            classList_content.remove(class_hideContent);
            classList_noMessages.add(class_hideNoMessages);
            /* append new messages */
            this.content.append(...newMessages);
            /* update current size */
            this.currentSize += newMessages.length;
            /* hide/show loadMore */
            if (this.currentSize >= countMsg) {
                classList_loadMore.add(class_hideLoadMore);
            }
            /* update total number of messages */
            this.countMsg.textContent = countMsg;
        }
    }

    async _emptyMessages(container) {
        if (!container || container.objReference || !container.hasChildNodes()) return;
        for (let i = container.childElementCount; i > 0; i--) {
            container.removeChild(container.children[i-1]);
        }
    }
}

register.registerClass('.mf2-messages', Messages);
