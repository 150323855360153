import ModalComponent from '../../../../../../libs/components/modal-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { informativaManager } from '../../../../../../libs/informativa-manager';

class CardAdded extends ModalComponent {
    constructor(name, root) {
        super(name, root);

        this.title = this._dEl('title');
        this.thankTxt = this._dEl('thankTxt');
        this.submit = this._dEl('submit');
        this.modality = 'only-validation';
        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        this.submit.addEventListener('click', (event) => {
            event.preventDefault();
            this._done();
        });
    }

    _addStoreListeners() {
        //intercept flow options and get modality if defined
        storeManager.on(/flow\/[^/]+$/g, async (path, flow) => {
            if (flow.options?.requestCardModality) this.modality = flow.options?.requestCardModality;

            if(this.modality === 'registration') this.submit.innerText = this.submit.dataset.registrationLabel;
        });
        storeManager.on('setMm5CardAdded', async (path, data) => {
            if (data.title) {
                this.title.innerText = data.title;
                this._initPrivacyText();
                const selectedPdvUser = storeManager.get('selectedPdv');
                const userData = {
                    ...storeManager.get('userData'),
                    dmCard: true,
                };
                this.currentPrivacy = await informativaManager.getInformativa(userData, selectedPdvUser);
                this._setInformativa();
            }
            if (data.saved) this.thankTxt.innerText = this.thankTxt.dataset.saved;
        });
    }

    async _initPrivacyText() {
        const privacyText = this._dEl('thankTxt');
        const text = privacyText.dataset.thank;
        if (!text || text == '') return;
        const linkLabel = privacyText.dataset.privacyLabel || (dictionary.get('privacy policy'));
        if (!text.includes('$privacy')) {
            privacyText.innerText = text;
            return;
        }
        const split = text.split('$privacy');
        const link = document.createElement('a');
        link.href = '#';
        link.classList.add(this._el('privacyLink'));
        link.innerText = linkLabel;
        privacyText.innerHTML = "";
        privacyText.append(split[0], link, split[1]);
    }

    async _setInformativa() {
        const privacyLinks = this._dEl('privacyLink', true);
        if (!privacyLinks) {
            console.warn('Missing privacy links.');
            return;
        }

        for(const privacyLink of privacyLinks){
            privacyLink.href = this.currentPrivacy.linkPdfInformativa;
            privacyLink.title = `informativa ${this.currentPrivacy.version}`;
            privacyLink.target = '_blank';
        }
    }
    
    /* override */
    async _onCancel() {
        this._done();
    }

    _done() {
        switch (this.modality) {
            case 'only-validation':
                storeManager.emit('setMw3RegisterLight', { modifyCard: true, showContinue: true });
                flowManager.complete();
                break;
            case 'associate-dm':
            case 'add-card':
                var url = new URL(window.location.href);
                url.searchParams.set('app','refresh');
                window.location.href = url.href;
                break;
            case 'registration':
                flowManager.complete();
                storeManager.emit('privacyWithFidelityCard', {});
                flowManager.next('privacy', null, true);
                break;
            default:
                console.warn(`[${this.getName()}] unknown modality "${this.modality}"`);
                break;
        }
    }
}

register.registerClass('.mm5-card-added', CardAdded);
