import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { register } from '../../../../../../libs/register';
import { FUNNEL_NAME, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';

class UndeletableProfile extends PopupComponent {
    constructor(name, root) {
        super(name, root);
    }

    async _onOpen() {
        await super._onOpen();

        trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.disablingUser, 'Impossibilità di eliminare profilo');
    }

    async _onCancel() {
        flowManager.backOrComplete();
    }
}

register.registerClass('.mp12-undeletable-profile', UndeletableProfile);
