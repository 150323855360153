import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';
import { showPopup } from '../mt4-popup/script';
import { apiProvider } from '../../../../../libs/api-provider';
import { closeLoader, openLoader } from '../mt11-loader/script';

class Message extends Component {
    constructor(name, root) {
        super(name, root);
        
        this.id = this.root.dataset.id;
        this.valore = JSON.parse(this.root.dataset.valore);
        this.unread = this._dEl('unread');
        this.readMore = this._dEl('readMore');
        this.popupContent = this._dEl('popupContent').content.querySelector('.mt9-message-popup');
        
        this._addEventListeners();
    }

    _addEventListeners() {
        this.readMore.addEventListener('click', (event) => {
            event.preventDefault();
            if (this.valore.letto === 'N') this._setReadMessage();
            const clone = this.popupContent.cloneNode(true);
            showPopup('main', clone);
        });
    }

    async _setReadMessage() {
        const valore = this.valore;
        valore.letto = 'S';
        valore.dataLettura = `${new Date().getTime()}`;
        const data = {
            id: this.id,
            valore: JSON.stringify(valore)
        };
        try {
            openLoader('main');
            const result = await apiProvider.editComplementaryData(data);
            console.log(result);
            if (this.unread) this.unread.remove();
        } catch (error) {
            console.warn(error);
        } finally {
            closeLoader('main');
        }
    }
}

register.registerClass('.mt7-message', Message);
