import { apiProvider } from '../../../../../../libs/api-provider';
import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { formToJSON } from '../../../../../../libs/form-to-json';
import { informativaManager } from '../../../../../../libs/informativa-manager';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { getTrackingManager } from '../../../../../../libs/tracking-manager';
import { isPositive } from '../../../../../../libs/utils';
import PerfectScrollbar from 'perfect-scrollbar';

class RefreshUpdateProfile extends PopupComponent {
    constructor(name, root) {
        super(name, root);
        this.user;
        this.trackingManager = getTrackingManager();
        this.form = this._dEl('form');
        this.checkboxPrivacy = this._dEl('privacy');
        this.checkboxRules = this._dEl('regulationCartaInsieme');
        this.submit = this._dEl('ctaAction');
        this.alertContainer = this._dEl('alertMessageInputs');
        this.mailAlertMessage = this._dEl('mailAlertMessage');
        this.content = this._dEl('content');
        this.phoneNumber = this._dEl('phoneNumber');
        this.changedMailLogin = false;

        this._addEventListeners();
    }

    /* override */
    async _onOpen() {
        await super._onOpen();

        await storeManager.waitInit(); // only after init, userData is available
        this.user = storeManager.get('userData');
        await this.trackingManager.track(this.root, {
            event: 'pageview',
            funnel: {
                nomeFunnel: 'Conad Refresh',
                stepFunnel: 'Pop up aggiornamento dati',
            },
        });

        this.form.reset();

        // preload phoneNumber
        const data = flowManager.getDataFromFlow();
        if (data?.telefonoCellulare && this.phoneNumber) {
            register.getClass(this.phoneNumber).setValue(data.telefonoCellulare);
        }

        if (!this.submit.classList.contains(this._elMod('ctaAction', 'disabled'))) {
            this.submit.classList.add(this._elMod('ctaAction', 'disabled'));
        }

        // create ps scrollbar
        const scrollbarOptions = {
            swipeEasing: true,
            suppressScrollX: true,
        };
        this.ps = new PerfectScrollbar(this.content, scrollbarOptions);
    }

    _addEventListeners() {
        this._addListener(
            'ecInputChanged',
            async () => {
                let mailAddressInput = register.getClass(this._dEl('mailAddress'));
                if (mailAddressInput.getValue() == this.user.mailOcdb) {
                    this.alertContainer.classList.remove(this._elMod('alertMessageInputs', 'hidden'));
                    this.mailAlertMessage.classList.remove(this._elMod('mailAlertMessage', 'hidden'));
                } else {
                    if (!this.mailAlertMessage.classList.contains(this._elMod('mailAlertMessage', 'hidden'))) {
                        this.mailAlertMessage.classList.add(this._elMod('mailAlertMessage', 'hidden'));
                        this.alertContainer.classList.add(this._elMod('alertMessageInputs', 'hidden'));
                    }
                }
            },
            this._dEl('mailAddress')
        );

        this._addListener(
            'click',
            async () => {
                await this.trackingManager.track(this.root, {
                    event: 'pageview',
                    funnel: {
                        nomeFunnel: 'Conad Refresh',
                        stepFunnel: 'Pop up aggiornamento dati - CTA Non ora',
                    },
                });

                flowManager.complete(); // always go to prize selection
            },
            this._dEl('ctaClose')
        );

        this._dEl('box', true).forEach((box) => {
            this._addListener(
                'ecInputChanged',
                (event) => {
                    if (!event.target.classList.contains('mt17-checkbox')) {
                        this._checkRadio(event.target.closest(this._el('box', true)), isPositive(event.data.value));
                    }
                },
                box
            );
        });

        // on user data update informative
        this._addStoreListener('userData', (path, data) => {
            const user = data;
            // set privacy link
            const privacyLabelEl = this.checkboxPrivacy.querySelector('.mt17-checkbox__labelText');
            const linkInf = `<a target="_blank" href="${informativaManager.getInformativaLinkByUser(user)}">${
                this.root.dataset.privacyLinkLabel
            }</a>`;
            privacyLabelEl.innerHTML = privacyLabelEl.innerHTML.replace('$privacy', linkInf);

            // set rules link
            const rulesLabelEl = this.checkboxRules.querySelector('.mt17-checkbox__labelText');
            const linkRules = `<a target="_blank" href="${apiProvider.getRegolamentoLink(user.newMappedCooperative)}">${
                this.root.dataset.rulesLinkLabel
            }</a>`;
            rulesLabelEl.innerHTML = rulesLabelEl.innerHTML.replace('$rules', linkRules);
        });

        this.form.addEventListener('submit', async (event) => {
            event.preventDefault();

            if (!this.form.checkValidity()) return;

            let updatedData = formToJSON(this.form);
            if (updatedData.mailAddress && updatedData.mailAddress != this.user.mailOcdb) {
                updatedData = { ...updatedData, emailOCDB: updatedData.mailAddress };
            }

            await this.trackingManager.track(this.root, {
                event: 'pageview',
                funnel: {
                    nomeFunnel: 'Conad Refresh',
                    stepFunnel: 'Pop up aggiornamento dati - CTA Salva',
                    quartoConsenso: updatedData?.privacyTZ == 'S' ? 'SI' : 'NO',
                },
            });
            flowManager.appendDataToFlow(updatedData);
            flowManager.complete();
        });
    }

    _checkRadio(box, valid) {
        const boxNotSelected = box.querySelector(this._el('boxNotSelected', true));
        if (!valid) {
            boxNotSelected.classList.add(this._elMod('boxNotSelected', 'show'));
        } else {
            boxNotSelected.classList.remove(this._elMod('boxNotSelected', 'show'));
        }
    }
}

register.registerClass('.mp25-refresh-update-profile', RefreshUpdateProfile);
