import PopupComponent from '../../../../../../libs/components/popup-component';
import { register } from '../../../../../../libs/register';

class BolliniInfo extends PopupComponent {
    constructor(name, root) {
        super(name, root);
    }
}

register.registerClass('.mp23-bollini-info', BolliniInfo);
