import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';

class FooterLinks extends Component {
    constructor(name, root) {
        super(name, root);
        this.closer = this._dEl('closer');
        this.container = this._dEl('container');
        this._addEventListeners();
    }

    _addEventListeners() {
        this.root.addEventListener('click', (event) => {
            if (!event.target.matches(this._el('closer', true))) return;
            event.preventDefault();

            if (this.root.classList.contains(this._mod('open'))) {
                this.root.classList.remove(this._mod('open'));
                this.closer.classList.remove('ec-icon-minus');
                this.closer.classList.add('ec-icon-plus');
            } else {
                this.root.classList.add(this._mod('open'));
                this.closer.classList.remove('ec-icon-plus');
                this.closer.classList.add('ec-icon-minus');
            }
        });
    }
}

register.registerClass('.mt5-footer-links', FooterLinks);
