import ValidableComponent from '../../../../../libs/components/validable-component';
import { dictionary } from '../../../../../libs/dictionary-provider';
import { register } from '../../../../../libs/register';

class Textarea extends ValidableComponent {
    constructor(name, root) {
        super(name, root);

        this.input = this._dEl('input');
        this.optional = this._dElMod('label', 'optional');
        this.error = this._dEl('error');

        this.showOptional(!this.input.required);
        if (this.input.classList.contains(this._elMod('input', 'newlineDisabled'))) {
            this._disableNewLine();
        }

        this._addEventListeners();
    }

    _getInput() {
        return this.input;
    }

    _addEventListeners() {

        ['keyup', 'change', 'input'].forEach((e) => {
            this.input.addEventListener(e, () => {
                this._checkState();
                this._changedInput();
            });
        });

        this.input.addEventListener('focusout', async () => {
            if (this.input.required && this.input.value == '') {
                if (!this.root.classList.contains(this._mod('errorState'))) {
                    this.trackFormError(dictionary.getFEMessage('requiredField'));
                }
                this.setState('error');
                await this._requireField();
            }
            if (this.input.value !== '' && this.root.classList.contains(this._mod('errorState'))) {
                this.trackFormError(this.errorText);
            }
        });
        
        this.root.addEventListener(
            'invalid',
            () => {
                if (this.input.required && this.input.value == '') {
                    this.setState('error');
                    this._requireField();
                }
            },
            true
        );
    }

    async _requireField() {
        this.error.innerText = this.setErrorText(dictionary.getFEMessage('requiredField'));
    }

    _disableNewLine() {
        /* disable enter keydown */
        this.input.addEventListener('keydown', (event) => {
            if (event.keyCode == 13) event.preventDefault();
        });
    }

    showOptional(show = true) {
        this.optional.style.display = show ? 'inline' : 'none';
    }

    getLabel() {
        return this.input.placeholder;
    }

    getName() {
        return this.input.name;
    }

    getValue() {
        return this.input.value;
    }

    isValid() {
        return !this.input.required || (this.input.required && this.input.value);
    }

    reset() {
        this.input.value = '';
        this.setState('');
    }

    /* override */
    setErrorText(errorText) {
        super.setErrorText(errorText);
        if (typeof errorText !== 'string' || errorText === '') return;
        this.error.innerText = errorText;
    }
}

register.registerClass('.mt18-textarea', Textarea);
