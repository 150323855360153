import WizardComponent from '../../../../../../libs/components/wizard-component';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { formToJSON, jsonToForm } from '../../../../../../libs/form-to-json';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { Store } from '../../../templates/mt3-store/script';
import { emptyElement } from '../../../../../../libs/utils';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { apiProvider } from '../../../../../../libs/api-provider';

class RegisterBimodal extends WizardComponent {
    constructor(name, root) {
        super(name, root);

        this.id = this.root.getAttribute('id');
        this.error = this._dEl('error');
        this.errorContent = this._dEl('error-content');
        if (this.error.dataset.recoverPwd) this.recoverPwd = JSON.parse(this.error.dataset.recoverPwd);
        this.backButton = this._dEl('backButton');
        this.userDataWrapper = this._dEl('userDataWrapper');
        this.form = this._dEl('form');
        this.userDataRecap = this._dEl('userDataRecapWrapper');
        this.userRecapEmail = this._dEl('recapEmail');
        this.userRecapPassword = this._dEl('recapPassword');
        this.userRecapPasswordCnt = this._dEl('recapPasswordCnt');
        this.userDataModify = this._dEl('editPersonalData');

        this.email = this._dEl('email');
        this.emailObj = register.getClass(this.email);
        this.emailRepeat = this._dEl('emailConfirm');
        this.emailRepeatObj = register.getClass(this.emailRepeat);
        this.emailMismatch = this._dEl('emailMismatch');

        this.password = this._dEl('password');
        this.passwObj = register.getClass(this.password);
        this.passwordRepeat = this._dEl('confirmPassword');
        this.passwRepeatObj = register.getClass(this.passwordRepeat);
        this.passwordRules = this._dEl('passwordRules');
        this.passwordState = this._dEl('passwordState');
        this.passwordStateInfo = this.passwordState.querySelector('.info');
        this.passwordStateOk = this.passwordState.querySelector('.ok');
        this.passwordStateError = this.passwordState.querySelector('.error');
        this.passwordMismatch = this._dEl('passwordMismatch');

        this.next = this._dEl('nextButton');

        this.refPdvButton = this._dEl('referencePdvButton');
        this.pdvSelect = this._dEl('pdvSelect');
        this.referencePdvTitle = this._dEl('referencePdvTitle');
        this.referencePdvText = this._dEl('referencePdvText');
        this.changePdvButton = this._dEl('changePdvButton');

        this.storeWrapper = this._dEl('storeWrapper');
        this.storeWrapperTitle = this._dEl('storeWrapper-title');
        this.storeWrapperAddress = this._dEl('storeWrapper-address');
        this.storeWrapperDistance = this._dEl('storeWrapper-distance');
        this.storeNotEnabledWrapper = this._dEl('storeNotEnabledWrapper');
        this.refPdvButton = this._dEl('referencePdvButton');
        this.submit = this._dEl('submit');
        this.userEmptyObj = {};

        //this._checkForm();
        this._addEventListeners();
        this._addStoreListeners();
    }

    /* override */
    open() {
        super.open();
        const ecRegistrationData = storeManager.get('ecRegistrationData');
        const pointOfServiceId = ecRegistrationData?.pointOfServiceId;
        const cooperativeId = ecRegistrationData?.cooperativeId;
        const selectedPdv = storeManager.get('selectedPdv');
        this.userInfo = null;
        if (pointOfServiceId && cooperativeId) {
            /* case pdv selected on ecommerce (registration flow from ecommerce) */
            this.userInfo = {
                coop: cooperativeId,
                store: pointOfServiceId,
            };
        } else if (selectedPdv) {
            /* case pdv selected together with type of service (registration flow from ecommerce) */
            this.userInfo = {
                coop: selectedPdv.codiceCooperativa,
                store: selectedPdv.anacanId,
            };
        }
        this._setValidationContexts();
        this._trackRegistrationFunnel(TRACKABLE_EVENT.pageview, FUNNEL_STEP.userAndPswChoice, this.userInfo);
    }

    _addEventListeners() {
        this.email.addEventListener('ecInputChanged', () => {
            if (!this.emailObj?.isValid()) {
                this._disableSubmit();
            }
            if (this.emailObj?.getValue() !== '' && this.emailRepeatObj?.getValue() !== '') {
                this._checkEmailRepeat();
            }
        });
        this.emailRepeat.addEventListener('ecInputChanged', () => {
            this._checkEmailRepeat();
        });
        this.password.addEventListener('click', () => {
            this.passwordRules.classList.remove(this._elMod('passwordRules', 'hidden'));
        });
        this.password.addEventListener('ecInputChanged', () => {
            this._checkShowPasswordRules();
            if (!this.passwObj?.isValid()) {
                this._disableSubmit();
            }
            if (this.passwObj?.getValue() !== '' && this.passwRepeatObj?.getValue() !== '') {
                this._checkPasswordRepeat();
            }
        });
        this.passwordRepeat.addEventListener('ecInputChanged', () => {
            this._checkPasswordRepeat();
        });
        this.next.addEventListener('click', () => {
            if (this.form.checkValidity()) {
                this._checkUserIfRegistered();
            } else {
                this._disableSubmit();
            }
        });
        this.userDataModify.addEventListener('click', () => {
            this.userDataRecap.classList?.add(this._elMod('userDataRecapWrapper', 'hidden'));
            this.form.classList?.remove(this._elMod('form', 'hidden'));
            this.userDataWrapper.classList?.remove(this._elMod('userDataWrapper', 'showRecap'));
            this.referencePdvText.classList?.add(this._elMod('referencePdvText', 'hidden'));
            this.changePdvButton.classList?.add(this._elMod('changePdvButton', 'hidden'));
            this.refPdvButton.classList?.add(this._elMod('referencePdvButton', 'hidden'));
            this.next.classList?.remove(this._elMod('nextButton', 'hidden'));
            setTimeout(() => {
                this.storeWrapper.classList.remove(this._elMod('storeWrapper', 'show'));
                this.refPdvButton.classList.remove(this._elMod('referencePdvButton', 'hide'));
                this.changePdvButton.classList.remove(this._elMod('changePdvButton', 'show'));
                if (this.submit.classList.contains(this._elMod('submit', 'show'))) {
                    this.submit.classList.remove(this._elMod('submit', 'show'));
                }
                this.pdvSelect.classList?.add(this._elMod('pdvSelect', 'disabled'));
                this.referencePdvTitle.classList?.add(this._elMod('referencePdvTitle', 'disabled'));
                this.pdvSelect.classList?.remove(this._elMod('pdvSelect', 'withBackground'));
            }, 500);
        });
        this.submit.addEventListener('click', (event) => {
            event.preventDefault();
            this._continueRegistration();
        });

        this.form.addEventListener('submit', (event) => {
            event.preventDefault();
        });

        //open modal select pdv
        this.refPdvButton.addEventListener('click', () => {
            flowManager.startFlow({
                flowName: 'select-pdv-extended',
                flowSteps: [{ name: 'select-pdv-extended' }],
            });
        });

        this.changePdvButton.addEventListener('click', () => {
            flowManager.startFlow({
                flowName: 'select-pdv-extended',
                flowSteps: [{ name: 'select-pdv-extended' }],
            });
        });

        /* form errors */
        this.form.addEventListener('ecFormError', (event) => {
            event.preventDefault();
            if (!event.data.errorField) return;
            trackEvent(
                TRACKABLE_EVENT.formError,
                FUNNEL_NAME.registration,
                FUNNEL_STEP.userAndPswChoice,
                null,
                null,
                null,
                {
                    errorField: event.data.errorField,
                    errorText: event.data.errorText,
                }
            );
            event.stopPropagation();
        });
        this.backButton.addEventListener('click', () => {
            this.userDataModify.click();
            this._disableSubmit();
            storeManager.emit('userData', this.userEmptyObj);
            this.passwObj.reset();
            this.passwRepeatObj.reset();
            this.passwordRules.classList.add(this._elMod('passwordRules', 'hidden'));
            this.passwordStateInfo.classList.add('active');
            this.passwordStateOk.classList.remove('active');
            this.passwordStateError.classList.remove('active');
        });
    }

    _addStoreListeners() {
        storeManager.on('userData', async (path, data) => {
            if (data.socialRegistration) {
                jsonToForm(this.form, data);
                /* lock email field */
                if (!this.email.classList.contains(this._elMod('email', 'locked')))
                    this.email.classList.add(this._elMod('email', 'locked'));
                this.email.querySelector('input').setAttribute('tabindex', '-1');

                // hide repeate mail
                if (!this.emailRepeat.classList.contains(this._elMod('emailConfirm', 'hidden')))
                    this.emailRepeat.classList.add(this._elMod('emailConfirm', 'hidden'));
                this.emailRepeat.querySelector('input').required = false;

                /* unset required password and hide it */
                if (!this.password.classList.contains(this._elMod('password', 'hidden')))
                    this.password.classList.add(this._elMod('password', 'hidden'));
                this.password.querySelector('input').required = false;

                if (!this.passwordRepeat.classList.contains(this._elMod('confirmPassword', 'hidden')))
                    this.passwordRepeat.classList.add(this._elMod('confirmPassword', 'hidden'));
                this.passwordRepeat.querySelector('input').required = false;

                // hide password recapt
                this.userRecapPasswordCnt.style.display = 'none';

                this._enableSubmit();
            } else {
                jsonToForm(this.form, data);
            }

            //this._checkForm();
        });

        storeManager.on('noCardUserData', async (path, data) => {
            this.userEmptyObj = data;
        });

        storeManager.on('selectedPdv', (path, data) => {
            this._addPdv(data);
        });
    }

    _setValidationContexts() {
        this.passwRepeatObj.setValidationContext({
            pswObj: this.passwObj,
        });
        this.emailRepeatObj.setValidationContext({
            emailObj: this.emailObj,
        });
    }

    async _addPdv(st) {
        emptyElement(this.storeWrapperTitle);
        emptyElement(this.storeWrapperAddress);
        emptyElement(this.storeWrapperDistance);
        const data = Store.getStoreData(this.id + '-' + Math.floor(Math.random() * 100000), `${this.name}__store`, st);
        this.storeWrapperTitle.innerHTML = data.title;
        this.storeWrapperAddress.innerHTML = data.address;
        this.storeWrapperDistance.innerHTML = data.distance;

        if (
            !data.cartaDematerializzata &&
            this.storeNotEnabledWrapper.classList.contains(this._elMod('storeNotEnabledWrapper', 'hidden'))
        ) {
            this.storeNotEnabledWrapper.classList.remove(this._elMod('storeNotEnabledWrapper', 'hidden'));
        } else if (
            data.cartaDematerializzata &&
            !this.storeNotEnabledWrapper.classList.contains(this._elMod('storeNotEnabledWrapper', 'hidden'))
        ) {
            this.storeNotEnabledWrapper.classList.add(this._elMod('storeNotEnabledWrapper', 'hidden'));
        }
        if (this.userDataWrapper.classList.contains(this._elMod('userDataWrapper', 'showRecap'))) {
            this.refPdvButton.classList.add(this._elMod('referencePdvButton', 'hidden'));
            this.storeWrapper.classList.add(this._elMod('storeWrapper', 'show'));
            this.changePdvButton.classList.add(this._elMod('changePdvButton', 'show'));
            this.submit.classList.add(this._elMod('submit', 'show'));
        }
    }

    async _continueRegistration() {
        // verify pdv is selected
        if (!this.form.checkValidity() || !storeManager.get('selectedPdv')?.anacanId) {
            const first = this.form.querySelector('input:invalid');
            if (first) {
                setTimeout(() => {
                    first.scrollIntoView({
                        block: 'center',
                    });
                }, 600);
            }
            return;
        }

        const data = formToJSON(this.form);

        /// VERIFY CONAD CARD EMAIL IF PRESENT
        let conadCard = false;
        let updatableMail = false;

        const codiceCarta = storeManager.get('userData')?.codiceCarta;
        if (codiceCarta) {
            try {
                openLoader('main');
                const verifyData = {
                    email: data.email,
                    codiceCarta: codiceCarta,
                    processo: 'R', // change mail process
                };
                const result = await apiProvider.verifyConadCard(verifyData);
                conadCard = result.conadCard == 'S';
                updatableMail = result.mailAggiornabile == 'S';
            } catch (error) {
                // check if we can continue
                let continueReg = false;
                if (error?.codice) {
                    switch (error.codice) {
                        case 'IS_ERR:EMAIL_GIA_PRESENTE_SU_CLIENTE':
                        case 'IS_ERR:CLIENTE_NON_AGGIORNABILE':
                            continueReg = true;
                    }
                }

                // if not block process.
                if (!continueReg) {
                    console.warn(error);

                    // show error popup if something goes wrong
                    flowManager.startFlow({
                        flowName: 'generic-error',
                        flowSteps: [{ name: 'generic-error' }],
                        initialData: {
                            errorMessage: error?.errore || dictionary.get('Something goes wrong'),
                        },
                    });
                    return;
                }
            } finally {
                closeLoader('main');
            }
        }

        // IF CONAD CARD AND MAIL UPDATABLE, SHOW GCC STEP
        let updateGccMail = null;
        if (conadCard && updatableMail) {
            const flowData = await flowManager.startAsyncFlow({
                flowName: 'checkGccMailRegistration',
                flowSteps: [{ name: 'gcc-check-email' }],
            });
            updateGccMail = flowData.updateGccMail;
        }

        storeManager.emit('userData', { ...storeManager.get('userData'), ...data, updateGccMail });
        flowManager.next('privacy');
    }

    _checkShowPasswordRules() {
        if (this.passwObj.getValue()) {
            this.passwordRules.classList.remove(this._elMod('passwordRules', 'hidden'));
        } else {
            this.passwordRules.classList.add(this._elMod('passwordRules', 'hidden'));
        }
        if (this.passwObj.isValid()) {
            this.passwordStateInfo.classList.remove('active');
            this.passwordStateOk.classList.add('active');
            this.passwordStateError.classList.remove('active');
        } else {
            this.passwordStateInfo.classList.remove('active');
            this.passwordStateOk.classList.remove('active');
            this.passwordStateError.classList.add('active');
        }
    }

    _checkPasswordRepeat() {
        if (!this.password || !this.passwordRules || !this.passwordState) return;
        const passwordRepeatError = this.passwordRepeat?.querySelector('.mt8-textfield__error');
        if (
            this.passwObj?.getValue() !== '' &&
            this.passwRepeatObj?.getValue() !== '' &&
            this.passwRepeatObj?.getValue() !== this.passwObj.getValue()
        ) {
            this.passwordMismatch?.classList.remove(this._elMod('passwordMismatch', 'hidden'));
            this.passwRepeatObj?.setState('error');
            if (passwordRepeatError) passwordRepeatError.style.display = 'none';
            this._disableSubmit();
        } else {
            passwordRepeatError?.removeAttribute('style');
            if (!this.passwordMismatch?.classList.contains(this._elMod('passwordMismatch', 'hidden')))
                this.passwordMismatch?.classList.add(this._elMod('passwordMismatch', 'hidden'));
            if (
                this.passwRepeatObj?.getValue() !== '' &&
                this.passwRepeatObj?.getValue() === this.passwObj?.getValue()
            ) {
                this.passwRepeatObj?.setState('valid');

                if(this._lastCheckEmailAndPasswordFieldsPopulation()) {
                    this._enableSubmit();
                }
            }
        }
    }

    _checkEmailRepeat() {
        if (!this.email) return;

        const emailRepeatError = this.emailRepeat?.querySelector('.mt8-textfield__error');

        if (
            this.emailObj?.getValue() !== '' &&
            this.emailRepeatObj?.getValue() !== '' &&
            this.emailRepeatObj?.getValue() !== this.emailObj.getValue()
        ) {
            this.emailMismatch?.classList.remove(this._elMod('emailMismatch', 'hidden'));
            this.emailRepeatObj?.setState('error');
            if (emailRepeatError) emailRepeatError.style.display = 'none';
            this._disableSubmit();
        } else {
            if (!this.emailMismatch?.classList.contains(this._elMod('emailMismatch', 'hidden')))
                this.emailMismatch?.classList.add(this._elMod('emailMismatch', 'hidden'));
            if (
                this.emailRepeatObj?.getValue() !== '' &&
                this.emailRepeatObj?.getValue() === this.emailObj?.getValue()
            ) {
                this.emailRepeatObj?.setState('valid');
                if(this._lastCheckEmailAndPasswordFieldsPopulation()) {
                    this._enableSubmit();
                }
            }
        }
    }

    _lastCheckEmailAndPasswordFieldsPopulation() {
        if (
            this.emailRepeatObj?.getValue() !== '' &&
            this.emailRepeatObj?.getValue() === this.emailObj?.getValue() &&
            this.passwRepeatObj?.getValue() !== '' &&
            this.passwRepeatObj?.getValue() === this.passwObj?.getValue()
        ) {
            return true;
        }

        return false;
    }

    _enableSubmit() {
        this.next?.classList?.remove(this._elMod('nextButton', 'disabled'));
    }

    _disableSubmit() {
        this.next?.classList?.add(this._elMod('nextButton', 'disabled'));
    }

    async _checkUserIfRegistered() {
        if (!this.next.classList.contains(this._elMod('nextButton', 'disabled'))) {
            var checkUser = null;
            try {
                checkUser = await apiProvider.checkUserIfRegistered({
                    email: this.emailObj.getValue(),
                });
            } catch (error) {
                checkUser = error.codice;
                console.warn('Error during check user');
            }

            if (checkUser == 'OK') {
                this.error.classList.add(this._elMod('error', 'hidden'));
                this.userRecapEmail.innerHTML = this.emailObj.input.value;
                var passwordHidden = '';
                for (var ct = 0; ct < this.passwObj.input.value.length; ct++) {
                    passwordHidden = passwordHidden + '*';
                }
                this.userRecapPassword.innerHTML = passwordHidden;
                this.pdvSelect.classList?.remove(this._elMod('pdvSelect', 'disabled'));
                this.referencePdvTitle.classList?.remove(this._elMod('referencePdvTitle', 'disabled'));
                this.referencePdvText.classList?.remove(this._elMod('referencePdvText', 'hidden'));
                this.changePdvButton.classList?.remove(this._elMod('changePdvButton', 'hidden'));
                this.refPdvButton.classList?.remove(this._elMod('referencePdvButton', 'hidden'));
                this.pdvSelect.classList?.add(this._elMod('pdvSelect', 'withBackground'));
                this.userDataWrapper.classList?.add(this._elMod('userDataWrapper', 'showRecap'));
                this.form.classList?.add(this._elMod('form', 'hidden'));
                this.next.classList?.add(this._elMod('nextButton', 'hidden'));
                setTimeout(() => {
                    this.userDataRecap.classList?.remove(this._elMod('userDataRecapWrapper', 'hidden'));
                }, 500);
            } else {
                if (checkUser == 'BACKEND:RESPONSE_ERROR') {
                    this.errorContent.innerHTML = this.root.dataset.genericError;
                } else {
                    const errorInfo = `<a href='${this.recoverPwd.href}'>${this.recoverPwd.label}</a> ${dictionary.get(
                        'or register with different email address'
                    )}`;
                    this.errorContent.innerHTML = `${await (dictionary.getFEMessage(
                        'IS_ERR:INDIRIZZO_EMAIL_GIA_REGISTRATO'
                    ) || 'Indirizzo email già registrato su Conad.it')}. ${errorInfo}`;
                }
                this.error.classList.remove(this._elMod('error', 'hidden'));
                window.scrollTo(0, 0);
            }
        }
    }

    _trackRegistrationFunnel(event, funnelStep, userInfoObj = null, formDataObj = null) {
        const typeOfService = storeManager.get('ecRegistrationData')?.typeOfService;
        const originEcommerce = storeManager.get('ecRegistrationData')?.originEcommerce;
        const userInfo =
            userInfoObj && userInfoObj.coop && userInfoObj.store
                ? { coop: userInfoObj.coop, store: userInfoObj.store }
                : null;
        const formData = formDataObj && formDataObj.errorFields ? { errorFields: formDataObj.errorFields } : null;
        if (storeManager.get('currentFlow').includes('conad-register')) {
            if (originEcommerce && typeOfService) {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, typeOfService, null, userInfo, formData);
            } else {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, null, null, userInfo, formData);
            }
        }
    }
}

register.registerClass('.mw5-register-bimodal', RegisterBimodal);
