import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';
import PerfectScrollbar from 'perfect-scrollbar';

class MessagePopup extends Component {
    constructor(name, root) {
        super(name, root);
        this.messageText = this._dEl('text');
        const scrollbarOptions = {
            swipeEasing: true,
            suppressScrollX: true
        };
        this.ps = new PerfectScrollbar(this.messageText, scrollbarOptions);
    }
}

register.registerClass('.mt9-message-popup', MessagePopup);