import Component from '../../../../../../libs/components/component';
import { register } from '../../../../../../libs/register';
import { apiProvider } from '../../../../../../libs/api-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import { bolliniPdvManager } from '../../../../../../libs/bollini-pdv-manager';

class Bollini extends Component {
    constructor(name, root) {
        super(name, root);

        this.operationsTable = this._dEl('table');
        this.donateBolliniButton = this._dEl('donateStart');
        this.currentSize = 0;
        this.downloadLength = parseInt(this.operationsTable?.dataset.downloadLength);
        this.operationsLoadMore = this._dEl('loadMore');
        this.noOperations = this._dEl('noOperations');
        this.bolliniCount = this._dEl('count');
        this.userBalance = '0';
        this.temporaryBolliniTransferBlockAmount = this.root.dataset.temporaryBolliniTransferBlockAmount;
        this.movements;

        this._addEventListeners();
        this._init();
    }

    async _init() {
        openLoader('main');

        try {
            // load operations
            await this._downloadOperation();
            if (this.movements) {
                this._appendOperations(this.movements);
            }

            // load bollini
            const bollini = await bolliniPdvManager.getCallBalanceBollini();
            this.userBalance = bollini.saldoCumulato - bollini.saldoSpeso;
            this.bolliniCount.innerHTML = this.userBalance;
            document.querySelector('.mt8-textfield__permanentText').innerHTML = '/ ' + this.userBalance;

        } catch (error) {
            console.error('error during bollini transactions retrieving process - ' + error);
        }

        closeLoader('main');
    }

    _addEventListeners() {

        // start donate bollini flow
        this.donateBolliniButton?.addEventListener('click', (event) => {
            event.preventDefault();

            // check if some bollini were already donate in a defined amount
            let bolliniAlreadyDonated = false;
            let temporaryBolliniTransferBlockAmountData = JSON.parse(localStorage.getItem("temporaryBolliniTransferBlockAmount"));
            if (temporaryBolliniTransferBlockAmountData?.date != null) {
                let donateBolliniDate = new Date(temporaryBolliniTransferBlockAmountData.date);
                let dateDiff = new Date() - donateBolliniDate;
                if (dateDiff < (this.temporaryBolliniTransferBlockAmount * 60 * 1000)) {
                    bolliniAlreadyDonated = true;
                    console.log("Bollini already donated");
                }
            }

            if (bolliniAlreadyDonated) {
                flowManager.startFlow({
                    flowName: 'bollini-temporary-block',
                    flowSteps: [
                        { name: 'bollini-temporary-block', disableBack: true }
                    ],
                    initialData: {}
                });
            } else {
                flowManager.startFlow({
                    flowName: 'donate-bollini',
                    flowSteps: [
                        { name: 'donate-bollini', disableBack: true },
                        { name: 'bollini-donate-thank-you', disableBack: true },
                    ],
                    initialData: {
                        bollini: this.userBalance,
                    },
                });
            }
        });

        // load more
        this.operationsLoadMore?.addEventListener('click', (event) => {
            event.preventDefault();
            openLoader('main');
            this._showHideAdditionalOperations();
            setTimeout(() => {
                closeLoader('main');
            }, 500);
        });
    }

    async _downloadOperation() {
        const data = {
            codiceCollection: this.root.dataset.codiceCollection,
        };
        try {
            this.movements = await apiProvider.getCardMovementsWithCollection(data);
        } catch (error) {
            console.error('error during call service for card movements');
        }
    }

    _appendOperations(operations) {
        const class_hideLoadMore = this._elMod('loadMore', 'hidden');
        let classList_loadMore = this.operationsLoadMore?.classList;
        const class_hideContent = this._elMod('table', 'hidden');
        let classList_content = this.operationsTable?.classList;
        const class_showNoOperations = this._elMod('noOperations', 'show');
        let classList_noOperations = this.noOperations.classList;

        if (this.currentSize + operations.length <= 0) {
            classList_content.add(class_hideContent);
            classList_noOperations.add(class_showNoOperations);
            classList_loadMore.add(class_hideLoadMore);
        } else {
            classList_content.remove(class_hideContent);
            classList_noOperations.remove(class_showNoOperations);

            const tableBody = this.operationsTable.querySelector('tbody');
            for (let operation of operations) {
                let row = tableBody.insertRow();
                let userOperationDate = row.insertCell(0);
                let date = new Date(operation.dataEmissione);
                let mm = date.getMonth() + 1;
                let dd = date.getDate();
                let yyyy = date.getFullYear();
                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;
                userOperationDate.innerHTML = dd + '/' + mm + '/' + yyyy;

                let userHours = row.insertCell(1);
                userHours.innerHTML =
                    String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0');

                let userOperationType = row.insertCell(2);
                userOperationType.innerHTML = operation.causale;

                let userBolliniAction = row.insertCell(3);
                userBolliniAction.innerHTML = operation.punti;
                if (this.currentSize < this.downloadLength) {
                    this.currentSize++;
                } else {
                    row.classList.add(this._el('hiddenOperation'));
                }
            }

            if (this.currentSize < operations.length) {
                classList_loadMore.remove(class_hideLoadMore);
            }
        }
    }

    _showHideAdditionalOperations() {
        const class_hideLoadMore = this._elMod('loadMore', 'hidden');
        let classList_loadMore = this.operationsLoadMore?.classList;
        let hiddenOperations = this._dEl('hiddenOperation', true);
        let operationCount = 0;
        for (let operationEl of hiddenOperations) {
            if (operationCount < this.downloadLength) {
                operationEl.classList.remove(this._el('hiddenOperation'));
                operationCount++;
            } else {
                this.currentSize += operationCount;
                break;
            }
        }
        if (operationCount >= hiddenOperations.length) {
            classList_loadMore.add(class_hideLoadMore);
        }
    }
}

register.registerClass('.mf19-bollini', Bollini);
