import Component from '../../../../../../libs/components/component';
import { apiProvider } from '../../../../../../libs/api-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { register } from '../../../../../../libs/register';
import { bolliniPdvManager } from '../../../../../../libs/bollini-pdv-manager';
import { getCookieByName, writeCookieWithName, nextTick } from '../../../../../../libs/utils';
import { getTrackingManager } from '../../../../../../libs/tracking-manager';

class Dashboard extends Component {
    constructor(name, root) {
        super(name, root);
        this.trackingManager = getTrackingManager();
        this.noInsiemeCardCta = this._dEl('noInsiemeCardCta');
        this.noInsiemePlusCardCta = this._dEl('noInsiemePlusCardCta');

        this.insiemeCardCouponsValue = this._dEl('insiemeCardCouponsValue');
        this.numberOfUnreadMessages = this._dEl('numberOfUnreadMessages');
        this.insiemeCardNotifsValue = this._dEl('insiemeCardNotifsValue');
        this.bolliniInfoButton = this._dEl('bollini-info-icon');
        this.bolliniCountContainer = this._dEl('bollini-count');
        this.bolliniEl = this._dEl('bollini');
        this.refreshBanner = this._dEl('refreshCampaignWrapper');
        this.popUpBackdrop = this._dEl('refreshCampaignPopUpBackdrop');
        this.pupUpElement = this._dEl('refreshCampaignPopUp');
        this.pupUpElementCloseIcon = this._dEl('refreshCampaignPopUpClose');
        this.ctaGoTo = this._dEl('refreshCampaignCta');

        this._addEventListeners();
        this._getDashboardCounts();
        this._checkBolliniPdvs();
        if (this.refreshBanner) this._checkRefreshIfShow();
    }

    async _getDashboardCounts() {
        const dashboardCounts = await apiProvider.getDashboardCounts();
        if (dashboardCounts != null) {
            this.insiemeCardCouponsValue.innerHTML = dashboardCounts.couponsCount;
            this.numberOfUnreadMessages.innerHTML = dashboardCounts.notificationsCount;
        }

        if ((dashboardCounts != null && dashboardCounts.notificationsCount == 0) || dashboardCounts == null) {
            this.insiemeCardNotifsValue.classList.add('mf8-dashboard__hidden');
        }
    }

    async _checkBolliniPdvs() {
        if (this.bolliniCountContainer) {
            const isEnabled = await bolliniPdvManager.isEnabled(window.initialStore.userData.pdvPreferito);
            if (!isEnabled) {
                this.bolliniEl.classList.add(this._elMod('bollini', 'hide'));
                setTimeout(() => {
                    this.bolliniEl.remove();
                }, 650);
            } else {
                try {
                    const bollini = await bolliniPdvManager.getCallBalanceBollini();
                    this.bolliniCountContainer.innerText = bollini.saldoCumulato - bollini.saldoSpeso;
                    this.bolliniEl.classList.add(this._elMod('bollini', 'show'));
                } catch (error) {
                    this.bolliniEl.classList.add(this._elMod('bollini', 'hide'));
                    this.bolliniEl.remove();
                }
            }
        }
    }

    _addEventListeners() {
        this.noInsiemeCardCta?.addEventListener('click', (event) => {
            event.preventDefault();
            this._requestInsiemeCard();
        });
        this.noInsiemePlusCardCta?.addEventListener('click', (event) => {
            event.preventDefault();
            flowManager.startFlow({
                flowName: 'request-conad-card',
                flowSteps: [{ name: 'request-conad-card' }],
            });
        });
        this.bolliniInfoButton?.addEventListener('click', (event) => {
            event.preventDefault();
            flowManager.startFlow({
                flowName: 'bollini-info',
                flowSteps: [{ name: 'bollini-info' }],
            });
        });
        this.popUpBackdrop?.addEventListener('click', () => {
            this.pupUpElement.classList.add(this._el('refreshCampaignPopUpHidden'));
            this.popUpBackdrop.classList.add(this._el('refreshCampaignPopUpHidden'));

            setTimeout(() => {
                this.pupUpElement.classList.add('hidden');
                this.popUpBackdrop.classList.add('hidden');
            }, 600);
        });
        this.pupUpElementCloseIcon?.addEventListener('click', async (event) => {
            await this.trackingManager.track(event.target, {
                event: 'uscitaPopupRefresh',
                funnel: {
                    nomeFunnel: 'Conad Refresh',
                    stepFunnel: 'Pop up partecipazione concorso - Esci',
                },
            });
            this.pupUpElement.classList.add(this._el('refreshCampaignPopUpHidden'));
            this.popUpBackdrop.classList.add(this._el('refreshCampaignPopUpHidden'));

            setTimeout(() => {
                this.pupUpElement.classList.add('hidden');
                this.popUpBackdrop.classList.add('hidden');
            }, 600);
        });
        this.ctaGoTo?.addEventListener('click', (event) => {
            event.preventDefault();
            let urlToGo = new URL(this.ctaGoTo.href);
            urlToGo.searchParams.set('tab', 'personalData');
            window.location = urlToGo;
        });
    }

    _requestInsiemeCard() {
        flowManager.startFlow({
            flowName: 'request-card',
            flowSteps: [
                { name: 'request-card-step1', stepIndex: 1, disableBack: true },
                { name: 'request-card-step2', stepIndex: 2, disableBack: true },
                { name: 'card-added', disableBack: true },
            ],
            options: {
                requestCardModality: 'associate-dm',
            },
        });
    }

    async _checkRefreshIfShow() {
        await nextTick();
        await this.trackingManager.track(this.refreshBanner, {
            event: 'pageview',
            funnel: {
                nomeFunnel: 'Conad Refresh',
                stepFunnel: 'Pop up partecipazione concorso',
            },
        });

        var cookieRefresh = getCookieByName('ecRefreshBanner');
        if (cookieRefresh) return;

        this.pupUpElement.classList.remove('hidden');
        this.popUpBackdrop.classList.remove('hidden');

        var domainParts = location.hostname.split('.');
        var domain = location.hostname;
        if (domainParts.length >= 2) {
            domain = '.' + domainParts.slice(-2).join('.');
        }
        var currentDate = new Date();
        var expiringDate = new Date(currentDate.getTime() + 172800000);
        writeCookieWithName('ecRefreshBanner', 'true', domain, expiringDate.toUTCString());
    }
}

register.registerClass('.mf8-dashboard', Dashboard);
