import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../libs/tracking-manager-old';
import { copyText } from '../../../../../libs/utils';

class CouponInstructions extends Component {
    constructor(name, root) {
        super(name, root);
        this.copyTriggerer = this._dEl('onlineShoppingCode');
        this.code = this._dEl('instructionsCouponCode');
        this.SHOW_TOOLTIP = this._mod('showTooltip');
        this._addEventListeners();
    }

    _addEventListeners() {
        //click on shopping code
        if (this._dEl('instructionsOnlineShoppingCode'))
            this._addListener(
                'click',
                (event) => {
                    event.preventDefault();
                    this._copy();
                },
                'instructionsOnlineShoppingCode'
            );

        //click on travel code
        if (this._dEl('instructionsTravelCode'))
            this._addListener(
                'click',
                (event) => {
                    event.preventDefault();
                    this._copy();
                },
                'instructionsTravelCode'
            );
    }

    _copy() {
        const code = this.code.textContent;
        copyText(code);
        trackEvent(
            TRACKABLE_EVENT.copyCouponCode,
            FUNNEL_NAME.couponsWallet,
            FUNNEL_STEP.copyCouponCode,
            null,
            null,
            null,
            null,
            null,
            null,
            code
        );
        this.root.classList.add(this.SHOW_TOOLTIP);
        setTimeout(() => this.root.classList.remove(this.SHOW_TOOLTIP), 6300);
    }
}

register.registerClass('.mt14-coupon-instructions', CouponInstructions);
