class Viewport {
    constructor() {
        this.content = {
            default: 'width=device-width, initial-scale=1, maximum-scale=5.0',
            fixed: 'width=device-width, initial-scale=1, maximum-scale=1.0',
            unscalable: 'width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no'
        };
        this._initViewport();
    }

    _initViewport() {
        let meta = document.head.querySelector('meta[name="viewport"]');
        if (meta) {
            this.el = meta;
        } else {
            this.el = document.createElement('meta');
            this.el.setAttribute('name', 'viewport');
            this.setViewport('default');
            document.head.appendChild(this.el);
        }
    }

    setViewport(key) {
        if (!this.el || (this.el && this.el.getAttribute('content') === this.content[key]))
            return;
        this.el.setAttribute('content', this.content[key] || this.content.default);
    }
}

const defaulViewport = new Viewport();
window.viewport = defaulViewport;
export const viewport = defaulViewport;
