import { apiProvider } from '../../../../../../libs/api-provider';
import WizardComponent from '../../../../../../libs/components/wizard-component';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { formToJSON } from '../../../../../../libs/form-to-json';
import { informativaManager } from '../../../../../../libs/informativa-manager';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { getCallback, isPositive, checkFormWithoutValidation } from '../../../../../../libs/utils';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';

class Privacy extends WizardComponent {
    constructor(name, root) {
        super(name, root);

        this.marketingRadio = this._dEl('marketingRadio');
        this.profilationRadio = this._dEl('profilationRadio');
        this.coopComunicationRadio = this._dEl('coopComunicationRadio');
        this.marketingComunicationPartnerRadio = this._dEl('marketingComunicationPartnerRadio');
        this.boxMarketingPartnerBox = this._dEl('boxMarketingPartner');

        this.marketingComunicationPartnerRadioClz = register.getClass(this.marketingComunicationPartnerRadio);

        this.marketingRadioInputs = Array.from(this.marketingRadio.querySelectorAll('.mt19-radio__input'));
        this.profilationRadioInputs = Array.from(this.profilationRadio.querySelectorAll('.mt19-radio__input'));
        this.coopComunicationRadioInputs = Array.from(
            this.coopComunicationRadio.querySelectorAll('.mt19-radio__input')
        );
        this.marketingComunicationPartnerRadioInputs = Array.from(
            this.marketingComunicationPartnerRadio.querySelectorAll('.mt19-radio__input')
        );

        this.form = this._dEl('form');
        this.boxElsOfForm = this._dEl('box', true);
        this.submit = this._dEl('submit');
        this.currentPrivacy = null;
        this.subtitle = this._dEl('subtitle');
        this.subtitleHtml = this.subtitle.innerHTML;
        this.text = this._dEl('text');
        this.textHtml = this.text.innerHTML;
        this.error = this._dEl('error');
        if (this.error.dataset.recoverPwd) this.recoverPwd = JSON.parse(this.error.dataset.recoverPwd);
        this.ignoreForm = false;

        //this._checkForm();
        this._addEventListeners();
        this._addStoreListeners();
    }

    /* override */
    open() {
        super.open();
        const ecRegistrationData = storeManager.get('ecRegistrationData');
        const pointOfServiceId = ecRegistrationData?.pointOfServiceId;
        const cooperativeId = ecRegistrationData?.cooperativeId;
        const selectedPdv = storeManager.get('selectedPdv');
        const userData = storeManager.get('userData');

        /* set precompiled consent */
        if (userData.privacy1) {
            if (userData.privacy1 === 'S') {
                let marketingRadioSInput = this.marketingRadioInputs.filter(
                    (radio) => radio.hasAttribute('value') && radio.value === 'S'
                )[0];
                if (marketingRadioSInput) {
                    marketingRadioSInput.checked = true;
                }
            }
            if (userData.privacy1 === 'N') {
                let marketingRadioNInput = this.marketingRadioInputs.filter(
                    (radio) => radio.hasAttribute('value') && radio.value === 'N'
                )[0];
                if (marketingRadioNInput) {
                    marketingRadioNInput.checked = true;
                }
            }
        }
        if (userData.privacy2) {
            if (userData.privacy2 === 'S') {
                let profilationRadioSInput = this.profilationRadioInputs.filter(
                    (radio) => radio.hasAttribute('value') && radio.value === 'S'
                )[0];
                if (profilationRadioSInput) {
                    profilationRadioSInput.checked = true;
                }
            }
            if (userData.privacy2 === 'N') {
                let profilationRadioNInput = this.profilationRadioInputs.filter(
                    (radio) => radio.hasAttribute('value') && radio.value === 'N'
                )[0];
                if (profilationRadioNInput) {
                    profilationRadioNInput.checked = true;
                }
            }
        }
        if (userData.privacy3) {
            if (userData.privacy3 === 'S') {
                let coopComunicationRadioSInput = this.coopComunicationRadioInputs.filter(
                    (radio) => radio.hasAttribute('value') && radio.value === 'S'
                )[0];
                if (coopComunicationRadioSInput) {
                    coopComunicationRadioSInput.checked = true;
                }
            }
            if (userData.privacy3 === 'N') {
                let coopComunicationRadioNInput = this.coopComunicationRadioInputs.filter(
                    (radio) => radio.hasAttribute('value') && radio.value === 'N'
                )[0];
                if (coopComunicationRadioNInput) {
                    coopComunicationRadioNInput.checked = true;
                }
            }
        }
        if (userData.privacyTZ) {
            if (userData.privacyTZ === 'S') {
                let marketingComunicationPartnerRadioSInput = this.marketingComunicationPartnerRadioInputs.filter(
                    (radio) => radio.hasAttribute('value') && radio.value === 'S'
                )[0];
                if (marketingComunicationPartnerRadioSInput) {
                    marketingComunicationPartnerRadioSInput.checked = true;
                }
            }
            if (userData.privacyTZ === 'N') {
                let marketingComunicationPartnerRadioNInput = this.marketingComunicationPartnerRadioInputs.filter(
                    (radio) => radio.hasAttribute('value') && radio.value === 'N'
                )[0];
                if (marketingComunicationPartnerRadioNInput) {
                    marketingComunicationPartnerRadioNInput.checked = true;
                }
            }
        }

        this.userInfo = null;
        if (pointOfServiceId && cooperativeId) {
            /* case pdv selected on ecommerce (registration flow from ecommerce) */
            this.userInfo = {
                coop: pointOfServiceId,
                store: cooperativeId,
            };
        } else if (selectedPdv) {
            /* case pdv selected during registration flow */
            this.userInfo = {
                coop: selectedPdv.codiceCooperativa,
                store: selectedPdv.anacanId,
            };
        }
        this._trackRegistrationFunnel(TRACKABLE_EVENT.pageview, FUNNEL_STEP.consentsScreen, this.userInfo);
        this.ISError = null;
        if (!this.error.classList.contains(this._elMod('error', 'hidden')))
            this.error.classList.add(this._elMod('error', 'hidden'));

        if (userData && !userData.bimodalCard) {
            this.boxMarketingPartnerBox.classList.add(this._elMod('boxMarketingPartner', 'hidden'));
            this.marketingComunicationPartnerRadioClz.reset();
            this.marketingComunicationPartnerRadioClz.getInput().required = false;
        } else if (userData && userData.bimodalCard) {
            this.boxMarketingPartnerBox.classList.remove(this._elMod('boxMarketingPartner', 'hidden'));
            this.marketingComunicationPartnerRadioClz.reset();
            this.marketingComunicationPartnerRadioClz.getInput().required = true;
        }

        if (!this.submit.classList.contains(this._elMod('submit', 'disabled')) && !this.ignoreForm) {
            this.submit.classList.add(this._elMod('submit', 'disabled'));
        }
    }

    _addEventListeners() {
        this.form.addEventListener('ecInputChanged', (event) => {
            this._checkRadio(event.target.closest(this._el('box', true)), isPositive(event.data.value));
            //this._checkForm();
            if (checkFormWithoutValidation(this.form)) {
                this.submit.classList.remove(this._elMod('submit', 'disabled'));
            } else {
                this.submit.classList.add(this._elMod('submit', 'disabled'));
            }
        });
        this.form.addEventListener('submit', (event) => {
            event.preventDefault();
        });
        this.submit.addEventListener('click', (event) => {
            event.preventDefault();
            if (!this.ignoreForm && (!this.form.checkValidity() || !this._validRadios())) {
                const first = this.form.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }
            const userData = {
                ...storeManager.get('userData'),
                ...(this.ignoreForm ? {} : formToJSON(this.form)),
            };
            if (!userData.bimodalCard && !this.ignoreForm) {
                delete userData.privacyTZ;
            }
            storeManager.emit('userData', userData);
            this._trackRegistrationFunnel(TRACKABLE_EVENT.registerCta, FUNNEL_STEP.clickRegisterCta, this.userInfo);
            this._registerUser();
        });
    }

    _addStoreListeners() {
        storeManager.on('selectedPdv', async (path, data) => {
            const userData = storeManager.get('userData');
            const result = await informativaManager.getInformativa(userData, data);
            this.currentPrivacy = result;
            this._setInformativa();
        });

        storeManager.on('userData', async (path, data) => {
            const pdv = storeManager.get('selectedPdv');
            const result = await informativaManager.getInformativa(data, pdv);
            this.currentPrivacy = result;
            this._setInformativa();
        });

        storeManager.on('privacyWithFidelityCard', async () => {
            // show fidelity text
            this.root.classList.add(this._mod('fidelity'));
            // ignore form and enable submit
            this.ignoreForm = true;
            this.submit.classList.remove(this._elMod('submit', 'disabled'));
            //this.submit.classList.remove('invalid');
            //this.submit.removeAttribute('title');
        });
    }

    async _setInformativa() {
        const str = `<a href="${this.currentPrivacy.linkPdfInformativa}" title="informativa ${this.currentPrivacy.version}" target="_blank">${this.subtitle.dataset.linkLabel}</a>`;

        this.subtitle.innerHTML = this.subtitleHtml.replace('$linkInformativa', () => {
            return str;
        });
        if (!this.text) return;
        this.text.innerHTML = this.textHtml.replace('$linkInformativa', () => {
            return str;
        });
    }

    _checkRadio(box, valid) {
        const boxNotSelected = box.querySelector(this._el('boxNotSelected', true));
        if (!valid) {
            boxNotSelected.classList.add(this._elMod('boxNotSelected', 'show'));
        } else {
            boxNotSelected.classList.remove(this._elMod('boxNotSelected', 'show'));
        }
    }

    _validRadios() {
        return Array.from(this.form.querySelectorAll(`.mt19-radio`))
            .map((radio) => register.getClass(radio))
            .map((obj) => !!obj.isValid())
            .reduce((x, y) => x && y);
    }

    async _registerUser() {
        const userData = storeManager.get('userData');
        const requestCardData = storeManager.get('requestCardData');
        const selectedPdv = storeManager.get('selectedPdv');

        /* merge request card data in user data iff dmCard */
        let data = {};
        if (userData.dmCard && requestCardData && !requestCardData.invalid) {
            data = {
                ...userData,
                ...requestCardData,
            };
        } else {
            /* reset dmCard flag and request card data */
            userData.dmCard = false;
            storeManager.emit('requestCardData', {});
            data = {
                ...userData,
            };
        }

        let userId = null;
        try {
            openLoader('main');
            if (!userData.bimodalCard) {
                userId = await this._registerNoCardUser(data, selectedPdv);
            } else {
                userId = await this._registerCardUser(data, selectedPdv);
            }
        } catch (error) {
            this.ISError = error;
            console.warn('Cannot register user', error);
        } finally {
            closeLoader('main');
        }

        if (userId) {
            storeManager.emit('generatedUserId', { userId: userId });
            flowManager.next('thankyou');
        } else {
            if (this.ISError) {
                const errorInfo = `<a href='${this.recoverPwd.href}'>${this.recoverPwd.label}</a> ${dictionary.get(
                    'or insert a different email address'
                )}`;
                this.error.innerHTML = this.ISError?.codice
                    ? `${await (dictionary.getFEMessage(this.ISError.codice) || this.ISError.errore)} (${errorInfo})`
                    : this.root.dataset.genericError;
                this.error.classList.remove(this._elMod('error', 'hidden'));
            }
        }
    }

    async _registerNoCardUser(userData, selectedPdv) {
        const ecRegistrationData = storeManager.get('ecRegistrationData');

        const data = {
            ...userData,
            flagRichiestaCartaDematerializzata: userData.dmCard ? 'S' : 'N',
            fonteRegistrazione: ecRegistrationData && ecRegistrationData.originEcommerce ? 'E' : 'C',
            cb: getCallback(userData.dmCard ? 'demCard' : 'standard'),
            versioneInformativaPrivacy: this.currentPrivacy?.version,
            pdvAnacanId: selectedPdv.anacanId,
            anacanCooperativeCode: selectedPdv.codiceCooperativa,
        };
        const userId = (await userData.socialRegistration)
            ? apiProvider.registerSocialUserWithoutCard(data)
            : apiProvider.registerUserWithoutCard(data);
        return userId;
    }

    async _registerCardUser(userData, selectedPdv) {
        const ecRegistrationData = storeManager.get('ecRegistrationData');

        const data = {
            ...userData,
            fonteRegistrazione: ecRegistrationData && ecRegistrationData.originEcommerce ? 'E' : 'C',
            versioneInformativaPrivacy: this.currentPrivacy?.version,
            cb: getCallback('fisCard'),
            pdvAnacanId: selectedPdv.anacanId,
            anacanCooperativeCode: selectedPdv.codiceCooperativa,
        };
        const userId = (await userData.socialRegistration)
            ? apiProvider.registerSocialUserWithCard(data)
            : apiProvider.registerUserWithCard(data);
        return userId;
    }

    _trackRegistrationFunnel(event, funnelStep, userInfoObj = null) {
        const typeOfService = storeManager.get('ecRegistrationData')?.typeOfService;
        const originEcommerce = storeManager.get('ecRegistrationData')?.originEcommerce;
        const userInfo =
            userInfoObj && userInfoObj.coop && userInfoObj.store
                ? { coop: userInfoObj.coop, store: userInfoObj.store }
                : null;
        if (storeManager.get('currentFlow').includes('conad-register')) {
            if (originEcommerce && typeOfService) {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, typeOfService, null, userInfo);
            } else {
                trackEvent(event, FUNNEL_NAME.registration, funnelStep, null, null, userInfo);
            }
        }
    }
}

register.registerClass('.mw9-privacy', Privacy);
