import { apiProvider } from '../../../../../../libs/api-provider';
import ModalComponent from '../../../../../../libs/components/modal-component';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { formToJSON, jsonToForm } from '../../../../../../libs/form-to-json';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { openLoader, closeLoader } from '../../../templates/mt11-loader/script';

class DeliveryAddressModal extends ModalComponent {
    constructor(name, root) {
        super(name, root);

        this.form = this._dEl('form');
        this.deliveryAddress = this._dEl('deliveryAddress');
        this.note = this._dEl('note');
        this.alert = this._dEl('alert');
        this.submit = this._dEl('submit');
        this.remove = this._dEl('remove');
        this.wrapper = this._dEl('wrapper');
        this.addressData = {};

        this.addressName = this._dEl('addressName');

        //this._disableSubmit();
        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        this.deliveryAddress.addEventListener('ecInputChanged', (event) => {
            if (!event.data.valid) return;
            const address = event.data.value;
            if (!address || !address.types || !address.address_components) {
                return;
            }
            if (address.types.includes('street_address')) {
                /* revoke valid state from delivery address */
                this._setStateDeliveryAddress('');
                /* hide alert and make delivery notes optional */
                this._showAlert(false);
                this._setRequiredDeliveryNotes(false);
                this.notCompleted = false;
            } else {
                if (address.types.includes('route')) {
                    /* grant valid state to delivery address */
                    this._setStateDeliveryAddress('valid');
                    /* show alert and make delivery notes required */
                    this._showAlert(true);
                    this._setRequiredDeliveryNotes(true);
                    this.notCompleted = true;
                }
            }
        });
        this.deliveryAddress.addEventListener('cancelledAddress', () => {
            /* grant valid state to delivery address */
            this._setStateDeliveryAddress('valid');
            /* hide alert and make delivery notes optional */
            this._showAlert(false);
            this._setRequiredDeliveryNotes(false);
            /* reset notCompleted */
            this.notCompleted = true;
        });
        this.root.addEventListener('ecInputChanged', (event) => {
            //if user modifies telefono than otp check need to be done
            if (event.target.matches(this._el('telefono', true))) {
                this._dEl('cellphoneVerified').value = 'false';
                console.log('changed phone');
            }

            this._updateAddressData(event.data.value);
            /*!event.data.valid
                ? this._disableSubmit()
                : this._getFormValidity(this.form)
                ? this._enableSubmit()
                : this._disableSubmit();*/
            event.stopPropagation();
        });
        this.submit.addEventListener('click', (event) => {
            event.preventDefault();
            if (!this.form.checkValidity()) {
                const first = this.form.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }
            /*if (this._submitDisabled()) {
                return;
            }*/
            trackEvent(TRACKABLE_EVENT.saveAddress, FUNNEL_NAME.addressHandling, FUNNEL_STEP.saveDeliveryAddress);
            //this._disableSubmit();
            this._submitForm(this.form);
        });
        this.remove.addEventListener('click', (event) => {
            event.preventDefault();
            trackEvent(TRACKABLE_EVENT.removeAddress, FUNNEL_NAME.addressHandling, FUNNEL_STEP.removeDeliveryAddress);
            /* send saved address id */
            storeManager.emit('deleteAddress', { type: 'delivery', addressId: this.addressId });
            flowManager.next('delete-address', 'delivery-add');
        });
        /* form errors */
        this.form.addEventListener('ecFormError', (event) => {
            event.preventDefault();
            if (!event.data.errorField) return;
            trackEvent(
                TRACKABLE_EVENT.formError,
                FUNNEL_NAME.addressHandling,
                FUNNEL_STEP[`${this.modality}DeliveryAddressModal`],
                null,
                null,
                null,
                {
                    errorField: event.data.errorField,
                    errorText: event.data.errorText,
                }
            );
            event.stopPropagation();
        });

        this.wrapper.addEventListener('click', (event) => {
            const validable = event.target.closest('[data-is-validable]');
            if (validable) return;

            const input = this.form.querySelector('input:invalid');
            if (input) {
                input.focus();
            }
        });
    }

    _addStoreListeners() {
        storeManager.on('currentDeliveryAddress', async (path, data) => {
            this.modality = data.newAddress ? 'add' : 'modify';
            this._toggleModalVersion(data.newAddress);
            trackEvent(
                TRACKABLE_EVENT.pageview,
                FUNNEL_NAME.addressHandling,
                FUNNEL_STEP[`${this.modality}DeliveryAddressModal`]
            );
            /* save address id */
            this.addressId = data?.address?.id || '';
            this.resetForms();
            //this._disableSubmit();
            const address = data?.address;
            if (address && address.notCompleted) {
                /* grant valid state to delivery address */
                this._setStateDeliveryAddress('valid');
                /* show alert and make delivery notes required */
                this._showAlert(true);
                this._setRequiredDeliveryNotes(true);
                this.notCompleted = true;
            } else {
                /* revoke valid state from delivery address */
                this._setStateDeliveryAddress('');
                /* hide alert and make delivery notes optional */
                this._showAlert(false);
                this._setRequiredDeliveryNotes(false);
                this.notCompleted = false;
            }
            const formData = { ...address };
            /* check if line2 contains just the street number */
            const STREET_NUMBER_PATTERN = '^[1-9][0-9]{0,4}(?: ?(?:[A-Za-z]+|[/-] ?[A-Za-z]+?))?$';
            let showStreetNumber = false;
            if (address.line2 && 
                address.line2 !== '' && 
                !address.line2.match(new RegExp(STREET_NUMBER_PATTERN))) {
                /* reset line2 for addresses that do not have a street number in the field "line2" */
                formData.line2 = '';
            } else {
                showStreetNumber = address.line2 && address.line2 !== '';
            }
            console.log('address', formData);
            jsonToForm(this.form, formData || {}, []);
            if (showStreetNumber) this._showStreetNumber();
        });
    }

    _showStreetNumber() {
        const obj = register.getClass(this.deliveryAddress);
        obj._showCivico();
    }
    _hideStreetNumber() {
        const obj = register.getClass(this.deliveryAddress);
        obj._hideCivico();
    }
    _showAlert(show = true) {
        if (show) {
            if (!this.alert.classList.contains(this._elMod('alert', 'show'))) {
                this.alert.classList.add(this._elMod('alert', 'show'));
                this.alert.setAttribute('aria-hidden', 'false');
            }
        } else {
            this.alert.classList.remove(this._elMod('alert', 'show'));
            this.alert.setAttribute('aria-hidden', 'true');
        }
    }
    _setRequiredDeliveryNotes(required = true) {
        this.note.querySelector('textarea').required = required === true;
        const obj = register.getClass(this.note);
        obj.showOptional(required === false);
        obj._checkState();
    }
    _setStateDeliveryAddress(state) {
        if (!state || !['error', 'valid', ''].includes(state)) return;
        register.getClass(this.deliveryAddress).setState(state);
    }

    _toggleModalVersion(newAddress) {
        this._dEl(`title${newAddress ? 'Add' : 'Modify'}`).classList.add(
            this._elMod(`title${newAddress ? 'Add' : 'Modify'}`, 'show')
        );
        this._dEl(`title${!newAddress ? 'Add' : 'Modify'}`).classList.remove(
            this._elMod(`title${!newAddress ? 'Add' : 'Modify'}`, 'show')
        );
        newAddress
            ? this.remove.classList.add(this._elMod('remove', 'hidden'))
            : this.remove.classList.remove(this._elMod('remove', 'hidden'));
    }

    _updateAddressData(value) {
        if (!value || !value.address_components) return;

        // get values
        const addressComponents = value.address_components;
        const formatted_address = value.formatted_address;
        const route = this._getAddressComp(addressComponents, 'route', 'long_name');
        const street_number = this._getAddressComp(addressComponents, 'street_number', 'long_name');
        const administrative_area_level_3 = this._getAddressComp(addressComponents, 'administrative_area_level_3', 'long_name');
        const locality = this._getAddressComp(addressComponents, 'locality', 'long_name');
        const postal_code = this._getAddressComp(addressComponents, 'postal_code', 'long_name');
        const administrative_area_level_2 = this._getAddressComp(addressComponents, 'administrative_area_level_2', 'short_name');
        const country_short = this._getAddressComp(addressComponents, 'country', 'short_name');
        const country_long = this._getAddressComp(addressComponents, 'country', 'long_name');
        const lat = value.geometry.location.lat();
        const lng = value.geometry.location.lng();

        // populate address data (use line2 to hold only the street number)
        this.addressData['formattedAddress'] = formatted_address?.trim();
        this.addressData['line1'] = route + 
            (postal_code !== '' ? `, ${postal_code}` : '') +
            (locality !== '' ? `, ${locality}` : '') +
            (country_long !== '' ? `, ${country_long}` : '');
        this.addressData['line2'] = `${street_number || ''}`?.trim();
        this.addressData['town'] = `${administrative_area_level_3}`?.trim();
        this.addressData['postalCode'] = `${postal_code}`?.trim();
        this.addressData['district'] = `${administrative_area_level_2}`?.trim();
        this.addressData['country'] = {
            isocode: `${country_short}`?.trim(),
            name: `${country_long}`?.trim(),
        };
        if (lat) this.addressData['latitude'] = lat;
        if (lng) this.addressData['longitude'] = lng;
    }
    _getAddressComp(comps, type, field) {
        const comp = comps.filter((cmp) => cmp.types.includes(type))[0];
        return comp ? comp[field] : '';
    }

    resetForms() {
        this._resetFormInputs(this.form);
    }
    _resetFormInputs(form) {
        if (!form || !form.classList.contains(this._el('form'))) return;
        ['mt2-addressfield', 'mt38-address-google', 'mt8-textfield', 'mt16-select', 'mt17-checkbox', 'mt18-textarea', 'mt19-radio'].forEach(
            (el) => {
                form.querySelectorAll(`.${el}`).forEach((field) => {
                    register.getClass(field).reset();
                });
            }
        );
    }

    _getFormValidity(form) {
        if (!form.checkValidity()) {
            return false;
        }
        return true;
    }

    _enableSubmit() {
        if (this.submit.classList.contains(this._elMod('submit', 'active'))) return;
        this.submit.classList.add(this._elMod('submit', 'active'));
        this.submit.removeAttribute('title');
    }
    async _disableSubmit() {
        this.submit.classList.remove(this._elMod('submit', 'active'));
        this.submit.setAttribute('title', dictionary.getFEMessage('fillInFields'));
    }
    _submitDisabled() {
        return !this.submit.classList.contains(this._elMod('submit', 'active'));
    }

    async _submitForm(form) {
        openLoader('main');
        if (!form || !form.classList.contains(this._el('form'))) return;
        const data = {
            ...formToJSON(form),
            ...this.addressData,
        };
        /* custom data conversion */
        if (data.floor) data.floor = parseInt(data.floor);
        if (data.lift) data.lift = data.lift == true;
        if (data.reception) data.reception = data.reception == true;
        if (!data.building) data.building = '';
        if (!data.remarks) data.remarks = '';
        if (data.cellphoneVerified) data.cellphoneVerified = data.cellphoneVerified == 'true';

        if (typeof this.notCompleted !== 'boolean') {
            console.warn('notCompleted field must be boolean');
            closeLoader('main');
            this.addressId = '';
            return;
        }
        if (!data.notCompleted) data.notCompleted = this.notCompleted;
        // TODO: send formattedAddress AS IS

        try {
            if (this.addressId === '') {
                await apiProvider.addDeliveryAddress(data);
            } else {
                data.id = this.addressId;
                await apiProvider.editDeliveryAddress(data);
            }
            closeLoader('main');

            //aprire il popup dell'otp
            // if (!data.cellphoneVerified) {
            //     //send to otp check
            //     storeManager.emit('otpDeliveryAddress', data);
            //     flowManager.startFlow({ flowName: 'otp-delivery-address', flowSteps: [{ name: 'address-otp' }] });
            // } else {
            if (this.modality === 'add') {
                flowManager.next();
            } else {
                storeManager.emit('newDeliveryAddress', data);
            }
            // }
        } catch (error) {
            console.warn(error);
            closeLoader('main');
        } finally {
            this.addressId = '';
        }
    }

    async _onOpen() {
        await super._onOpen();
        register.getClass(this.addressName).focus();
    }
}

register.registerClass('.mm7-delivery-address', DeliveryAddressModal);
