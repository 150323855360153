import { apiProvider } from '../../../../../../libs/api-provider';
import WizardComponent from '../../../../../../libs/components/wizard-component';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { formToJSON } from '../../../../../../libs/form-to-json';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { checkOriginEcommerce } from '../../../../../../libs/utils';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';

class RecoverPsw extends WizardComponent {
    constructor(name, root) {
        super(name, root);

        this.historyBack = this._dEl('historyBack');
        this.form = this._dEl('form');
        this.error = this._dEl('error');
        //this.submit = this._dEl('submit');
        this.id = this.root.getAttribute('id');
        this.recaptchaAction = this.root.dataset.recaptchaAction;

        //this._checkForm();
        this.originEcommerce = checkOriginEcommerce();
        this._addEventListeners();
    }

    /* override */
    open() {
        super.open();
        if (this.originEcommerce) {
            this.typeOfService = window.accessInfo.typeOfService;
            this.pointOfServiceId = window.accessInfo.pointOfServiceId;
            this.cooperativeId = window.accessInfo.cooperativeId;
            this.userInfo = null;
            if (this.pointOfServiceId && this.cooperativeId) {
                this.userInfo = {
                    coop: this.cooperativeId,
                    store: this.pointOfServiceId,
                };
            }
            trackEvent(
                TRACKABLE_EVENT.pageview,
                FUNNEL_NAME.recoverPsw,
                FUNNEL_STEP.recoverPswForm,
                this.typeOfService,
                null,
                this.userInfo
            );
        } else {
            trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.recoverPsw, FUNNEL_STEP.recoverPswForm);
        }
    }

    _addEventListeners() {
        this.historyBack.addEventListener('click', () => {
            window.history.back();
        });

        this.form.addEventListener('submit', async (event) => {
            event.preventDefault();
            if (!this.form.checkValidity()) {
                const first = this.form.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }
            const token = await this._getRecaptchaToken();
            this._recoverPsw(token);
        });

        this.form.addEventListener('ecInputChanged', () => {
            //this._checkForm();
            if (!this.error.classList.contains(this._elMod('error', 'hidden'))) {
                this.error.classList.add(this._elMod('error', 'hidden'));
            }
        });

        /* form errors */
        this.form.addEventListener('ecFormError', (event) => {
            event.preventDefault();
            if (!event.data.errorField) return;
            trackEvent(
                TRACKABLE_EVENT.formError,
                FUNNEL_NAME.recoverPsw,
                FUNNEL_STEP.recoverPswForm,
                null,
                null,
                null,
                {
                    errorField: event.data.errorField,
                    errorText: event.data.errorText,
                }
            );
            event.stopPropagation();
        });
    }

    /*async _checkForm() {
        if (!this.form.checkValidity()) {
            if (!this.submit.classList.contains('invalid')) this.submit.classList.add('invalid');
            this.submit.setAttribute('title', dictionary.getFEMessage('fillInFields'));
        } else {
            this.submit.classList.remove('invalid');
        }
    }*/

    async _recoverPsw(token) {
        let result = false;
        try {
            openLoader('main');
            const data = formToJSON(this.form);
            data.gRecaptchaResponse = token;
            const resultData = await apiProvider.recoverPassword(data);
            if (resultData) {
                storeManager.emit('recoveredPswData', { ...data, ...resultData });
                flowManager.startFlow({flowName: 'check-email',
                    options: {
                        checkEmailOperation: 'changedPsw',
                    },
                    flowSteps: [{ name: 'check-email' }],
                });
                result = true;
            }
        } catch (error) {
            this.ISError = error;
            console.warn(error);
        } finally {
            closeLoader('main');
        }

        if (!result) {
            const errorText = this.ISError?.codice
                ? await (this.ISError.errore || dictionary.getFEMessage(this.ISError.codice))
                : this.root.dataset.genericError;
            this.error.innerHTML = errorText;
            trackEvent(
                TRACKABLE_EVENT.formError,
                FUNNEL_NAME.recoverPsw,
                FUNNEL_STEP.recoverPswForm,
                null,
                null,
                null,
                {
                    errorField: dictionary.getFEMessage('formSubmitError'),
                    errorText: errorText,
                }
            );
            this.error.classList.remove(this._elMod('error', 'hidden'));
        }
    }

    _getRecaptchaToken() {
        return new Promise((resolve) => {
            /* recaptcha */
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(window.recaptchaSiteKey, { action: this.recaptchaAction }).then((token) => {
                    resolve(token);
                });
            });
        });
    }
}

register.registerClass('.mw13-recover-psw', RecoverPsw);
