import { apiProvider } from '../../../../../libs/api-provider';
import Component from '../../../../../libs/components/component';
import { flowManager } from '../../../../../libs/flow-manager';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { register } from '../../../../../libs/register';
import { storeManager } from '../../../../../libs/store-manager';
import contentDskTpl from './partials/content-dsk-tpl.html';

export class CustomerCareRequest extends Component {
    constructor(name, root) {
        super(name, root);
        this._doLogic();
    }

    async _doLogic() {
        try {
            this.request = JSON.parse(this.root.dataset.request);
        } catch (error) {
            console.warn('Could not parse request data');
            return;
        }
        try {
            this.labels = JSON.parse(this.root.dataset.labels);
        } catch (error) {
            console.warn('Could not parse labels');
            this.labels = {};
        }
        this.contentEl = null;
        this.BREAKPOINT_L = 1024;
        this._getElements();
        this._addEventListeners();
    }

    _getElements() {
        this.btnOpenMob = this._dEl('btnOpenMob');
        this.btnOpenDsk = this._dEl('btnOpenDsk');
        this.content = this._dEl('content');
    }

    _addEventListeners() {
        // click accordion
        this.btnOpenDsk?.addEventListener('click', async () => {
            if (this.contentEl === null) {
                await this._generateContentOnce();
            }
            this._isContentAccOpen() ? this.closeContentAcc() : this._openContentAcc();
        });

        // open mobile flow
        this.btnOpenMob?.addEventListener('click', () => {
            this._openMobileModal();
        });

        // resize
        window.addEventListener('resize', () => {
            if (window.innerWidth < this.BREAKPOINT_L) {
                this.closeContentAcc();
            }
        });
    }

    async _openMobileModal() {
        await this._downloadAttachments();

        const contentData = CustomerCareRequest.getCCRequestData(
            this.request.id,
            '',
            this.labels,
            storeManager.get('ccRequestStatusMap'),
            storeManager.get('ccRequestSiteIconMap'),
            this.request
        );
        storeManager.emit('renderCCRequest', contentData);
        flowManager.startFlow({
            flowName: 'cc-request-details',
            flowSteps: [{ name: 'cc-request-details' }],
        });
    }

    async _downloadAttachments() {
        if (!this.request.hasAttachments || this.request.documents) return;

        const documents = await apiProvider.getAttachmentsList({ code: this.request.id });
        let finalDocuments = [];
        for (const doc of documents) {
            finalDocuments.push({
                ...doc,
                url: apiProvider.getCustomerCareAttachmentLink({
                    attachmentId: doc.id,
                    extension: doc.fileExtension,
                    fileName: doc.title,
                }),
            });
        }
        this.request.documents = finalDocuments;
    }

    async _generateContentOnce() {
        if (!this.request) {
            console.warn('Cannot generate content without request data');
            return;
        }

        await this._downloadAttachments();

        // generate content element
        const contentData = await CustomerCareRequest.getCCRequestContentData(this.request, this.labels);
        this.contentEl = runTemplate(contentDskTpl, contentData, this._el('contentWrapper', true));
        this.content.append(this.contentEl);

        // add content event listeners
        const minimizeBtn = this.content.querySelector(this._el('minimize', true));
        const closeRequestBtn = this.content.querySelector(this._el('closeRequest', true));
        minimizeBtn?.addEventListener('click', () => {
            this.closeContentAcc();
            setTimeout(() => {
                this.btnOpenDsk.focus();
            }, 500);
        });

        closeRequestBtn?.addEventListener('click', () => {
            flowManager.startFlow({
                flowName: 'close-cc-request',
                flowSteps: [{ name: 'close-cc-request' }],
                options: {
                    request: this.request,
                },
            });
        });
    }

    _isContentAccOpen() {
        return this.btnOpenDsk.getAttribute('aria-expanded') === 'true';
    }

    _openContentAcc() {
        this.btnOpenDsk.setAttribute('aria-expanded', 'true');
        this.btnOpenDsk.setAttribute('aria-label', this.btnOpenDsk.dataset.labelMinimize);
        this.content.setAttribute('aria-hidden', 'false');
        this.content.style.visibility = 'visible';
        this._updateContentAccHeight();
    }

    closeContentAcc() {
        this.btnOpenDsk.setAttribute('aria-expanded', 'false');
        this.btnOpenDsk.setAttribute('aria-label', this.btnOpenDsk.dataset.labelExpand);
        this.content.setAttribute('aria-hidden', 'true');
        this.content.style.visibility = 'hidden';
        this._updateContentAccHeight();
    }

    _updateContentAccHeight() {
        const isOpen = this._isContentAccOpen();
        this.content.style.maxHeight = `${isOpen ? this.content.scrollHeight + 100 : 0}px`;
    }

    static getCCRequestContentData(request, labels) {
        return {
            labels,
            request,
        };
    }

    static FULL_DATE_FORMATTER = new Intl.DateTimeFormat('it-IT', { day: 'numeric', month: 'short', year: 'numeric' });
    static getCCRequestData(uniqueId, extraClasses, labels, statusMap, siteIconMap, request) {
        return {
            uniqueId,
            extraClasses,
            labels,
            labelsJson: JSON.stringify(labels),
            request,
            requestJson: JSON.stringify(request),
            siteIcon: siteIconMap[request.site] || 'ec-icon-cursor',
            statusLabel: statusMap[request.status] || '',
            createdDate: this.FULL_DATE_FORMATTER.format(new Date(request.createdDate)),
        };
    }
}

register.registerClass('.mt40-customer-care-request', CustomerCareRequest);
