import { Popup } from '../../repository/apps/conad-myconad/templates/mt4-popup/script';
import Component from './component';

export default class PopupComponent extends Component {
    constructor(name, root) {
        super(name, root);
        this.popup = Popup.getCurrentPopup(this.root);
        this.popupName = this.popup.getPopupName();

        this._initModalEvents();
        this._initSteps();
    }

    _initModalEvents() {
        /* modal events */
        this.popup.getRoot().addEventListener('ecPopupOpen', () => {
            this._onOpen();
        });
        this.popup.getRoot().addEventListener('ecPopupBeforeOpen', () => {
            this._onBeforeOpen();
        });
        this.popup.getRoot().addEventListener('ecPopupClose', () => {
            this._onClose();
        });
        this.popup.getRoot().addEventListener('ecPopupCancel', () => {
            this._onCancel();
        });
    }

    _initSteps() {}

    async _onOpen() {
        //focus content
        this.popup?.content?.focus();
    }

    async _onClose() {}

    async _onCancel() {}

    async _onBeforeOpen() {}
}
