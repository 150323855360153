import Component from '../../../../../../libs/components/component';
import { informativaManager } from '../../../../../../libs/informativa-manager';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';

class Footer extends Component {
    constructor(name, root) {
        super(name, root);
        this.privacyLink = this._dEl('privacyLink');
        this._addStoreListeners();
        this.user = storeManager.get('userData');
        this.pdv = storeManager.get('selectedPdv');
        this.setPrivacyLink(this.coop, this.isCard);
    }

    _addStoreListeners() {
        storeManager.on('selectedPdv', async (path, data) => {
            this.pdv = data;
            this.setPrivacyLink();
        });

        storeManager.on('userData', async (path, data) => {
            this.user = data;
            this.setPrivacyLink();
        });

        this.setPrivacyLink();
    }

    async setPrivacyLink() {
        const result = await informativaManager.getInformativa(this.user, this.pdv);
        this.privacyLink.setAttribute('href', result.linkPdfInformativa);
    }
}

register.registerClass('.ms3-footer', Footer);
