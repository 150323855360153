import { apiProvider } from '../../../../../../libs/api-provider';
import Component from '../../../../../../libs/components/component';
import { register } from '../../../../../../libs/register';
import { formToJSON, jsonToForm } from '../../../../../../libs/form-to-json';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import { showPopup } from '../../../templates/mt4-popup/script';
import { flowManager } from '../../../../../../libs/flow-manager';
import { delay } from '../../../../../../libs/utils';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { storeManager } from '../../../../../../libs/store-manager';
import { informativaManager } from '../../../../../../libs/informativa-manager';

class Profile extends Component {
    constructor(name, root) {
        super(name, root);

        /* TABS ***********************************************************************************************************************/

        this.tabNames = ['accessSecurity', 'personalData', 'consents'];
        this.tab = {
            accessSecurity: this._dEl('accessSecurityTab'),
            personalData: this._dEl('personalDataTab'),
            consents: this._dEl('consentsTab'),
        };
        this.tabList = this._dEl('tabs');
        this.tabs = Object.values(this.tab);
        this.content = {
            accessSecurity: this._dEl('accessSecurityContent'),
            personalData: this._dEl('personalDataContent'),
            consents: this._dEl('consentsContent'),
        };
        this.tabPanels = Object.values(this.content);

        /* ACCESS/SECURITY ***********************************************************************************************************************/

        this.accessEmail = this._getElFromContent('accessSecurity', 'accessEmail');
        this.accessEmailModify = this._getElFromContent('accessSecurity', 'accessEmailModify');
        this.accessEmailCancel = this._getElFromContent('accessSecurity', 'accessEmailCancel');
        this.accessEmailEditForm = this._getElFromContent('accessSecurity', 'accessEmailEditForm');
        this.accessEmailSave = this._getElFromContent('accessSecurity', 'accessEmailSave');

        this.accessPassword = this._getElFromContent('accessSecurity', 'accessPassword');
        this.accessPasswordModify = this._getElFromContent('accessSecurity', 'accessPasswordModify');
        this.accessPasswordCancel = this._getElFromContent('accessSecurity', 'accessPasswordCancel');
        this.accessPasswordEditForm = this._getElFromContent('accessSecurity', 'accessPasswordEditForm');
        this.accessPasswordSave = this._getElFromContent('accessSecurity', 'accessPasswordSave');
        this.passwordOld = this._getElFromContent('accessSecurity', 'accessPasswordOldField');
        this.passwOldObj = register.getClass(this.passwordOld);
        this.password = this._getElFromContent('accessSecurity', 'accessPasswordField');
        this.passwObj = register.getClass(this.password);
        this.passwordRepeat = this._getElFromContent('accessSecurity', 'accessPasswordFieldRepeat');
        this.passwRepeatObj = register.getClass(this.passwordRepeat);
        this.passwordRules = this._getElFromContent('accessSecurity', 'passwordRules');
        this.passwordState = this._getElFromContent('accessSecurity', 'passwordState');
        this.passwordMismatch = this._getElFromContent('accessSecurity', 'passwordMismatch');
        this.passwordStateOk = this.passwordState.querySelector('.ok');
        this.passwordStateError = this.passwordState.querySelector('.error');
        this.changedPswPopupContent = this._dEl('changedPswPopupContent').content.querySelector('.mt26-basic-popup');

        /* marketing email data */
        this.marketingEmailSection = this._dEl('marketingEmailSection');
        this.marketingEmailAlert = this._dEl('marketingEmailAlert');
        this.marketingEmailAlertCta = this._dEl('marketingEmailAlertCta');
        this.marketingEmailAlertClose = this._dEl('marketingEmailAlertClose');

        this.deleteProfile = this._getElFromContent('accessSecurity', 'deleteProfile');

        /* PERSONAL DATA ***********************************************************************************************************************/

        this.personalData = this._getElFromContent('personalData', 'personalData');
        this.personalDataModify = this._getElFromContent('personalData', 'personalModify');
        this.personalDataCancel = this._getElFromContent('personalData', 'personalCancel');
        this.personalDataEditForm = this._getElFromContent('personalData', 'personalEditForm');
        this.personalChildBirthDateEdit = this._getElFromContent('personalData', 'personalChildBirthDateEdit');
        this.personalChildBirthDatePickers = Array.from(
            this.personalChildBirthDateEdit.querySelectorAll('.mt23-datepicker')
        );
        this.personalDataSave = this._getElFromContent('personalData', 'personalSave');
        this.personalAddressEdit = this._getElFromContent('personalData', 'personalAddressEdit');
        this.personalAddressProvinceSelect = this.personalAddressEdit?.querySelector(
            `${this._el('personalAddressProvinceEdit', true)}.mt16-select`
        );
        this.personalAddressCitySelect = this.personalAddressEdit?.querySelector(
            this._el('personalAddressCityEdit', true)
        );

        this.enrichmentBanner = this._getElFromContent('personalData', 'enrichmentBanner');
        this.enrichmentBannerCta = this.enrichmentBanner.querySelector(this._el('enrichmentBannerCta', true));
        this.enrichmentData = this._getElFromContent('personalData', 'enrichmentData');
        this.enrichmentDataModify = this._getElFromContent('personalData', 'enrichmentModify');
        this.enrichmentDataCancel = this._getElFromContent('personalData', 'enrichmentCancel');
        this.enrichmentDataEditForm = this._getElFromContent('personalData', 'enrichmentEditForm');
        this.enrichmentDataSave = this._getElFromContent('personalData', 'enrichmentSave');
        this.enrichmentDataSavedOnce = true;

        /* CONSENTS ***********************************************************************************************************************/

        this.consentsData = this._getElFromContent('consents', 'consentsData');
        this.consentsForm = this._getElFromContent('consents', 'consentsForm');
        this.consentsMarketing = this._getElFromContent('consents', 'consentsMarketing');
        this.consentsMarketingRadio = this._getElFromContent('consents', 'consentsPrivacy1');
        this.consentsProfiling = this._getElFromContent('consents', 'consentsProfiling');
        this.consentsCooperative = this._getElFromContent('consents', 'consentsCooperative');
        this.consentsMarketingPartner = this._getElFromContent('consents', 'consentsMarketingCommunicationPartner');
        this.consentsSave = this._getElFromContent('consents', 'consentsSave');
        this.consentsHeadingText = this._getElFromContent('consents', 'consentsHeadingText');
        // CONTACT PREFERENCES
        this.cpForm = this._getElFromContent('consents', 'cp-form');
        this.cpToggles = this.cpForm ? [...this.cpForm.querySelectorAll(this._el('cp-toggle', true))] : [];

        this._initTextsWithEditableLinks();

        /* PREPOPULATE ***********************************************************************************************************************/

        this.tabFocus = this.tabNames.indexOf(this.root.dataset.activeTab) || 0;
        this.tab[this.root.dataset.activeTab].setAttribute('tabindex', '0');
        this._selectTab(this.tab[this.root.dataset.activeTab], this.content[this.root.dataset.activeTab], true);

        /*['accessEmailSave', 'accessPasswordSave', 'personalDataSave', 'enrichmentDataSave', 'consentsSave'].forEach(
            (el) => {
                this._disableSubmit(this[el], el);
            }
        );*/

        try {
            this.user = JSON.parse(this.root.dataset.user);
        } catch (error) {
            console.warn('Could not parse user');
            return;
        }

        if (this.personalAddressProvinceSelect) this._loadProvince(this.personalAddressProvinceSelect);
        this.firstLoad = true;

        jsonToForm(this.personalDataEditForm, this.user, []);
        this._refreshPersonalChildBirthDatepickers();

        jsonToForm(this.enrichmentDataEditForm, this.user, []);

        this._initConsentsForm();
        this._initConsentsNegativeTexts();

        this._setValidationContexts();

        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        /* TABS ***********************************************************************************************************************/

        this.tabs.forEach((tab) => {
            tab.addEventListener('click', () => {
                if (this._isTabActive(tab)) return;
                // Remove all current selected tabs
                const selectedTabs = [...this.tabList.querySelectorAll('[aria-selected="true"]')];
                selectedTabs?.forEach((t) => {
                    this._selectTab(t, this._getTabPanelFromTab(t), false);
                });
                // Set this tab as selected
                this._selectTab(tab, this._getTabPanelFromTab(tab), true);
            });
        });
        this.tabList.addEventListener('keydown', (e) => {
            if (e.key !== 'ArrowRight' && e.key !== 'ArrowLeft') return;
            this.tabs[this.tabFocus].setAttribute('tabindex', '-1');
            if (e.key === 'ArrowRight') {
                this.tabFocus++;
                // if at the end, go to the start
                if (this.tabFocus >= this.tabs.length) {
                    this.tabFocus = 0;
                }
            } else if (e.key === 'ArrowLeft') {
                this.tabFocus--;
                // if at the start, move to the end
                if (this.tabFocus < 0) {
                    this.tabFocus = this.tabs.length - 1;
                }
            }
            this.tabs[this.tabFocus].setAttribute('tabindex', '0');
            this.tabs[this.tabFocus]?.focus();
        });

        /* ACCESS/SECURITY ***********************************************************************************************************************/

        this.accessEmailModify.addEventListener('click', (event) => {
            event.preventDefault();
            /* reset edit fields before entering edit mode */
            this._resetFormInputs(this.accessEmailEditForm);
            this._refreshEcTime();
            if (!this.accessEmail.classList.contains(this._elMod('accessEmail', 'edit'))) {
                this.accessEmail.classList.add(this._elMod('accessEmail', 'edit'));
                /* move focus to cancel button */
                this.accessEmailCancel?.focus();
            }
        });
        this.accessEmailCancel.addEventListener('click', (event) => {
            event.preventDefault();
            this.accessEmail.classList.remove(this._elMod('accessEmail', 'edit'));
            /* move focus to modify button */
            this.accessEmailModify?.focus();
        });

        this.accessPasswordModify.addEventListener('click', (event) => {
            event.preventDefault();
            /* reset edit fields before entering edit mode */
            this._resetFormInputs(this.accessPasswordEditForm);
            this._refreshEcTime();
            if (!this.accessPassword.classList.contains(this._elMod('accessPassword', 'edit'))) {
                this.accessPassword.classList.add(this._elMod('accessPassword', 'edit'));
                /* move focus to cancel button */
                this.accessPasswordCancel?.focus();
            }
        });
        this.accessPasswordCancel.addEventListener('click', (event) => {
            event.preventDefault();
            if (!this.passwordRules.classList.contains(this._elMod('passwordRules', 'hidden')))
                this.passwordRules.classList.add(this._elMod('passwordRules', 'hidden'));
            this.passwordStateOk.classList.remove('active');
            this.passwordStateError.classList.remove('active');
            this.passwordStateOk.classList.add('active');
            this.accessPassword.classList.remove(this._elMod('accessPassword', 'edit'));
            /* move focus to modify button */
            this.accessPasswordModify?.focus();
        });
        this.passwordOld.addEventListener('ecInputChanged', () => {
            this._checkPasswordOldFormat();
        });
        this.password.addEventListener('ecInputChanged', () => {
            this._checkShowPasswordRules();
            if (this.passwordRepeat.querySelector('input')?.value !== '') this._checkPasswordRepeat();
        });
        this.password.querySelector('input').addEventListener('focus', () => {
            this._checkShowPasswordRules();
        });
        this.passwordRepeat.addEventListener('ecInputChanged', () => {
            this._checkPasswordRepeat();
        });

        this.accessEmailSave.addEventListener('click', async (event) => {
            event.preventDefault();
            if (!this._getFormValidity(this.accessEmailEditForm)) {
                const first = this.accessEmailEditForm.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }
            if (this.ecTime && new Date().getTime() - this.ecTime > this.ecTimeThreshold) {
                this._confirmLogin('accessEmailSave');
                return;
            }

            const reload = await this._submitNewEmail(this.accessEmailEditForm);
            if (reload) {
                window.location.reload();
                this.accessEmail.classList.remove(this._elMod('accessEmail', 'edit'));
            }
        });

//        this.accessPasswordEditForm.addEventListener('ecInputChanged', (event) => {
//            if (event.data.valid && this._getFormValidity(this.accessPasswordEditForm)) {
//                this._checkPasswordRepeat();
//            }
//            event.stopPropagation();
//        });
        this.accessPasswordSave.addEventListener('click', async (event) => {
            event.preventDefault();
            if (!this._getFormValidity(this.accessPasswordEditForm)) {
                const first = this.accessPasswordEditForm.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }

                if(first.name == 'oldPassword') {
                    if(this.passwOldObj.getValue() == '') {
                        this.passwOldObj.setErrorText(dictionary.getFEMessage('oldPasswordFieldErrorEmpty'));
                    }
                }

                return;
            }

            if (this.ecTime && new Date().getTime() - this.ecTime > this.ecTimeThreshold) {
                this._confirmLogin('accessPasswordSave');
                return;
            }

            await this._submitNewPassword(this.accessPasswordEditForm);
        });

        this.marketingEmailAlertCta?.addEventListener('click', () => {
            flowManager.startFlow({
                flowName: 'substitute-marketing-email',
                flowSteps: [{ name: 'substitute-marketing-email' }],
            });
        });

        this.marketingEmailAlertClose?.addEventListener('click', () => {
            this.marketingEmailAlert?.remove();
        });

        this.deleteProfile.addEventListener('click', async (event) => {
            event.preventDefault();

            //tracking
            trackEvent('clickEliminaProfilo', FUNNEL_NAME.disablingUser, 'Click elimina profilo');

            const deletable = await this._verifyUserDeletion();
            if (deletable) {
                // normal flow (confirm login if necessary, otherwise show confirmation popup directly)
                this._refreshEcTime();
                if (this.ecTime && new Date().getTime() - this.ecTime > this.ecTimeThreshold) {
                    this._confirmLogin('deleteProfile');
                    return;
                }
                this._deleteProfile();
            } else {
                // open information popup (on close, stay on current page)
                flowManager.startFlow({
                    flowName: 'undeletable-profile',
                    flowSteps: [{ name: 'undeletable-profile' }],
                });
            }
        });

        /* PERSONAL DATA ***********************************************************************************************************************/

        this.personalDataModify.addEventListener('click', (event) => {
            event.preventDefault();
            if (!this.personalData.classList.contains(this._elMod('personalData', 'edit'))) {
                this.personalData.classList.add(this._elMod('personalData', 'edit'));
                /* move focus to cancel button */
                this.personalDataCancel?.focus();
            }
            if (
                this.enrichmentBanner &&
                !this.enrichmentBanner.classList.contains(this._elMod('enrichmentBanner', 'hidden'))
            ) {
                this.enrichmentBanner.classList.add(this._elMod('enrichmentBanner', 'hidden'));
            }
            this._refreshEcTime();
        });

        this.personalDataCancel.addEventListener('click', (event) => {
            event.preventDefault();
            this.personalData.classList.remove(this._elMod('personalData', 'edit'));
            /* move focus to modify button */
            this.personalDataModify?.focus();
            if (this.enrichmentBanner) {
                this.enrichmentBanner.classList.remove(this._elMod('enrichmentBanner', 'hidden'));
            }
            this._closeSelects(this.personalDataEditForm);
        });

        // save personal data
        this.personalDataSave.addEventListener('click', async (event) => {
            event.preventDefault();

            if (!this._getFormValidity(this.personalDataEditForm)) {
                const first = this.personalDataEditForm.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }

            // verifico confirm-login
            if (this.ecTime && new Date().getTime() - this.ecTime > this.ecTimeThreshold) {
                const data = await flowManager.startAsyncFlow({
                    flowName: 'confirm-login',
                    flowSteps: [{ name: 'confirm-login' }],
                });

                if (!data?.ok) return;
            }

            // verifico conad-card
            if (this.user.conadCard) {
                const data = await flowManager.startAsyncFlow({
                    flowName: 'save-profile',
                    flowSteps: [{ name: 'save-profile' }],
                });

                if (!data?.ok) return;
            }

            // load form
            const data = formToJSON(this.personalDataEditForm, true);
            if (data.codiceCittaLabel) data.citta = data.codiceCittaLabel;
            if (data.dataNascitaFiglio1 == '') data.dataNascitaFiglio1 = '00-00-0000';
            if (data.dataNascitaFiglio2 == '') data.dataNascitaFiglio2 = '00-00-0000';
            if (data.dataNascitaFiglio3 == '') data.dataNascitaFiglio3 = '00-00-0000';

            // verifico refresh
            let isRefreshPopulated = false;
            let extraUserRefresh = {};
            if (this.user.refresh) {
                const extra = await flowManager.startAsyncFlow({
                    flowName: 'save-profile',
                    flowSteps: [{ name: 'refresh-update-profile' } /*, { name: 'refresh-prize-choice' }*/],
                    initialData: {
                        telefonoCellulare: data.telefonoCellulare,
                    },
                });
                if (extra) {
                    extraUserRefresh = extra;
                    isRefreshPopulated = true;
                }
            }

            // mix form + extraUserRefresh
            const newData = { ...data, ...extraUserRefresh };
            try {
                openLoader('main');

                // get firmaDigitale & edit user
                const firmaDigitale = await apiProvider.getFirmaDigitale();
                newData.firmaDigitale = firmaDigitale;
                await apiProvider.editUserData(newData);

/*
                // save coupon choice
                if (newData.choice) {
                    await apiProvider.setCouponChoice({ choice: newData.choice });
                }
*/

                // update mail if necessary ( refresh )
                if (isRefreshPopulated && newData.mailAddress && newData.mailAddress != this.user.email) {
                    const dataMailCall = {
                        email: newData.mailAddress,
                        updateGccMail: false,
                    };
                    await apiProvider.changeEmailRequestV2(dataMailCall);
                }

                closeLoader('main');

                // open congratulation popup if refresh
                if (isRefreshPopulated) {
                    await flowManager.startAsyncFlow({
                        flowName: 'refresh-thank-you',
                        flowSteps: [{ name: 'refresh-thank-you' }],
                        initialData: {
                            /*choice: newData.choice ? newData.choice : null,*/
                            changedMailLogin: newData.mailAddress != this.user.email ? newData.mailAddress : null,
                        },
                    });
                }
                await delay(1000);
                this.personalData.classList.remove(this._elMod('personalData', 'edit'));

                // reload page
                let url = new URL(window.location.href);
                url.searchParams.set('tab', 'personalData');
                window.location.href = url.href;
            } catch (e) {
                closeLoader('main');

                trackEvent(
                    TRACKABLE_EVENT.formError,
                    FUNNEL_NAME.personalProfile,
                    FUNNEL_STEP.personalDataForm,
                    null,
                    null,
                    null,
                    {
                        errorField: dictionary.getFEMessage('formSubmitError'),
                        errorText: dictionary.getFEMessage('genericError'),
                    }
                );

                console.warn('Cannot save personal data', e);

                // GENERIC ERROR FLOW
                await flowManager.startAsyncFlow({
                    flowName: 'generic-error',
                    flowSteps: [{ name: 'generic-error', disableBack: true }],
                });
            }
        });

        this.personalAddressProvinceSelect?.addEventListener('ecInputChanged', async (event) => {
            if (this.personalAddressCitySelect) {
                const selComuneObj = register.getClass(this.personalAddressCitySelect);
                await this._loadComuni(event.data.value, selComuneObj);
                if (this.firstLoad && event.data.value === this.user?.codiceProvincia) {
                    this.firstLoad = false;
                    if (this.user.codiceCitta) selComuneObj.setSelected(this.user.codiceCitta);
                }
            }
        });

        this.personalChildBirthDateEdit.addEventListener('ecInputChanged', () => {
            this._refreshPersonalChildBirthDatepickers();
        });

        this.enrichmentBannerCta.addEventListener('click', (event) => {
            event.preventDefault();

            if (this.user.privacy2 != 'S') {
                flowManager.startFlow({
                    flowName: 'profilation-consent',
                    flowSteps: [{ name: 'profilation-consent' }],
                    onComplete: (name, data) => {
                        if (data?.ok) this.tab.consents.click();
                    },
                });
                return;
            }

            if (!this.enrichmentData.classList.contains(this._elMod('enrichmentData', 'active'))) {
                this.enrichmentData.classList.add(this._elMod('enrichmentData', 'active'));
            }
            if (!this.enrichmentData.classList.contains(this._elMod('enrichmentData', 'edit'))) {
                this.enrichmentData.classList.add(this._elMod('enrichmentData', 'edit'));
            }
            this.enrichmentDataSavedOnce = false;
            /* move focus to cancel button */
            this.enrichmentDataCancel?.focus();
        });

        this.enrichmentDataModify.addEventListener('click', (event) => {
            event.preventDefault();
            this._refreshEcTime();
            if (!this.enrichmentData.classList.contains(this._elMod('enrichmentData', 'edit'))) {
                this.enrichmentData.classList.add(this._elMod('enrichmentData', 'edit'));
                /* move focus to cancel button */
                this.enrichmentDataCancel?.focus();
            }
        });

        this.enrichmentDataCancel.addEventListener('click', (event) => {
            event.preventDefault();
            this.enrichmentData.classList.remove(this._elMod('enrichmentData', 'edit'));
            this._closeSelects(this.enrichmentDataEditForm);
            if (!this.enrichmentDataSavedOnce) {
                this.enrichmentData.classList.remove(this._elMod('enrichmentData', 'active'));
                /* move focus to banner button */
                this.enrichmentBannerCta?.focus();
                return;
            }
            /* move focus to modify button */
            this.enrichmentDataModify?.focus();
        });

        this.enrichmentDataSave.addEventListener('click', async (event) => {
            event.preventDefault();
            if (!this._getFormValidity(this.enrichmentDataEditForm)) {
                const first = this.enrichmentDataEditForm.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }

            if (this.ecTime && new Date().getTime() - this.ecTime > this.ecTimeThreshold) {
                this._confirmLogin('enrichmentSave');
                return;
            }

            await this._submitNewEnrichmentData(this.enrichmentDataEditForm);
            await delay(1000);

            this.enrichmentData.classList.remove(this._elMod('enrichmentData', 'edit'));
            this.enrichmentDataSavedOnce = true;
        });

        /* CONSENTS ***********************************************************************************************************************/

        [
            this.consentsMarketing,
            this.consentsProfiling,
            this.consentsCooperative,
            this.consentsMarketingPartner,
        ].forEach((el) => {
            if (!el) return;

            el.addEventListener('ecInputChanged', (event) => {
                const value = event.data.value;
                el.querySelector(this._el('consentsNegativeText', true)).style.display =
                    value === 'N' ? 'block' : 'none';
            });
        });

        this.consentsMarketingRadio?.addEventListener('ecInputChanged', (event) => {
            const value = event.data.value;
            if (value === 'S') {
                this._enableContactPreferences();
                this._toggleAllContactPreferences('S');
                return;
            }
            if (value === 'N') {
                this._toggleAllContactPreferences('N');
                this._disableContactPreferences();
                return;
            }
        });

        this.cpToggles?.forEach((toggle) => {
            toggle.addEventListener('click', () => {
                if (register.getClass(toggle).isDisabled()) {
                    flowManager.startFlow({
                        flowName: 'marketing-consent',
                        flowSteps: [{ name: 'marketing-consent' }],
                    });
                }
            });

            toggle.addEventListener('change', () => {
                if (!register.getClass(toggle).isDisabled()) {
                    this._checkPrivacy1Consent();
                }
            });
        });

        this.consentsData.addEventListener('ecInputChanged', (event) => {
            this._refreshEcTime();
            event.stopPropagation();
        });
        this.consentsSave.addEventListener('click', async (event) => {
            event.preventDefault();
            if (!this._getFormValidity(this.consentsForm)) {
                const first = this.consentsForm.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }

            if (this.ecTime && new Date().getTime() - this.ecTime > this.ecTimeThreshold) {
                this._confirmLogin('consentsSave');
                return;
            }
            await this._submitNewConsents(this.consentsForm);
            await delay(1000);
        });

        /* FORM ERRORS ***********************************************************************************************************************/
        this.accessEmailEditForm.addEventListener('ecFormError', (event) => {
            event.preventDefault();
            if (!event.data.errorField) return;
            this._trackFormError(FUNNEL_STEP.changeEmailForm, event);
            event.stopPropagation();
        });
        this.accessPasswordEditForm.addEventListener('ecFormError', (event) => {
            event.preventDefault();
            if (!event.data.errorField) return;
            this._trackFormError(FUNNEL_STEP.changePswForm, event);
            event.stopPropagation();
        });
        this.personalDataEditForm.addEventListener('ecFormError', (event) => {
            event.preventDefault();
            if (!event.data.errorField) return;
            this._trackFormError(FUNNEL_STEP.personalDataForm, event);
            event.stopPropagation();
        });
        this.enrichmentDataEditForm.addEventListener('ecFormError', (event) => {
            event.preventDefault();
            if (!event.data.errorField) return;
            this._trackFormError(FUNNEL_STEP.enrichmentDataForm, event);
            event.stopPropagation();
        });
    }

    _addStoreListeners() {
        storeManager.on('mm10LoginConfirmed', async (path, data) => {
            if (!data.confirmed) {
                console.warn('Login confirmation failed');
                return;
            }

            // get submit el
            const submitEl = data.submitEl;
            if (!submitEl) return;

            switch (submitEl) {
                case 'accessEmailSave': {
                    const reload = await this._submitNewEmail(this.accessEmailEditForm);
                    if (reload) {
                        window.location.reload();
                        this.accessEmail.classList.remove(this._elMod('accessEmail', 'edit'));
                    }
                    break;
                }
                case 'accessPasswordSave': {
                    await this._submitNewPassword(this.accessPasswordEditForm);
                    break;
                }
                case 'deleteProfile': {
                    this._deleteProfile();
                    break;
                }
                case 'enrichmentSave': {
                    await this._submitNewEnrichmentData(this.enrichmentDataEditForm);
                    await delay(1000);
                    this.enrichmentData.classList.remove(this._elMod('enrichmentData', 'edit'));
                    this.enrichmentDataSavedOnce = true;
                    break;
                }
                case 'consentsSave': {
                    await this._submitNewConsents(this.consentsForm);
                    await delay(1000);
                    break;
                }
                default:
                    break;
            }
        });

        storeManager.on('userData', async (path, data) => {
            const privacyLink = this._dEl('privacyLink');
            if (!privacyLink) return;

            const userData = data;
            const pdv = storeManager.get('selectedPdv');
            const result = await informativaManager.getInformativa(userData, pdv);
            if (!result) return;

            privacyLink.setAttribute('href', result.linkPdfInformativa);
        });

        storeManager.on('changedEmailOcdb', async () => {
            this.marketingEmailSection?.classList.remove(this._elMod('marketingEmailSection', 'changeable'));
        });
    }

    _confirmLogin(submitEl) {
        flowManager.startFlow({
            flowName: 'confirm-login',
            flowSteps: [{ name: 'confirm-login' }],
            options: { submitEl: submitEl },
        });
    }

    _refreshEcTime() {
        this.ecTime = storeManager.get('perslogData')?.ecTime || null;
        this.ecTimeThreshold = storeManager.get('perslogData').ecTimeThreshold;
    }

    _checkPrivacy1Consent() {
        let checkPrivacy1Consent = false;
        let cpNoContactConsentElement;

        if (document.querySelector('input[name="privacy1"][value="N"]')) {
            cpNoContactConsentElement = document.querySelector('input[name="privacy1"][value="N"]');
        }

        this.cpForm?.querySelectorAll('input').forEach(function (i, idx, array) {
            if (i.checked) {
                checkPrivacy1Consent = true;
            }

            if (idx === array.length - 1 && !checkPrivacy1Consent && cpNoContactConsentElement) {
                cpNoContactConsentElement.click();

                const top = document.getElementById('mf7-profile-consents-negative-text1').getBoundingClientRect().top;
                window.scrollTo({
                    top: top + window.scrollY - 140,
                    behavior: 'smooth',
                });
            }
        });
    }

    _trackFormError(funnelStep, event) {
        trackEvent(TRACKABLE_EVENT.formError, FUNNEL_NAME.personalProfile, funnelStep, null, null, null, {
            errorField: event.data.errorField,
            errorText: event.data.errorText,
        });
    }

    _getElFromContent(name, el) {
        if (!name || !this.tabNames.includes(name)) return;
        return this.content[name].querySelector(this._el(el, true));
    }

    _switchTab(name) {
        if (!name || !this.tabNames.includes(name)) return;
        this.tabNames.forEach((tabName) => {
            if (tabName === name) {
                this.toggleActiveTab(name);
            } else {
                this._disablePointerEvents(this.tab[tabName]);
                this._cleanStyle(this.tab[tabName]);
            }
        });
    }
    toggleActiveTab(name) {
        if (!name || !this.tabNames.includes(name)) return;
        this.tabNames.forEach((tabName) => {
            if (tabName === name) {
                this.tab[name].classList.add(this._el('activeTab'));
                this.content[name].classList.add(this._el('activeContent'));
            } else {
                this.tab[tabName].classList.remove(this._el('activeTab'));
                this.content[tabName].classList.remove(this._el('activeContent'));
            }
        });
    }
    _disablePointerEvents(target) {
        if (!target) return;
        target.style.pointerEvents = 'none';
    }
    _cleanStyle(target) {
        if (!target) return;
        target.removeAttribute('style');
    }

    _getFormValidity(form) {
        if (!form.checkValidity()) {
            return false;
        }
        return this._getDatesValidity(form);
    }
    _getDatesValidity(form) {
        if (!form) return false;
        const validities = Array.from(form.querySelectorAll('.mt23-datepicker')).map((el) => {
            return {
                name: register.getClass(el).getName(),
                value: register.getClass(el).getValue(),
                valid: register.getClass(el).isValid(),
            };
        });
        const result = validities.filter((el) => !el.valid).length <= 0;
        return result;
    }

    _checkPasswordRepeat() {
        if (
            this.passwObj.getValue() !== '' &&
            this.passwObj.isValid() &&
            this.passwRepeatObj.getValue() !== '' &&
            this.passwRepeatObj.getValue() !== this.passwObj.getValue()
        ) {
            this.passwordMismatch.classList.remove(this._elMod('passwordMismatch', 'hidden'));
            this.passwRepeatObj.setState('error');
            this.passwordRepeat.querySelector('.mt8-textfield__error').style.display = 'none';
        } else {
            this.passwordRepeat.querySelector('.mt8-textfield__error').removeAttribute('style');
            if (!this.passwordMismatch.classList.contains(this._elMod('passwordMismatch', 'hidden')))
                this.passwordMismatch.classList.add(this._elMod('passwordMismatch', 'hidden'));
            if (this.passwRepeatObj.getValue() !== '' && this.passwRepeatObj.getValue() === this.passwObj.getValue()) {
                this.passwRepeatObj.setState('valid');
            }
        }
    }

    _checkPasswordOldFormat() {
        if (this.passwOldObj.getValue() !== '' && this.passwOldObj.isValid()) {
            this.passwOldObj.isValid();
        }
    }

    _submitDisabled(submit, el) {
        if (!submit) return;
        return !submit.classList.contains(this._elMod(el, 'active'));
    }
    _resetFormInputs(form) {
        if (!form) return;
        ['mt2-addressfield', 'mt8-textfield', 'mt16-select', 'mt17-checkbox', 'mt18-textarea', 'mt19-radio'].forEach(
            (el) => {
                form.querySelectorAll(`.${el}`).forEach((field) => {
                    register.getClass(field).reset();
                });
            }
        );
    }

    _closeSelects(container) {
        if (!container) return;
        Array.from(container.querySelectorAll('.mt16-select')).forEach((select) =>
            register.getClass(select).closeDropdown()
        );
    }

    _refreshPersonalChildBirthDatepickers() {
        for (let i = 1; i < this.personalChildBirthDatePickers.length; i++) {
            let actual = register.getClass(this.personalChildBirthDatePickers[i]);
            let actualVal = actual.getValue();
            let previous = register.getClass(this.personalChildBirthDatePickers[i - 1]);
            let previousVal = previous.getValue();
            if (previousVal === '' && actualVal === '') {
                actual.disable();
            } else {
                actual.enable();
            }
        }
        for (let i = 0; i < this.personalChildBirthDatePickers.length - 1; i++) {
            let next = register.getClass(this.personalChildBirthDatePickers[i + 1]);
            let nextVal = next.getValue();
            if (nextVal === '') {
                const input = next.getInput();
                this._cleanStyle(input);
                input.readOnly = false;
            }
        }
    }

    _initConsentsNegativeTexts() {
        [
            this.consentsMarketing,
            this.consentsProfiling,
            this.consentsCooperative,
            this.consentsMarketingPartner,
        ].forEach((el) => {
            if (!el) return;

            let radio = register.getClass(el.querySelector('.mt19-radio'));
            let radioVal = radio.getValue();
            el.querySelector(this._el('consentsNegativeText', true)).style.display =
                radioVal === 'N' ? 'block' : 'none';
        });
    }

    async _loadProvince(select) {
        const provinceSelect = register.getClass(select);
        try {
            const province = await apiProvider.province();
            province.forEach((prov) => {
                if (prov.value === this.root.dataset.initProvince) {
                    prov.selected = true;
                    return;
                }
            });
            await provinceSelect.setItems(province);
        } catch (error) {
            console.error('Cannot set province');
        }
    }

    async _loadComuni(value, select) {
        try {
            select.reset();
            const comuni = await apiProvider.comuni({ provinciaId: value });
            await select.setItems(comuni);
        } catch (error) {
            console.error('Cannot set comuni');
        }
    }

    async _submitNewEmail(form) {
        if (!form) return;
        const data = formToJSON(form);
        if (!data.email || !data.password) {
            console.error('Something was not filled in');
            return false;
        }

        /// VERIFY CONAD CARD EMAIL IF PRESENT
        let updatableMail = false;
        if (this.user.conadCard) {
            try {
                openLoader('main');
                const verifyData = {
                    email: data.email,
                    codiceCarta: this.user.cartaFedelta,
                    processo: 'M', // change mail process
                };
                const result = await apiProvider.verifyConadCard(verifyData);
                updatableMail = result.mailAggiornabile == 'S';
            } catch (error) {
                // check if we can continue
                let continueReg = false;
                if (error?.codice) {
                    switch (error.codice) {
                        case 'IS_ERR:EMAIL_GIA_PRESENTE_SU_CLIENTE':
                        case 'IS_ERR:CLIENTE_NON_AGGIORNABILE':
                            continueReg = true;
                    }
                }

                // if not block process.
                if (!continueReg) {
                    trackEvent(
                        TRACKABLE_EVENT.formError,
                        FUNNEL_NAME.personalProfile,
                        FUNNEL_STEP.changeEmailForm,
                        null,
                        null,
                        null,
                        {
                            errorField: dictionary.getFEMessage('formSubmitError'),
                            errorText: dictionary.getFEMessage('genericError'),
                        }
                    );
                    console.warn(error);

                    // show error popup if something goes wrong
                    flowManager.startFlow({
                        flowName: 'generic-error',
                        flowSteps: [{ name: 'generic-error' }],
                        initialData: {
                            errorMessage: dictionary.get('Cannot verify user conad card'),
                        },
                    });
                    return false;
                }
            } finally {
                closeLoader('main');
            }
        }

        // GENERATE STEP
        let flowSteps = [
            { name: 'email-change-proceed' },
            { name: 'check-email' },
            { name: 'generic-error', disableBack: true },
        ];
        if (this.user.conadCard) {
            if (updatableMail) {
                flowSteps = [
                    { name: 'gcc-check-email' },
                    { name: 'email-change-proceed-gcc' },
                    { name: 'check-email' },
                    { name: 'generic-error', disableBack: true },
                ];
            }
        }

        // START FLOW TO CHANGE MAIL AND WAIT COMPLETE
        return await flowManager.startAsyncFlow({
            flowName: 'change-email',
            flowSteps: flowSteps,
            options: {
                checkEmailOperation: 'changedEmail',
            },
            initialData: {
                formData: data,
            },
        });
    }

    _checkShowPasswordRules() {
        this.passwordRules.classList.remove(this._elMod('passwordRules', 'hidden'));
        if (this.passwObj.isValid()) {
            this.passwordStateOk.classList.add('active');
            this.passwordStateError.classList.remove('active');
        } else {
            this.passwordStateOk.classList.remove('active');
            this.passwordStateError.classList.add('active');
        }
    }

    async _submitNewPassword(form) {
        if (!form) return;
        const data = formToJSON(form);
        if (!data.password) {
            console.error('Non hai compilato qualcosa');
            return;
        }
        try {
            openLoader('main');
            await apiProvider.changePasswordV2(data);
            const clone = this.changedPswPopupContent.cloneNode(true);
            closeLoader('main');
            if (!this.passwordRules.classList.contains(this._elMod('passwordRules', 'hidden')))
                this.passwordRules.classList.add(this._elMod('passwordRules', 'hidden'));
            this.passwordStateOk.classList.remove('active');
            this.accessPassword.classList.remove(this._elMod('accessPassword', 'edit'));
            await delay(1000);
            showPopup('main', clone);
        } catch (error) {
            trackEvent(
                TRACKABLE_EVENT.formError,
                FUNNEL_NAME.personalProfile,
                FUNNEL_STEP.changePswForm,
                null,
                null,
                null,
                {
                    errorField: dictionary.getFEMessage('formSubmitError'),
                    errorText: dictionary.getFEMessage('genericError'),
                }
            );
            console.warn(error);
            this.passwOldObj.setState('error');
            let message = error?.codice == 'IS_ERR:CREDENZIALI_NON_VALIDE' ? dictionary.getFEMessage('oldPasswordWrong') : null;
            this.passwOldObj.setErrorText(message);
            closeLoader('main');
        }
    }

    async _verifyUserDeletion() {
        let result = true;
        try {
            openLoader('main');
            result = await apiProvider.hasActiveOrders();
        } catch (error) {
            console.warn(error);
        } finally {
            closeLoader('main');
        }
        return !result;
    }

    _deleteProfile() {
        flowManager.startFlow({
            flowName: 'delete-profile',
            flowSteps: [{ name: 'delete-profile' }],
        });
    }

    async _submitNewEnrichmentData(form) {
        if (!form) return;
        const data = formToJSON(form, true);
        const suffix = 'Value';
        Object.getOwnPropertyNames(data).forEach((prop) => {
            if (prop !== suffix && prop.endsWith(suffix) && data[prop] !== '') {
                data[prop.substring(0, prop.length - suffix.length)] = [data[prop]];
            }
        });
        if (data.dataNascitaConiuge == '') data.dataNascitaConiuge = '00-00-0000';

        try {
            openLoader('main');
            const firmaDigitale = await apiProvider.getFirmaDigitale();
            data.firmaDigitale = firmaDigitale;
            await apiProvider.editUserData(data);

            let url = new URL(window.location.href);
            url.searchParams.set('tab', 'enrichmentData');
            window.location.href = url.href;
        } catch (error) {
            trackEvent(
                TRACKABLE_EVENT.formError,
                FUNNEL_NAME.personalProfile,
                FUNNEL_STEP.personalDataForm,
                null,
                null,
                null,
                {
                    errorField: dictionary.getFEMessage('formSubmitError'),
                    errorText: dictionary.getFEMessage('genericError'),
                }
            );
            console.warn(error);

            // GENERIC ERROR FLOW
            flowManager.startFlow({
                flowName: 'generic-error',
                flowSteps: [{ name: 'generic-error', disableBack: true }],
            });
        } finally {
            closeLoader('main');
        }
    }

    async _submitNewConsents(form) {
        if (!form) return;

        const data = formToJSON(form);
        data.canaliDiContatto = (this.cpForm ? [...this.cpForm.querySelectorAll('input')] : []).map((input) => {
            return {
                codice: input.name,
                consenso: input.checked ? 'S' : 'N',
            };
        });

        /*let choiceData = {};
        if (this.user.refresh) {
            choiceData = await flowManager.startAsyncFlow({
                flowName: 'save-consensus',
                flowSteps: [{ name: 'refresh-prize-choice' }],
                initialData: {},
                options: {
                    hideBack: true,
                },
            });
        }*/

        // edit
        try {
            openLoader('main');

            /*// save coupon choice
            if (choiceData?.choice) {
                await apiProvider.setCouponChoice({ choice: choiceData.choice });
            }*/

            const firmaDigitale = await apiProvider.getFirmaDigitale();
            data.firmaDigitale = firmaDigitale;
            await apiProvider.editUserData(data);
        } catch (error) {
            trackEvent(
                TRACKABLE_EVENT.formError,
                FUNNEL_NAME.personalProfile,
                FUNNEL_STEP.consentsForm,
                null,
                null,
                null,
                {
                    errorField: dictionary.getFEMessage('formSubmitError'),
                    errorText: dictionary.getFEMessage('genericError'),
                }
            );
            console.warn(error);

            // GENERIC ERROR FLOW
            flowManager.startFlow({
                flowName: 'generic-error',
                flowSteps: [{ name: 'generic-error', disableBack: true }],
            });

            return;
        } finally {
            closeLoader('main');
        }

        // show refresh
        if (this.user.refresh) {
            closeLoader('main');
            await flowManager.startAsyncFlow({
                flowName: 'refresh-thank-you',
                flowSteps: [{ name: 'refresh-thank-you' }],
                initialData: {
                    /*choice: choiceData.choice ? choiceData.choice : null,*/
                }
            });
        }

        // reload page
        openLoader('main');
        let url = new URL(window.location.href);
        url.searchParams.set('tab', 'consents');
        window.location.href = url.href;
        await delay(1000);
        closeLoader('main');
    }

    _initTextsWithEditableLinks() {
        const privacyLinkLabel = this.consentsHeadingText.dataset.privacyLinkLabel;
        this.consentsHeadingText.innerHTML = this.consentsHeadingText.innerHTML.replace(
            '$privacy',
            `<a class='mf7-profile__privacyLink' href="#" title="informativa privacy" target="_blank">${privacyLinkLabel}</a>`
        );
    }

    _setValidationContexts() {
        this.passwRepeatObj.setValidationContext({
            pswObj: this.passwObj,
        });
    }

    _initConsentsForm() {
        let cp;
        try {
            cp = JSON.parse(this.cpForm.dataset.preferences);
        } catch (error) {
            console.warn('Could not parse current contact preferences');
            cp = {};
        }
        const data = {
            ...this.user,
        };
        jsonToForm(this.consentsForm, data, []);
        // init toggles
        this.cpToggles?.forEach((toggle) => {
            const toggleObj = register.getClass(toggle);
            const inputName = toggleObj._getInput().name;
            toggleObj.setChecked(cp[inputName] && cp[inputName] === 'S');
        });
    }

    _enableContactPreferences() {
        this.cpToggles?.forEach((toggle) => {
            register.getClass(toggle).enable();
        });
    }

    _disableContactPreferences() {
        this.cpToggles?.forEach((toggle) => {
            register.getClass(toggle).disable();
        });
    }

    _toggleAllContactPreferences(value) {
        if (value !== 'S' && value !== 'N') {
            console.warn('Invalid contact preferences value');
            return;
        }
        this.cpToggles?.forEach((toggle) => {
            register.getClass(toggle).setChecked(value === 'S');
        });
    }

    _getTabPanelFromTab(tab) {
        return this.root.querySelector(`#${tab.getAttribute('aria-controls')}`);
    }

    _isTabActive(tab) {
        return tab.classList.contains(this._el('activeTab'));
    }

    _selectTab(tab, tabPanel, select = true) {
        if (select == true) {
            tab.setAttribute('aria-selected', 'true');
            tab.classList.add(this._el('activeTab'));
            tabPanel.removeAttribute('hidden');
            tabPanel.setAttribute('aria-hidden', 'false');
        } else {
            tab.setAttribute('aria-selected', 'false');
            tab.classList.remove(this._el('activeTab'));
            tabPanel.setAttribute('hidden', 'true');
            tabPanel.setAttribute('aria-hidden', 'true');
        }
    }
}

register.registerClass('.mf7-profile', Profile);
