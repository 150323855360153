import { apiProvider } from '../../../../../../libs/api-provider';
import ModalComponent from '../../../../../../libs/components/modal-component';
import { formToJSON, jsonToForm } from '../../../../../../libs/form-to-json';
import { register } from '../../../../../../libs/register';
import { flowManager } from '../../../../../../libs/flow-manager';
import { storeManager } from '../../../../../../libs/store-manager';
import { openLoader, closeLoader } from '../../../templates/mt11-loader/script';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';

class BillingAddressModal extends ModalComponent {
    constructor(name, root) {
        super(name, root);

        this.billingTypes = ['persfis', 'azienda', 'libprof'];
        this.heading = this._dEl('heading');
        this.billingType = this._dEl('billingType');
        this.formSelect = register.getClass(this.billingType);
        this.forms = this.root.querySelectorAll(this._el('form', true));
        this.form = {
            'persfis': this.root.querySelector(`${this._el('form', true)}[data-form-type='persfis']`),
            'azienda': this.root.querySelector(`${this._el('form', true)}[data-form-type='azienda']`),
            'libprof': this.root.querySelector(`${this._el('form', true)}[data-form-type='azienda']`)
        }
        this.activeForm = Array.from(this.forms).filter((form) => {
            form.classList.contains(this._elMod('form', 'active'));
        })[0];
        this.emailPecAzienda = this.form['azienda']?.querySelector(this._el('emailPEC', true));
        this.destCodeAzienda = this.form['azienda']?.querySelector(this._el('codiceDestinatario', true));
        this.submit = this._dEl('submit');
        this.remove = this._dEl('remove');

        this._populateSelectsProvince();
        this._populateSelectsCountries();
        this._initActiveForm();

        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        /* form handlers */
        this.root.addEventListener('ecInputChanged', (event) => {
            if (!event.data.valid) return;
            this._toggleActiveForm(event.data.value);
        });
        /*this.root.addEventListener('ecInputChanged', (event) => {
            !event.data.valid
                ? this._disableSubmit()
                : this._getFormValidity(this.activeForm)
                    ? this._enableSubmit()
                    : this._disableSubmit();
        });*/
        /* handle only one between email pec and dest code required for form type 'azienda' */
        this.emailPecAzienda?.addEventListener('ecInputChanged', (event) => {
            if (!this.destCodeAzienda) return;
            const otherFieldObj = register.getClass(this.destCodeAzienda);
            if (!otherFieldObj) return;
            const otherInput = otherFieldObj?.getInput();
            if (!otherInput) return; 
            otherInput.required = !event.data.valid || event.data.value === '';
            otherFieldObj._checkState();
        });
        this.destCodeAzienda?.addEventListener('ecInputChanged', (event) => {
            if (!this.emailPecAzienda) return;
            const otherFieldObj = register.getClass(this.emailPecAzienda);
            if (!otherFieldObj) return;
            const otherInput = otherFieldObj?.getInput();
            if (!otherInput) return;
            otherInput.required = !event.data.valid || event.data.value === '';
            otherFieldObj._checkState();
        });
        this.submit.addEventListener('click', (event) => {
            event.preventDefault();
            if (!this._getFormValidity(this.activeForm)) {
                const first = this.activeForm.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }

            trackEvent(TRACKABLE_EVENT.saveAddress, FUNNEL_NAME.addressHandling, FUNNEL_STEP.saveBillingAddress);
            //this._disableSubmit();
            this._submitForm(this.activeForm);
        });
        this.remove.addEventListener('click', (event) => {
            event.preventDefault();
            trackEvent(TRACKABLE_EVENT.removeAddress, FUNNEL_NAME.addressHandling, FUNNEL_STEP.removeBillingAddress);
            /* send saved address id */
            storeManager.emit('deleteAddress', { type: 'billing', addressId: this.addressId });
            flowManager.next('delete-address', 'billing-add');
        });

        /* form errors */
        if (this.form) Object.values(this.form).forEach((form) => {
            form.addEventListener('ecFormError', (event) => {
                event.preventDefault();
                if (!event.data.errorField) return;
                trackEvent(
                    TRACKABLE_EVENT.formError, 
                    FUNNEL_NAME.addressHandling, 
                    FUNNEL_STEP[`${this.modality}BillingAddressModal`], 
                    null, null, null, 
                    { 
                        errorField: event.data.errorField,
                        errorText: event.data.errorText,
                    }
                );
                event.stopPropagation();
            });
        });
    }

    _addStoreListeners() {
        storeManager.on('currentBillingAddress', async (path, data) => {
            this.modality = data.newAddress ? 'add' : 'modify';
            this._toggleModalVersion(data.newAddress);
            trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.addressHandling, FUNNEL_STEP[`${this.modality}BillingAddressModal`]);
            /* save address id */
            this.addressId = data?.address?.id || '';
            let billingType = data.address?.type;
            if (billingType == 'libprof') billingType = 'azienda';
            let formData = data?.address;
            let form = Array.from(this.forms).filter((form) => form.dataset.formType === billingType)[0];
            if (billingType && this.billingTypes.includes(billingType) && form) {
                this.formSelect.setSelected(billingType);
                this._activateForm(billingType);
                /* disable edit of billing type on modify */
                !data.newAddress 
                    ? this.formSelect.disable()
                    : this.formSelect.enable();
                
            } else {
                console.warn('Error on current billing address');
            }
            jsonToForm(form, formData || {}, []);
        });
    }

    _initActiveForm() {
        this._toggleActiveForm(this.formSelect.getValue());
    }

    _toggleActiveForm(type) {
        if (!this.billingTypes.includes(type)) return;
        this.forms.forEach((form) => {
            form.classList.remove(this._elMod('form', 'active'));
            this._resetFormInputs(form);
        });
        if (this.form[type] && !this.form[type].classList.contains(this._elMod('form', 'active'))) {
            this.form[type].classList.add(this._elMod('form', 'active'));
        }
        //this._disableSubmit();
        this.ps.update();
        this._updateActiveForm();
    }

    _toggleModalVersion(newAddress) {
        /* show title */
        this._dEl(`title${newAddress ? 'Add' : 'Modify'}`).classList.add(this._elMod(`title${newAddress ? 'Add' : 'Modify'}`, 'show'));
        this._dEl(`title${!newAddress ? 'Add' : 'Modify'}`).classList.remove(this._elMod(`title${!newAddress ? 'Add' : 'Modify'}`, 'show'));
        newAddress
            ? this.remove.classList.add(this._elMod('remove', 'hidden'))
            : this.remove.classList.remove(this._elMod('remove', 'hidden'));
    }

    _activateForm(type) {
        const others = Array.from(this.forms).filter((form) => form.dataset.formType !== type);
        if (others.length > 0) others.forEach((other) => {
            other.classList.remove(this._elMod('form', 'active'));
            this._resetFormInputs(other);
        });
        const active = Array.from(this.forms).filter((form) => form.dataset.formType === type)[0];
        if (active) active.classList.add(this._elMod('form', 'active'));

        this.ps.update();
        this._updateActiveForm();
    }

    _updateActiveForm() {
        this.activeForm = Array.from(this.forms).filter((form) =>
            form.classList.contains(this._elMod('form', 'active'))
        )[0];
    }

    _resetFormInputs(form) {
        if (!form || !form.classList.contains(this._el('form'))) return;
        [
            'mt8-textfield',
            'mt16-select',
            'mt17-checkbox'
        ].forEach((el) => {
            form.querySelectorAll(`.${el}`).forEach((field) => {
                register.getClass(field).reset();
            });
        });
    }
    resetForms() {
        this.forms.forEach((form) => {
            this._resetFormInputs(form);
        });
    }

    _getFormValidity(form) {
        if (!form.checkValidity()) return false;
        //console.log("valid");
        return true;
    }

    async _populateSelectsCountries() {
        await this._loadNations(Array.from(this.root.querySelectorAll(this._el('nazione', true))));
    }
    async _loadNations(selects) {
        try {
            const nations = await apiProvider.stati();
            selects.forEach(async (select) => {
                const nationsSelect = register.getClass(select);
                await nationsSelect.setItems(nations);
            });
        } catch (error) {
            console.error('Cannot set nations');
        }
    }

    async _populateSelectsProvince() {
        await this._loadProvince(Array.from(this.root.querySelectorAll(this._el('provincia', true))));
    }
    async _loadProvince(selects) {

        try {
            const province = await apiProvider.province();
            selects.forEach(async (select) => {
                const provinceSelect = register.getClass(select);
                await provinceSelect.setItems(province);
            });
        } catch (error) {
            console.error('Cannot set province');
        }
    }

    _enableSubmit() {
        if (this.submit.classList.contains(this._elMod('submit', 'active'))) return;
        this.submit.classList.add(this._elMod('submit', 'active'));
    }
    _disableSubmit() {
        if (!this.submit.classList.contains(this._elMod('submit', 'active'))) return;
        this.submit.classList.remove(this._elMod('submit', 'active'));
    }

    async _submitForm(form) {
        openLoader('main');
        if (!form || !form.classList.contains(this._el('form'))) return;
        const data = formToJSON(form);
        /* add country object as required by IS */
        data.country = {
            isocode: data.countryValue || '',
            name: data.countryValueLabel || ''
        };
        data.type = form.dataset.formType;
        if (data.defaultBillingAddress == null) {
            data.defaultBillingAddress = false;
        }
        try {
            if (this.addressId === '') {
                await apiProvider.addBillingAddress(data);
            } else {
                data.id = this.addressId;
                await apiProvider.editBillingAddress(data);
            }
            closeLoader('main');
            if (this.modality === 'add') {
                flowManager.next();
            } else {
                storeManager.emit('newBillingAddress', data);
            }
        } catch (error) {
            console.warn(error);
            closeLoader('main');
        } finally {
            this.addressId = '';
        }
    }

}

register.registerClass('.mm2-billing-address', BillingAddressModal);
