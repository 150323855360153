import Component from '../../../../../../libs/components/component';
import { runTemplate } from '../../../../../../libs/htl-runtime/HTMLRuntime';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import contentTpl from '../mc7-strillo-app/content.html';

class StrilloApp extends Component {
    constructor(name, root) {
        super(name, root);
        this._doLogic();
    }

    async _doLogic() {
        try {
            this.configurationMap = JSON.parse(this.root.dataset.configurationMap);
            console.log('configurationMap strillo', this.configurationMap);
        } catch (error) {
            console.warn('Could not parse configuration map');
            return;
        }
        this._addStoreListeners();
    }

    _addStoreListeners() {
        storeManager.on('touchpointParams', async (path, data) => {
            this._renderContent(data);
        });
    }

    async _renderContent(touchpointParams) {
        const regType = touchpointParams.regType || 'standard';
        const domain = touchpointParams.domain || 'myconad';
        if (this.configurationMap[domain].hiddenByAuthor && !window.isAuthor) {
            // component hidden by author ==> do NOT show
            return;
        }
        if (regType == 'standard' && !window.isAuthor) {
            // no card (light user) ==> do NOT show
            return;
        }
        // render content
        const contentData = {
            ...this.configurationMap[domain],
        };
        const contentEl = runTemplate(
            contentTpl, 
            contentData, 
            this._el('wrapper', true)
        );
        this.root.append(contentEl);
        // set background color
        if (this.configurationMap[domain].bgColor) {
            this.root.style.backgroundColor = `${this.configurationMap[domain].bgColor}`;
        }
    }
}

register.registerClass('.mc7-strillo-app', StrilloApp);
