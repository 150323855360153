import { apiProvider } from '../../../../../../libs/api-provider';
import Component from '../../../../../../libs/components/component';
import { register } from '../../../../../../libs/register';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';

class FavoriteContent extends Component {
    constructor(name, root) {
        super(name, root);
        this.loaderEndpoint = this.root.dataset.endpoint;
        this.currentSize = parseInt(this.root.dataset.initLength);
        this.downloadLength = parseInt(this.root.dataset.downloadLength);
        this.countContent = this.root.querySelector(`${this._el('countContent', true)} > span`);
        this.loadMore = this._dEl('loadMore');
        this.noContent = this._dEl('noContent');
        this.content = this._dEl('content');
        this.filter = register.getClass(this._dEl('filter'));

        this._addEventListeners();
    }

    _addEventListeners() {
        this.loadMore.addEventListener('click', async () => {
            openLoader('main');
            const lastFocusedCard = this.content.querySelector(`${this._el('card', true)}:last-child`);
            await this._downloadContent(this.currentSize, this.downloadLength);
            setTimeout(() => {
                closeLoader('main');
                lastFocusedCard?.querySelector('a')?.focus();
            }, 500);
        });
        this.root.addEventListener('mt10FilterChanged', async (event) => {
            console.info(`[${this.getName()}] selected filter: ${event.data.label} (${event.data.value})`);
            openLoader('main');
            this.currentSize = 0;
            await this._emptyContent(this.content);
            await this._downloadContent(this.currentSize, this.downloadLength);
            setTimeout(() => {
                closeLoader('main');
            }, 500);
        });
    }

    async _downloadContent(init, length) {
        const url = this.loaderEndpoint;
        const data = {
            filter: this.filter.getValue(),
            init: init,
            length: length,
            currentSize: this.currentSize,
        };
        try {
            const html = await apiProvider.loaderGet(url, data);
            this._appendContent(html);
        } catch (error) {
            console.error(error);
        }
    }

    _appendContent(content) {
        /* parse new content */
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const newContent = doc.querySelectorAll('.mt35-content-card');
        const countContent = doc.querySelector(this._el('loader', true)).dataset.countContent;

        const class_hideLoadMore = this._elMod('loadMore', 'hidden');
        let classList_loadMore = this.loadMore.classList;
        const class_hideContent = this._elMod('content', 'hidden');
        let classList_content = this.content.classList;
        const class_hideNoContent = this._elMod('noContent', 'hidden');
        let classList_noContent = this.noContent.classList;

        /* hide/show loadMore */
        if (this.currentSize != 0 && newContent.length < this.downloadLength) {
            /* case no more content to download next */
            classList_loadMore.add(class_hideLoadMore);
        } else {
            /* case possibly more content to download next */
            classList_loadMore.remove(class_hideLoadMore);
        }

        /* hide/show content */
        if (this.currentSize + newContent.length <= 0) {
            /* case no content */
            classList_content.add(class_hideContent);
            classList_noContent.remove(class_hideNoContent);
            this.countContent.textContent = '0';
            classList_loadMore.add(class_hideLoadMore);
        } else {
            /* case content */
            classList_content.remove(class_hideContent);
            classList_noContent.add(class_hideNoContent);
            /* append new content */
            this.content.append(...newContent);
            /* update current size */
            this.currentSize += newContent.length;
            /* hide/show loadMore */
            if (this.currentSize >= countContent) {
                classList_loadMore.add(class_hideLoadMore);
            }
            /* update total number of content */
            this.countContent.textContent = countContent;
        }
    }

    async _emptyContent(container) {
        if (!container || container.objReference || !container.hasChildNodes()) return;
        for (let i = container.childElementCount; i > 0; i--) {
            container.removeChild(container.children[i - 1]);
        }
    }
}

register.registerClass('.mf13-favorite-content', FavoriteContent);
