import ModalComponent from '../../../../../../libs/components/modal-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { apiProvider } from '../../../../../../libs/api-provider';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import { informativaManager } from '../../../../../../libs/informativa-manager';

class ConfirmLoginModal extends ModalComponent {
    constructor(name, root) {
        super(name, root);

        this.error = this._dEl('error');
        this.recaptchaAction = this.root.dataset.recaptchaAction;

        this.form = this._dEl('form');
        this.socialButtons = Array.from(this._dEl('socialButton', true));
        this.emailText = this._dEl('emailText');
        this.email = this.emailText.dataset.email || storeManager.get('userData').email;
        this.password = this._dEl('password');
        this.passwObj = register.getClass(this.password);
        
        this.recoverPwdText = this._dEl('recoverPwdText');
        this.recoverPwdLink = JSON.parse(this.recoverPwdText.dataset.recoverPwd);
        
        this.bottomText = this._dEl('bottomText');
        this.coop = this.bottomText.dataset.coop || 'cnd';

        this.wrongEmail = this._dEl('wrongEmail');
        
        this.submit = this._dEl('submit');
        
        this._initDynamicTexts();
        this._checkError();
        this._addEventListeners();
        this._addStoreListeners();
        //this._checkShowSubmit();
    }

    _addEventListeners() {
        /*this.root.addEventListener('ecInputChanged', () => {
            this._checkShowSubmit();
        });*/
        this.wrongEmail?.addEventListener('click', async (event) => {
            event.preventDefault();
            try {
                openLoader('main');
                await apiProvider.logout();
                const url = new URL(this.wrongEmail.href);
                url.searchParams.set('cb', encodeURIComponent(window.location.href));
                window.location = url.href;
            } catch (error) {
                console.warn(error);
                closeLoader('main');
            }
        });
        this.form.addEventListener('submit', (event) => {
            event.preventDefault();
            if (!this.form.checkValidity()) {
                const first = this.form.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }

            //this._checkShowSubmit();
            if (!this.error.classList.contains(this._elMod('error', 'hidden'))) {
                this.error.classList.add(this._elMod('error', 'hidden'));
                this.error.innerHTML = '';
            }
            this.passwObj.setState('');
            this.socialLogin = false;
            this._doLogin();
        });
        this.socialButtons.forEach((item) => {

            item.addEventListener('click', (event) => {
                event.preventDefault();
                this.socialType = item.dataset.type;
                this.socialLogin = true;
                this._doLogin();
            });
        });
    }

    _addStoreListeners() {
        //intercept flow options and get submitEl if defined
        storeManager.on(/flow\/[^/]+$/g, async (path, flow) => {
            this.submitEl = flow?.options?.submitEl || null;
            this.calleeId = flow?.options?.calleeId || null;
        });
    }

    _getErrorFromUrl() {
        const urlParams = new URLSearchParams(window.location.search);
        const error = urlParams.get('error');
        if (error) return error;
        return null;
    }

    async _checkError() {
        const err = this._getErrorFromUrl();
        if (!err) return;
        const message = err != '' ? err : this.root.dataset.genericError;
        let decoded = null;
        try {
            decoded = decodeURI(message);
        } catch (error) {
            console.warn('Error message wrongly encoded in url');
        }
        this.error.innerHTML = decoded != null ? dictionary.get(decoded) : this.root.dataset.genericError;
        this.error.classList.remove(this._elMod('error', 'hidden'));
    }

    async _sendSocialLogin(socialType) {
        const data = {
            socialType,
            cb: window.location.href,
        };

        try {
            openLoader('main');
            const result = await apiProvider.socialLogin(data);
            storeManager.emit('mm10LoginConfirmed', { 
                confirmed: !!result, 
                submitEl: this.submitEl
            });
            flowManager.appendDataToFlow({ok: true});
            flowManager.complete();
        } catch (error) {
            this.passwObj.setState('error');
            const message = error && error.frontendMessage ? error.frontendMessage : this.root.dataset.genericError;
            this.error.innerHTML = message;
            this.error.classList.remove(this._elMod('error', 'hidden'));
            closeLoader('main');
        }
    }

    async _sendLogin(token) {
        if (!this.email || !this.passwObj.isValid()) {
            this._showError('Something was not filled in!');
            return;
        }

        const data = {
            email: this.email,
            password: this.passwObj.getValue(),
            cb: window.location.href,
            gRecaptchaResponse: token,
        };

        try {
            openLoader('main');
            const result = await apiProvider.confirmLogin(data);
            if (!result.cbValid) {
                window.location.href = "/";
            }
            closeLoader('main');
            flowManager.appendDataToFlow({ok: true});
            flowManager.complete();
            storeManager.emit('mm10LoginConfirmed', { 
                confirmed: !!result, 
                submitEl: this.submitEl,
                calleeId: this.calleeId,
            });
        } catch (error) {
            this.passwObj.setState('error');
            let message = error?.codice == 'IS_ERR:CREDENZIALI_NON_VALIDE' ? dictionary.getFEMessage('failedConfirmLogin') : null;
            if (!message) message = error && error.frontendMessage ? error.frontendMessage : this.root.dataset.genericError;
            this.error.innerHtml = '';
            this.error.append(message);
            this.error.classList.remove(this._elMod('error', 'hidden'));
            closeLoader('main');
        }
    }

    async _doLogin() {
        if (!this.socialLogin) {
            /* recaptcha */
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(window.recaptchaSiteKey, { action: this.recaptchaAction }).then((token) => {
                    this._sendLogin(token);
                });
            });
        } else {
            this._sendSocialLogin(this.socialType);
        }
    }

    /*_checkShowSubmit() {
        if (this.email && this.passwObj.isValid()) {
            this.submit.classList.remove('invalid');
        } else {
            this.submit.classList.add('invalid');
        }
    }*/

    async _initDynamicTexts() {
        this._replacePlaceholderWithElement(
            this.emailText, 
            '{{email}}', 
            this.email, 
            'span', 
            'email'
        );
        this._replacePlaceholderWithElement(
            this.recoverPwdText, 
            '{{recover-pwd}}', 
            this.recoverPwdLink.label,
            'a', 
            'recoverPwd', 
            {
                href: this.recoverPwdLink.href
            }
        );

        const userData = storeManager.get('userData');
        const pdv = storeManager.get('selectedPdv');
        const result = await informativaManager.getInformativa(userData, pdv);
        if (!result) {
            console.warn(`Cannot set informativa for coop "${this.coop}", empty response returned`);
            this._replacePlaceholderWithElement(
                this.bottomText, 
                '{{privacy-policy}}', 
                this.bottomText.dataset.privacyPolicyLabel, 
                'span'
            );
        } else {
            this._replacePlaceholderWithElement(
                this.bottomText, 
                '{{privacy-policy}}', 
                this.bottomText.dataset.privacyPolicyLabel, 
                'a', 
                null,
                {
                    href: result.linkPdfInformativa,
                    title: `informativa ${result.version}`,
                    target: '_blank'
                }
            );
        }
        this._replacePlaceholderWithElement(
            this.bottomText, 
            '{{cookie-policy}}', 
            this.bottomText.dataset.cookiePolicyLabel, 
            'a', 
            null, 
            {
                href: this.bottomText.dataset.cookiePolicy,
                target: '_blank',
            }
        );
    }

    // linkData: { href, title, target }
    _replacePlaceholderWithElement(container, placeholder, label, el, clazz = null, linkData = null) {
        try {
            const split = container.innerHTML.split(placeholder);
            if (split.length <= 1) return;
            const replacedEl = document.createElement(el);
            if (clazz) replacedEl.classList.add(`${this.getName()}__${clazz}`);
            if (el === 'a' && linkData && linkData.href) {
                replacedEl.setAttribute('href', linkData.href);
                if (linkData.title) replacedEl.setAttribute('title', linkData.title);
                if (linkData.target) replacedEl.setAttribute('target', linkData.target);
            }
            replacedEl.innerText = label;
            container.innerHTML = '';
            container.insertAdjacentHTML('beforeend', split[0]);
            container.append(replacedEl);
            container.insertAdjacentHTML('beforeend', split[1]);
        } catch (error) {
            console.warn(error);
            return;
        }
    }

    _disableEl(el, elName) {
        if (!el || !elName) return;
        if (!el.classList.contains(this._elMod(elName, 'disabled')))
            el.classList.add(this._elMod(elName, 'disabled'));
    }
    _enableEl(el, elName) {
        if (!el || !elName) return;
        el.classList.remove(this._elMod(elName, 'disabled'));
    }

    /* override */
    async _onCancel() {
        await super._onCancel();
        flowManager.backOrComplete();
    }
}

register.registerClass('.mm10-confirm-login', ConfirmLoginModal);
