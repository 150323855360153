import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';
import PerfectScrollbar from 'perfect-scrollbar';

class OrderStatesPopup extends Component {
    constructor(name, root) {
        super(name, root);
        this.states = this.root.querySelector(`${this._el('content', true)} > div`);
        const scrollbarOptions = {
            swipeEasing: true,
            suppressScrollX: true
        };
        this.ps = new PerfectScrollbar(this.states, scrollbarOptions);
        this._updateHeight();
        this._addEventListeners();
    }

    _addEventListeners() {
        window.addEventListener('resize', () => {
            this._updateHeight();
        });
        document.addEventListener('scroll', () => {
            this._updateHeight();
        });
    }

    _updateHeight() {
        /* keep 5px free on top/bottom when innerHeight is used */
        this.root.style.height = `${Math.min(window.innerHeight - 10, 536)}px`;
    }
}

register.registerClass('.mt22-order-states-popup', OrderStatesPopup);