export const TRACKABLE_EVENT = {
    pageview: 'pageview',
    clickProfile: 'clickProfilo',
    clickMenuItem: 'clickVoceMenu',
    addAddress: 'aggiungiIndirizzo',
    favoriteAddress: 'indirizzoPreferito',
    modifyAddress: 'modificaIndirizzo',
    removeAddress: 'rimuoviIndirizzo',
    saveAddress: 'salvaIndirizzo',
    clickManageOrder: 'clickGestioneOrdine',
    clickCoupon: 'clickCoupon',
    copyCouponCode: 'copiaCodiceCoupon',
    loginCta: 'loginEmail',
    loginSocial: 'loginSocial',
    registerCta: 'ctaRegistrati',
    emailRegistration: 'registrazioneEmail',
    socialRegistration: 'registrazioneSocial',
    closeModal: 'chiusuraModale',
    searchAddress: 'ricercaIndirizzo',
    deleteAddress: 'cancellaIndirizzo',
    selectStore: 'selezionaNegozio',
    insertExistingCard: 'inserisciCartaEsistente',
    nextNoExistingCard: 'proseguiNuovaCarta',
    formError: 'erroreForm',
    verifyInsiemeCard: 'verificaCartaInsieme',
    resendActivationEmailCta: 'ctaReinviaMailAttivazione',
    requestCardCta: 'ctaRichiediCarta',
    associateSocial: 'associaSocial',
    cancelSocial: 'annullaSocial',
    downloadReceipt: 'stampaRicevutaPagamento',
    chooseCartConflict: 'CTASceltaCarrello',
};

export const FUNNEL_NAME = {
    personalMenu: 'Menu personale',
    orders: 'Ordini',
    addressHandling: 'Gestione indirizzi',
    favoriteProducts: 'Prodotti preferiti',
    couponsWallet: 'Portafoglio buoni sconto',
    access: 'Accesso',
    registration: 'Registrazione',
    login: 'Login',
    recoverEmail: 'Recupero email',
    recoverPsw: 'Recupero password',
    /* added */
    personalProfile: 'Il mio profilo',
    insiemeCard: 'Carta Insieme',
    storeChoice: 'Scelta negozio',
    contactPreferences: 'Preferenze di contatto',
    /* reset privacy */
    cdnCoopResetPrivacy: "Richiesta Consensi Customer per Utente Light",
    disablingUser: 'Disattivazione utenza',
    downloadReceipt: 'Checkout',
    retrieveCart: 'Recupero Carrello',
    /* refresh */
    refresh: 'Conad Refresh',
};

export const FUNNEL_STEP = {
    deliveryAddressList: 'Lista indirizzi di consegna',
    billingAddressList: 'Lista indirizzi di fatturazione',
    addDeliveryAddress: 'Aggiungi indirizzo di consegna',
    addBillingAddress: 'Aggiungi indirizzo di fatturazione',
    favoriteDeliveryAddress: 'Indirizzo preferito di consegna',
    favoriteBillingAddress: 'Indirizzo preferito di fatturazione',
    modifyDeliveryAddress: 'Modifica indirizzo di consegna',
    modifyBillingAddress: 'Modifica indirizzo di fatturazione',
    addDeliveryAddressModal: 'Modale aggiunta indirizzo di consegna',
    modifyDeliveryAddressModal: 'Modale modifica indirizzo di consegna',
    addBillingAddressModal: 'Modale aggiunta indirizzo di fatturazione',
    modifyBillingAddressModal: 'Modale modifica indirizzo di fatturazione',
    removeDeliveryAddress: 'Rimuovi indirizzo di consegna',
    removeBillingAddress: 'Rimuovi indirizzo di fatturazione',
    saveDeliveryAddress: 'Salva indirizzo di consegna',
    saveBillingAddress: 'Salva indirizzo di fatturazione',
    ordersList: 'Lista ordini',
    modifyDayOrTimeslot: 'Modifica giorno e fascia oraria',
    addOrRemoveProduct: 'Aggiungi o rimuovi prodotto',
    cancelOrder: 'Annulla ordine',
    orderDetail: 'Dettaglio ordine',
    couponsList: 'Lista coupon',
    clickCoupon: 'Click su coupon',
    couponDetail: 'Dettaglio coupon',
    copyCouponCode: 'Copia codice coupon',
    opening: 'Apertura',
    ecommerceFavoriteProductsList: 'Lista prodotti preferiti eCommerce',
    addToCart: 'Aggiunta al carrello',
    loginCta: 'Login con Email',
    registerCta: 'CTA Registrati',
    registrationModeChoice: 'Scelta modalità registrazione',
    registrationWithEmail: 'Registrazione con email',
    registrationWithFaceboook: 'Registrazione con Facebook',
    registrationWithGoogle: 'Registrazione con Google',
    registrationWithApple: 'Registrazione con Apple',
    serviceChoice: 'Scelta servizio',
    storeChoice: 'Scelta negozio',
    closeModal: 'Chiusura modale',
    searchAddress: 'Ricerca indirizzo',
    deleteAddress: 'Cancella indirizzo',
    selectStore: 'Seleziona negozio',
    cardInsertionChoice: 'Scelta immissione carta',
    insertInsiemeCardData: 'Inserisci i dati della Carta Insieme',
    nextNoExistingCard: 'Prosegui senza inserire Carta esistente',
    verifyInsiemeCardCta: 'CTA verifica Carta Insieme',
    userAndPswChoice: 'Scelta username e password',
    consentsScreen: 'Schermata consensi',
    clickRegisterCta: 'Click su CTA Registrati',
    registrationPerformed: 'Registrazione effettuata',
    resendActivationEmailCta: 'CTA Invia di nuovo email attivazione',
    requestInsiemeCardCta: 'CTA Richiedi Carta Insieme',
    confirmRequestInsiemeCard: 'Conferma richiesta Carta Insieme',
    confirmProfileActivation: 'Conferma attivazione profilo',
    confirmSocialAssociation: 'Conferma associazione social',
    failedSocialAssociation: 'Associazione social non riuscita',
    pendingSocialAssociation: 'Associazione social in attesa di conferma',
    completedSocialAssociation: 'Associazione social completata',
    loginModeChoice: 'Scelta modalità login',
    loginWithEmail: 'Login con email',
    loginWithFacebook: 'Login con Facebook',
    loginWithGoogle: 'Login con Google',
    loginWithApple: 'Login con Apple',
    socialProfileAssociationScreen: 'Schermata associazione profilo social',
    cancelSocialAssociation: 'Annulla associazione social',
    confirmEmailRecovery: 'Conferma recupero email',
    confirmSendingResetPswEmail: 'Conferma invio email di reset password',
    confirmResetPsw: 'Conferma reset password',
    insiemeCardDataForm: 'Form dati Carta Insieme',
    setNewPswForm: 'Form impostazione nuova password',
    recoverPswForm: 'Form recupero password',
    recoverEmailForm: 'Form recupero email',
    requestInsiemeCardForm: 'Form richiesta Carta Insieme',
    downloadReceipt: 'Stampa ricevuta pagamento',
    /* added */
    personalDataForm: 'Form dati personali',
    enrichmentDataForm: 'Form dati di arricchimento',
    changeEmailForm: 'Form cambio email',
    changePswForm: 'Form cambio password',
    consentsForm: 'Form consensi',
    contactPreferencesForm: 'Form preferenze di contatto',
    /**/
    cdnCoopResetPrivacy: 'Apertura form consensi utente Light',
    viewConflictCarts: 'Visualizzazione carrelli',
    confirmSelectCart: 'Conferma scelta carrello',
    /* refresh */
    popUpUpdateDataRefesh: 'Pop up aggiornamento dati',
};

const FUNNEL_TYPE_OF_SERVICE = {
    HOME_DELIVERY: 'Ordina e ricevi',
    ORDER_AND_COLLECT: 'Ordina e ritira',
};

export const trackEvent = async (
    event,
    funnelName,
    funnelStep,
    funnelTypeOfService = null,
    funnelSearch = null,
    userInfo = null,
    form = null,
    carts = null,
    cb = null,
    buono = null
) => {
    const TRACKING_ENABLED = window.trackingEnabled || false;
    const DEBUG_TRACKING = true;
    
    /* base tracking data */
    const data = {
        event: event,
        funnel: {
            nomeFunnel: funnelName,
            stepFunnel: funnelStep,
        },
    };

    /* funnel with type of service */
    if (funnelTypeOfService && FUNNEL_TYPE_OF_SERVICE[funnelTypeOfService]) {
        data.funnel.tipoServizio = FUNNEL_TYPE_OF_SERVICE[funnelTypeOfService];
    }
    if (funnelTypeOfService === '') {
        data.funnel.tipoServizio = '';
    }

    /* funnel with search */
    if (funnelSearch && funnelSearch.address) {
        data.funnel.ricerca = {
            tipoRicerca: funnelSearch?.type,
            indirizzoRicerca: funnelSearch.address,
        };
    }

    /* event with user info */
    if (userInfo) {
        if (!data.userinfo) Object.assign(data, { userinfo: {} });

        if (userInfo.id) {
            data.userinfo.userId = userInfo.id;
        }
        if (userInfo.id === '') {
            data.userinfo.userId = '';
        }

        if (userInfo.coop) {
            data.userinfo.cooperativa = userInfo.coop;
        }
        if (userInfo.coop === '') {
            data.userinfo.cooperativa = '';
        }

        if (userInfo.store) {
            data.userinfo.negozioPreferito = userInfo.store;
        }
        if (userInfo.store === '') {
            data.userinfo.negozioPreferito = '';
        }
    }
    /* event with form data */
    if (form) {
        if (!data.form) Object.assign(data, { form: {} });
        if (form.errorField) {
            data.form.campoErrore = form.errorField;
        }
        if (form.errorText) {
            data.form.testoErrore = form.errorText;
        }
    }

    if(carts) {
        if (cb != null) {
            data.funnel.provenienza = cb;
        }

        if(Object.keys(carts).length > 1) {
            if (!data.carrelloSuggerito && !data.carrelloNonSuggerito){
                Object.assign(data, { carrelloSuggerito: {}, carrelloNonSuggerito: {} });
            }
            data.carrelloSuggerito = carts.carrelloSuggerito;
            data.carrelloNonSuggerito = carts.carrelloNonSuggerito;
        } else {
            if (!data.carrelloSelezionato) {
                 Object.assign(data, { carrelloSelezionato: {} });
            }
            data.carrelloSelezionato = carts.carrelloSelezionato;
        }
    }

    if(buono){
        if (!data.buono) Object.assign(data, { buono: {} });
        data.buono.IDBuono = buono;
    }

    /* debug tracking */
    if (DEBUG_TRACKING) {
        console.log('OLD - TRACKING DEBUG', data);
    }
    if (TRACKING_ENABLED == false) return;
    /* push to adobeDataLayer */
    if (!window.adobeDataLayer) {
        console.warn('adobeDataLayer not found, cannot push analytics tracking data');
        return;
    }
    window.adobeDataLayer.push(data);
};
