import Component from '../../../../../libs/components/component';
import { register } from '../../../../../libs/register';

class Loader extends Component {
    constructor(name, root) {
        super(name, root);
        this.page = register.getClass(document.querySelector('.ms1-page'));
    }

    open() {
        this.root.classList.add(this._mod('show'));
        this.page.setBlockScroll();
    }

    close() {
        this.page.removeBlockScroll();
        this.root.classList.remove(this._mod('show'));
    }

    isOpened() {
        return this.root.classList.contains(this._mod('show'));
    }
}

register.registerClass('.mt11-loader', Loader);

export const openLoader = (name) => {
    const loader = document.getElementById(`loader-${name}`);
    const obj = register.getClass(loader);
    if (!obj) return;
    obj.open();
};

export const closeLoader = (name) => {
    const loader = document.getElementById(`loader-${name}`);
    const obj = register.getClass(loader);
    if (!obj) return;
    obj.close();
};

export const isLoaderOpened = (name) => {
    const loader = document.getElementById(`loader-${name}`);
    const obj = register.getClass(loader);
    if (!obj) return;
    obj.isOpened();
};
