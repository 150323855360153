import { apiProvider } from '../../../../../../libs/api-provider';
import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';

class AddressOtp extends PopupComponent {
    constructor(name, root) {
        super(name, root);

        this.ctaAction = this._dEl('ctaAction');
        this.selectedAddress = null;
        this.text = this._dEl('text');
        this.form = this._dEl('form');
        this.otp = this._dEl('otp');
        this.error = this._dEl('error');

        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        this.ctaAction.addEventListener('click', async (event) => {
            event.preventDefault();
            if (this.form.checkValidity()) {
                this._checkOtp();
            }
        });
        /* Enable / Disable button */
        this.root.addEventListener('ecInputChanged', () => {
            if (this.form.checkValidity()) {
                this.ctaAction.classList.remove(this._elMod('ctaAction', 'disabled'));
            } else {
                this.ctaAction.classList.add(this._elMod('ctaAction', 'disabled'));
            }
        });
        /* form errors */
        this.form.addEventListener('ecFormError', (event) => {
            event.preventDefault();
            if (!event.data.errorField) return;
            trackEvent(
                TRACKABLE_EVENT.formError,
                FUNNEL_NAME.addressHandling,
                FUNNEL_STEP[`${this.modality}DeliveryAddressOTPCheck`],
                null,
                null,
                null,
                {
                    errorField: event.data.errorField,
                    errorText: event.data.errorText,
                }
            );
            event.stopPropagation();
        });
    }

    _addStoreListeners() {
        storeManager.on('otpDeliveryAddress', (path, data) => {
            this.selectedAddress = data;
            let stars = '';
            for (let i = 0; i < data.cellphone.length - 3; i++) {
                stars += '*';
            }
            this.text.innerHTML = this.text.dataset.text.replace('{0}', stars + data.cellphone.slice(-3));
            this.ctaAction.classList.add(this._elMod('ctaAction', 'disabled'));
            this.error.classList.add(this._elMod('error', 'hidden'));
            register.getClass(this.otp).reset();
        });
    }

    async _checkOtp() {
        openLoader('main');

        try {
            const result = await apiProvider.checkOtpAddress({
                addressId: this.selectedAddress.id,
                otpCode: register.getClass(this.otp).getValue(),
            });
            closeLoader('main');
            if (result) {
                storeManager.emit('newDeliveryAddress', this.selectedAddress);
                flowManager.backOrComplete();
                return;
            }
        } catch (e) {
            closeLoader('main');
            console.warn('Cannot verify otp', e);
        }

        this.error.classList.remove(this._elMod('error', 'hidden'));
        register.getClass(this.otp).setState('error');
    }

    async _onCancel() {
        if (this.selectedAddress) storeManager.emit('newDeliveryAddress', this.selectedAddress);
        flowManager.backOrComplete();
    }
}

register.registerClass('.mp11-address-otp', AddressOtp);
